import { REFETCH_PRINTER_LIST } from '@prusaconnect/api'
import { useState } from 'react'
import { Container } from 'react-bootstrap'
import { useSearchParams } from 'react-router-dom'
import styled from 'styled-components'

import { IPrintersQuery } from '../../../api/types/printer'
import { useBreakpoint } from '../../../helpers/useBreakpoint'
import { usePrintersService } from '../../../services/usePrintersService'
import { usePrintersOverviewStore } from '../../../store/printersOverview'
import { PaceProgress, shouldShowTabularPace } from '../../common/PaceProgress'
import { Pager } from '../../common/Pager'
import { NoPrinters } from '../NoPrinters'
import { CardItem } from './CardItem'
import { Cards } from './CardItem.styled'
import { PrinterListItem } from './PrinterListItem'

export const PrinterList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  margin-bottom: 1.5rem;
`

export const ListItemContainer = styled.div`
  border-bottom: 1px solid var(--border-secondary);
  padding-bottom: 0.5rem;

  &:last-child {
    border-bottom: none;
    padding-bottom: 0;
  }
`

export function ListView() {
  const [searchParams] = useSearchParams()
  const [offset, setOffset] = useState(0)
  const [limit, setLimit] = useState(Number(searchParams.get('itemsPerPage')) || 50)
  const { teamId, groupId, states, getSortParamQuery } = usePrintersOverviewStore()
  const isLgOrLarge = useBreakpoint('xl')

  const printersQuery: IPrintersQuery = {
    limit,
    group_id: groupId,
    team_id: teamId,
    offset,
    state_include: states,
    sort_by: getSortParamQuery()
  }

  const printersQueryResult = usePrintersService(printersQuery, {
    refetchInterval: REFETCH_PRINTER_LIST,
    placeholderData: (previousData) => previousData
  })
  const { data } = printersQueryResult

  if (data?.printers.length === 0) {
    return <NoPrinters />
  }

  return (
    <>
      {shouldShowTabularPace(printersQueryResult) && <PaceProgress />}

      {isLgOrLarge ? (
        <Container>
          <PrinterList>
            {data &&
              data.printers.map((printer) => (
                <ListItemContainer key={printer.uuid}>
                  <PrinterListItem printer={printer} showCamera />
                </ListItemContainer>
              ))}
          </PrinterList>
        </Container>
      ) : (
        <Cards>{data?.printers.map((printer, i) => <CardItem key={i} printer={printer} />)}</Cards>
      )}

      {data && (
        <Container className="mb-2">
          <Pager pager={data.pager} setOffset={setOffset} setLimit={setLimit} />
        </Container>
      )}
    </>
  )
}
