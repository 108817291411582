import type { CSSProperties } from 'styled-components'

import Frame from '../../icons/general/grid-virtual.svg'
import Shelves from '../../icons/general/rows.svg'
import Harvester from '../../icons/general/state-harvest.svg'
import IconCoreOne from '../../svg/printers/core_one.svg'
import IconHT90 from '../../svg/printers/ht90.svg'
import IconIx from '../../svg/printers/ix.svg'
import IconSlMedicalOne from '../../svg/printers/m1.svg'
import IconMini from '../../svg/printers/mini.svg'
import IconMk3 from '../../svg/printers/mk3.svg'
import IconMk3Mmu2 from '../../svg/printers/mk3mmu2.svg'
import IconMk3s from '../../svg/printers/mk3s.svg'
import IconMk3sMmu3 from '../../svg/printers/mk3smmu3.svg'
import IconMk4 from '../../svg/printers/mk4.svg'
import IconMk4Mmu3 from '../../svg/printers/mk4mmu3.svg'
import IconMk4s from '../../svg/printers/mk4s.svg'
import IconMk4sMmu3 from '../../svg/printers/mk4smmu3.svg'
import IconMk25 from '../../svg/printers/mk25.svg'
import IconMk25s from '../../svg/printers/mk25s.svg'
import IconMk35 from '../../svg/printers/mk35.svg'
import IconMk35Mmu from '../../svg/printers/mk35mmu3.svg'
import IconMk35s from '../../svg/printers/mk35s.svg'
import IconMk35sMmu from '../../svg/printers/mk35smmu3.svg'
import IconMk39 from '../../svg/printers/mk39.svg'
import IconMk39Mmu3 from '../../svg/printers/mk39mmu3.svg'
import IconMk39s from '../../svg/printers/mk39s.svg'
import IconMk39sMmu3 from '../../svg/printers/mk39smmu3.svg'
import IconSl1 from '../../svg/printers/sl1.svg'
import IconSl1S from '../../svg/printers/sl1s.svg'
import IconTrilabDeltiq2 from '../../svg/printers/trilab_deltiq_2.svg'
import IconTrilabDeltiq2Plus from '../../svg/printers/trilab_deltiq_2_plus.svg'
import Unknown from '../../svg/printers/unknown.svg'
import IconXl from '../../svg/printers/xl.svg'
import IconXlEnclosure from '../../svg/printers/xl_enclosure.svg'
import IconXl2ToolsEnclosure from '../../svg/printers/xl_multitool_2_enclosure.svg'
import IconXl3ToolsEnclosure from '../../svg/printers/xl_multitool_3_enclosure.svg'
import IconXl4ToolsEnclosure from '../../svg/printers/xl_multitool_4_enclosure.svg'
import IconXl5ToolsEnclosure from '../../svg/printers/xl_multitool_5_enclosure.svg'
import IconXl2tool from '../../svg/printers/xlmultitool2.svg'
import IconXl3tool from '../../svg/printers/xlmultitool3.svg'
import IconXl4tool from '../../svg/printers/xlmultitool4.svg'
import IconXl5tool from '../../svg/printers/xlmultitool5.svg'
import { PrinterType } from '../../types/printers'

function getUrl(type?: string, countOfTools?: number, mmu?: boolean, enclosure?: boolean) {
  switch (type) {
    case PrinterType.MK25:
      return IconMk25
    case PrinterType.MK25S:
      return IconMk25s
    case PrinterType.MK3:
      return mmu ? IconMk3Mmu2 : IconMk3
    case PrinterType.MK3S:
      return mmu ? IconMk3sMmu3 : IconMk3s
    case PrinterType.MK35:
      return mmu ? IconMk35Mmu : IconMk35
    case PrinterType.MK35S:
      return mmu ? IconMk35sMmu : IconMk35s
    case PrinterType.MK39:
      return mmu ? IconMk39Mmu3 : IconMk39
    case PrinterType.MK39S:
      return mmu ? IconMk39sMmu3 : IconMk39s
    case PrinterType.MK4:
      return mmu ? IconMk4Mmu3 : IconMk4
    case PrinterType.MK4S:
      return mmu ? IconMk4sMmu3 : IconMk4s
    case PrinterType.MINI:
      return IconMini
    case PrinterType.XL:
      if (countOfTools === 5) return enclosure ? IconXl5ToolsEnclosure : IconXl5tool
      if (countOfTools === 4) return enclosure ? IconXl4ToolsEnclosure : IconXl4tool
      if (countOfTools === 3) return enclosure ? IconXl3ToolsEnclosure : IconXl3tool
      if (countOfTools === 2) return enclosure ? IconXl2ToolsEnclosure : IconXl2tool
      return enclosure ? IconXlEnclosure : IconXl
    case PrinterType.IX:
      return IconIx
    case PrinterType.SL1:
      return IconSl1
    case PrinterType.SL1S:
      return IconSl1S
    case PrinterType.M1:
      return IconSlMedicalOne
    case PrinterType.HT90:
      return IconHT90
    case PrinterType.DELTIQ_2:
      return IconTrilabDeltiq2
    case PrinterType.DELTIQ_2_PLUS:
      return IconTrilabDeltiq2Plus
    case PrinterType.CORE_ONE:
      return IconCoreOne
    case PrinterType.NEXT:
      return Unknown
    case '252.1.0':
      return Harvester
    case '251.1.0':
      return Shelves
    case '250.1.0':
      return Frame
    default:
      return Unknown
  }
}

type Props = {
  type?: string
  title?: string
  size?: number | string
  enclosure?: boolean
  countOfTools?: number
  isMmu?: boolean
  style?: CSSProperties
}

export function PrinterImage({ type, size, title, enclosure, countOfTools, isMmu, style }: Props) {
  const src = getUrl(type, countOfTools, isMmu, enclosure)
  const inlineStyle = { ...style, width: size, height: size }

  return <img className="max-w-none" src={src} style={inlineStyle} alt={title} />
}
