import { useEffect } from 'react'
import { Outlet, useNavigate } from 'react-router-dom'

import { useAuthState } from '../context/authStore'
import { useInitAppziFeedback } from '../helpers/useInitAppziFeedback'
import { useSleekplan } from '../helpers/useSleekplan'
import { useRedirectUrl } from '../hooks/useRedirectUrl'

// Redirect to / if user is NOT logged
export function PrivateRoute() {
  const { isAuthenticated } = useAuthState()
  const { setupRedirectUrl } = useRedirectUrl()
  const navigate = useNavigate()
  useInitAppziFeedback()
  useSleekplan()

  useEffect(() => {
    // store redirect url for later
    if (isAuthenticated === false) {
      setupRedirectUrl()
      navigate('/login')
    }
  }, [isAuthenticated, navigate, setupRedirectUrl])

  if (isAuthenticated === true) {
    return <Outlet />
  }
}
