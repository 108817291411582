import { ConnectState } from '@prusaconnect/api'

import { IPager, IPagerQuery } from './pager'
import { IPrinter } from './printer'
import { IVector3 } from './vector'

// /app/printers/groups
export type IGroupOverview = {
  created: number
  description?: string
  farm: boolean
  id: number
  name: string
  printer_count: number
  size?: IGroupSize
  team_id: number
}

// /app/printers/groups/{group_id}
export type IGroupDetail = IGroupOverview & {
  full_positions?: IVector3[]
}

type IGroupInfo = {
  enabled: boolean
  position?: IVector3
}

export type IGroupPrinter = IPrinter & { group_info?: IGroupInfo }

// /app/printers/groups/{group_id}/printers
export type IGroupPrintersResponse = {
  printers: IGroupPrinter[]
  pager: IPager
  group: IGroupOverview
}

export type IGroupPrintersRequest = IPagerQuery & {
  state_include?: ConnectState[]
}

// Used in a printer's detail
export type IGroupSimpleView = {
  id: number
  name: string
  description?: string
  farm: boolean
  size?: IGroupSize
}

export enum IGroupPattern {
  GRID = 'grid',
  SHIFTED = 'shifted'
}

export type IGroupSize = IVector3 & {
  pattern: IGroupPattern
}

export type IGroupsResponse = {
  groups: IGroupOverview[]
  pager?: IPager
}

export type IPrinterInGroupAttributes = {
  position?: IVector3
}

// PUT /app/printers/groups/{groupId}
export type IEditGroupRequestBody = {
  team_id: number
  name: string
  description?: string
  farm?: boolean
  size?: IVector3
}
