import * as TooltipPrimitive from '@radix-ui/react-tooltip'
import React from 'react'

export type TooltipPosition = 'top' | 'right' | 'bottom' | 'left'

export function Tooltip({
  content,
  children,
  disabled,
  showPopover,
  side = 'top',
  asChild = false,
  inverse = false,
  ...props
}: {
  content: React.ReactNode
  children: React.ReactNode
  disabled?: boolean
  showPopover?: boolean
  side?: TooltipPosition
  asChild?: boolean
  inverse?: boolean
}) {
  return disabled || !content ? (
    <>{children}</>
  ) : (
    <TooltipPrimitive.Provider delayDuration={100}>
      <TooltipPrimitive.Root open={showPopover ?? undefined}>
        <TooltipPrimitive.Trigger asChild={asChild}>{children}</TooltipPrimitive.Trigger>
        <TooltipPrimitive.Content
          className="tooltip-inner bg-neutral-900 dark:bg-neutral-100 text-neutral-100 dark:text-neutral-900 text-sm p-2 rounded-md fade-in z-[999]"
          side={side}
          {...props}
        >
          <TooltipPrimitive.Arrow
            className={`fill-neutral-900 dark:fill-neutral-100 ${inverse && 'fill-neutral-100 dark:fill-neutral-200'}`}
            width={15}
            height={7}
          />
          {content}
        </TooltipPrimitive.Content>
      </TooltipPrimitive.Root>
    </TooltipPrimitive.Provider>
  )
}
