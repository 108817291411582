import { useUserPreferences } from '../../../context/UserPreferencesContext'
import { THINSP } from '../../../lib/textSymbols'

export function Distance({ distance, decimalPlaces }: { distance: number; decimalPlaces?: number }) {
  const { units } = useUserPreferences()
  const dimensions = units?.dimensions || 'mm'
  return (
    <span>
      {decimalPlaces !== undefined && distance.toFixed(decimalPlaces)}
      {decimalPlaces === undefined && String(distance)}
      {THINSP}
      {dimensions}
    </span>
  )
}
