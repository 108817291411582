import '@prusaconnect/ui/css/main.css'
import '@prusaconnect/ui/fonts/fonts'
/** Inject api object into Window, can connect to slicer app */
import './slicer/publicApi'

import createApiClient, { ApiClientContext, setAccessToken } from '@prusaconnect/api'
import i18n from '@prusaconnect/prusalator-i18next'
import * as Sentry from '@sentry/react'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import { useEffect } from 'react'
import { HelmetProvider } from 'react-helmet-async'
import { I18nextProvider } from 'react-i18next'
import { RouterProvider } from 'react-router-dom'

import { createApiClient as createOldApiClient } from './api/createClient'
import { ApiContextProvider as OldApiContextProvider } from './api/react'
import { ErrorBoundary } from './components/errors/ErrorBoundary'
import { config } from './config'
import { QueryClientProvider } from './context/QueryClientProvider'
import { router } from './router'
import { isSlicerAvailable } from './slicer/hooks/useSlicer'
import { useSlicerConfig } from './slicer/hooks/useSlicerConfig'

type Props = {
  i18next: typeof i18n
}

export function App(props: Props) {
  const { accessToken } = useSlicerConfig()

  useEffect(() => {
    if (accessToken) {
      setAccessToken(accessToken)
    }
  }, [accessToken])

  const isInsideSlicer = isSlicerAvailable()

  if (isInsideSlicer && !accessToken) return null

  const oldApiClient = createOldApiClient(config.hostname)
  const apiClient = createApiClient(config.hostname, { accessToken })

  return (
    <I18nextProvider i18n={props.i18next}>
      <Sentry.ErrorBoundary fallback={<ErrorBoundary />}>
        <OldApiContextProvider apiClient={oldApiClient}>
          <ApiClientContext.Provider value={apiClient}>
            <QueryClientProvider>
              <HelmetProvider>
                <RouterProvider router={router} />
              </HelmetProvider>
              <ReactQueryDevtools initialIsOpen={false} />
            </QueryClientProvider>
          </ApiClientContext.Provider>
        </OldApiContextProvider>
      </Sentry.ErrorBoundary>
    </I18nextProvider>
  )
}
