import { Button, Dialog, DialogButtons, DialogContent } from '@prusaconnect/ui'
import { useMutation } from '@tanstack/react-query'
import { ChangeEvent, useCallback, useState } from 'react'
import { Form } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import { useApiClient } from '../../../api/react'
import { IStoppedPrintReasonPayload, StoppedPrintReasons } from '../../../api/types/job'
import { isFdmPrinter } from '../../../hooks/usePrinterType'
import { useStoppedPrint } from '../../../hooks/useStoppedPrint'
import { CustomCheckbox } from '../../common/CustomCheckbox'
import { Layers } from '../../helpers/zIndex'

type IReasonTranslations = Omit<
  { [key in StoppedPrintReasons]: string },
  StoppedPrintReasons.OTHER | StoppedPrintReasons.IGNORED
>

const useReasonTranslations = () => {
  const { t } = useTranslation()
  return {
    CLOGGED_NOZZLE: t('printer.actions.stopped-print.reason.CLOGGED_NOZZLE'),
    NON_ADHERENT_BED: t('printer.actions.stopped-print.reason.NON_ADHERENT_BED'),
    UNDER_EXTRUSION: t('printer.actions.stopped-print.reason.UNDER_EXTRUSION'),
    OVER_EXTRUSION: t('printer.actions.stopped-print.reason.OVER_EXTRUSION'),
    STRINGING_OR_OOZING: t('printer.actions.stopped-print.reason.STRINGING_OR_OOZING'),
    GAPS_IN_THIN_WALLS: t('printer.actions.stopped-print.reason.GAPS_IN_THIN_WALLS'),
    OVERHEATING: t('printer.actions.stopped-print.reason.OVERHEATING'),
    LAYER_SHIFTING: t('printer.actions.stopped-print.reason.LAYER_SHIFTING'),
    SPAGHETTI_MONSTER: t('printer.actions.stopped-print.reason.SPAGHETTI_MONSTER'),
    LAYER_SEPARATION: t('printer.actions.stopped-print.reason.LAYER_SEPARATION'),
    WARPING: t('printer.actions.stopped-print.reason.WARPING'),
    POOR_BRIDGING: t('printer.actions.stopped-print.reason.POOR_BRIDGING')
  }
}

const FormGroup = styled.div<{
  gridColumn: number
}>`
  display: flex;
  align-items: center;
  gap: 0.2rem;
  grid-column: ${({ gridColumn }) => gridColumn};
  padding: 0.2rem 0;
`

const Label = styled(Form.Label)`
  position: relative;
  margin: 0;
  user-select: none;
`

const BodyWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 5px;
  font-size: 14px;
  padding: 0.5rem;
  margin-bottom: 1rem;
`

const Textarea = styled.textarea`
  box-sizing: border-box;
  font-size: 13px;
  padding: 0.2rem 0.5rem;
  width: 100%;
`

const ReasonImg = styled.img<{ show: boolean }>`
  position: absolute;
  top: 100%;
  left: 50%;
  width: 100px;
  border: 1px solid ${({ theme }) => theme.colors.primary};
  z-index: ${Layers.MODAL + 1};
  visibility: ${({ show }) => (show ? 'visible' : 'hidden')};
`

const getImage = (imgName: string) => {
  const imageUrl = new URL(`../../../img/stoppedPrintReasons/${imgName}.jpg`, import.meta.url)
  return imageUrl.href
}

type FormData = Omit<{ [key in StoppedPrintReasons]: boolean }, StoppedPrintReasons.OTHER | StoppedPrintReasons.IGNORED>

type HoverableReason = keyof FormData

type ReasonsDialogBodyProps = {
  formData: FormData
  otherReason: string
  reasonTranslations: IReasonTranslations
  handleChange: (e: any) => void
  onTextChange: (e: any) => void
}

const ReasonsDialogBody = ({
  formData,
  otherReason,
  reasonTranslations,
  handleChange,
  onTextChange
}: ReasonsDialogBodyProps) => {
  const { t } = useTranslation()
  const [hoveredReason, setHoveredReason] = useState<HoverableReason>()
  return (
    <>
      <BodyWrapper>
        {Object.keys(formData).map((reason, i) => (
          <FormGroup key={reason} gridColumn={i % 2 === 0 ? 1 : 2}>
            <CustomCheckbox
              id={`checkbox-id-${reason}`}
              name={reason}
              checked={formData[reason as keyof typeof formData]}
              onChange={handleChange}
            />
            <Label
              htmlFor={`checkbox-id-${reason}`}
              onMouseEnter={() => setHoveredReason(reason as HoverableReason)}
              onMouseLeave={() => setHoveredReason(undefined)}
            >
              {reasonTranslations[reason as keyof IReasonTranslations]}
              <ReasonImg src={getImage(reason)} show={hoveredReason === reason} />
            </Label>
          </FormGroup>
        ))}
      </BodyWrapper>
      <Textarea
        className="form-control"
        rows={3}
        value={otherReason}
        placeholder={t('printer.actions.stopped-print.textarea.description')}
        onChange={onTextChange}
      />
    </>
  )
}

type SendReasonParams = {
  uuid: string
  jobId: number
  reason: IStoppedPrintReasonPayload
}

const formInitalState = {
  CLOGGED_NOZZLE: false,
  NON_ADHERENT_BED: false,
  UNDER_EXTRUSION: false,
  OVER_EXTRUSION: false,
  STRINGING_OR_OOZING: false,
  GAPS_IN_THIN_WALLS: false,
  OVERHEATING: false,
  LAYER_SHIFTING: false,
  SPAGHETTI_MONSTER: false,
  LAYER_SEPARATION: false,
  WARPING: false,
  POOR_BRIDGING: false
}

export function StoppedPrintReasonsDialog() {
  const { jobId, uuid, printerType, showStoppedPrintReasonsDialog, setShowStoppedPrintDialogs } = useStoppedPrint()
  const reasonTranslations = useReasonTranslations()
  const [otherReason, setOtherReason] = useState<string>('')
  const { t } = useTranslation()
  const api = useApiClient()
  const [formData, setFormData] = useState<FormData>(formInitalState)
  const isFdm = isFdmPrinter(printerType)

  const sendReasonMutation = useMutation({
    mutationFn: (params: SendReasonParams) => api.app.jobs.editJobEndReason(params.uuid, params.jobId, params.reason)
  })

  const handleChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      [e.target.name]: e.target.checked
    }))
  }, [])

  const onTextChange = useCallback((e: ChangeEvent<HTMLTextAreaElement>) => {
    setOtherReason(e.target.value)
  }, [])

  const sendReason = () => {
    if (uuid && typeof jobId === 'number') {
      let tag = Object.keys(formData).filter((key) => formData[key as keyof FormData])
      const isIgnored = !tag.length && !otherReason
      if (isIgnored) {
        tag = [StoppedPrintReasons.IGNORED]
      }
      if (otherReason) {
        tag = [...tag, StoppedPrintReasons.OTHER]
      }
      const reason = {
        reason: {
          tag: tag as StoppedPrintReasons[],
          ...(otherReason ? { other: otherReason } : {})
        }
      }
      sendReasonMutation.mutate({
        uuid,
        jobId,
        reason
      })
    }
    resetState()
  }

  const resetState = () => {
    setShowStoppedPrintDialogs(false)
    setFormData(formInitalState)
    setOtherReason('')
  }

  const ignoreDialog = () => {
    if (uuid && typeof jobId === 'number') {
      const reason = {
        reason: {
          tag: [StoppedPrintReasons.IGNORED]
        }
      }
      sendReasonMutation.mutate({
        uuid,
        jobId,
        reason
      })
    }
    resetState()
  }

  return (
    <Dialog open={showStoppedPrintReasonsDialog && isFdm} onOpenChange={ignoreDialog}>
      <DialogContent title={t('printer.actions.stopped-print.reason')}>
        <ReasonsDialogBody
          formData={formData}
          handleChange={handleChange}
          onTextChange={onTextChange}
          reasonTranslations={reasonTranslations}
          otherReason={otherReason}
        />

        <DialogButtons>
          <Button onClick={ignoreDialog}>{t('printer.actions.stopped-print.reason.ignore')}</Button>
          <Button onClick={sendReason}>{t('printer.actions.stopped-print.reason.send')}</Button>
        </DialogButtons>
      </DialogContent>
    </Dialog>
  )
}
