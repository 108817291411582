import { Link } from 'react-router-dom'
import styled, { useTheme } from 'styled-components'

import { getState, IConnectState } from '../../../api/types/state'
import { getColorScheme } from '../../../theme/getColorScheme'
import { textEllipsis } from '../../helpers/styled'
import { Time } from '../../helpers/time'

const Centered = styled.div`
  display: flex;
  justify-content: center;
  text-align: center;
  flex-direction: column;
  margin: 0 auto;
`

export const Top = styled(Centered)`
  flex: 1;
  width: 100%;
`
export const Center = styled(Centered)`
  flex-shrink: 0;
  flex-grow: 0;
  margin: 0.25rem auto;
`
export const Bottom = styled(Centered)`
  flex: 1;
  width: 100%;
  justify-content: flex-start;
`

export const Name = styled.div`
  ${textEllipsis};
  margin: 0.25rem auto 0;
  font-weight: 500;
  width: 65%;
  color: var(--color-primary);
`

export const Status = styled.span`
  text-transform: uppercase;
  font-size: 0.9rem;
  color: black;
  font-weight: 500;
`

export const TimeLabel = styled.div`
  text-transform: uppercase;
`

export const TimeValue = styled(Time)`
  width: 65%;
  display: block;
  margin: 0 auto;
  white-space: normal;
`

export const PrintingInfo = styled.div`
  color: black;
  width: 100%;
  font-size: 0.7rem;
`

type ContainerProps = { $state: IConnectState; $progress: number | undefined }

export const Container = styled(Link).attrs<ContainerProps>(({ $state, $progress }) => {
  const theme = useTheme()
  const colorScheme = getColorScheme(getState($state).baseColor, theme.name === 'dark')

  return {
    // Gradient via attrs() to prevent generating many CSS classes
    style: {
      background:
        $state === IConnectState.PRINTING && $progress !== undefined
          ? `conic-gradient(
      ${colorScheme.backgroundSecondary.background} ${Math.round($progress)}%,
      ${colorScheme.progress.background} 0
    )`
          : undefined
    }
  }
})<ContainerProps>`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  text-align: center;
  color: #fff;
  background-color: ${(props) =>
    getColorScheme(getState(props.$state).baseColor, props.theme.name === 'dark').backgroundSecondary.background};
  overflow: hidden;

  &:hover {
    text-decoration: none;
    background: var(--color-primary) !important;
    color: white !important;
    cursor: pointer;

    .printer-name {
      color: white;
    }
  }
`
