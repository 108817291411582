import i18next from '@prusaconnect/prusalator-i18next'
import { createContext, ReactNode, useEffect, useState } from 'react'

import { useJwtStore } from '../store/jwt'
import { isSlicerAvailable, sendMessage } from './hooks/useSlicer'
import { InitOptions } from './publicApi'
import { EventTypes, SlicerEvent } from './types'

export const SlicerConfigContext = createContext<InitOptions>({})

let shouldReloadOnInit = false
/** This is ugly way, how to send information about logout from query client to slicer */
export function notifySlicerAboutLogout() {
  shouldReloadOnInit = true
}

export function SlicerConfigProvider({ children }: { children: ReactNode }) {
  const [config, setConfig] = useState<InitOptions>({})
  const setAccessToken = useJwtStore((s) => s.setTokens)

  const initListener = (event: SlicerEvent<InitOptions>) => {
    const data = event.detail.data
    setConfig((prev) => ({
      ...prev,
      ...data
    }))

    // Copy access token to jwt store
    if (data.accessToken) {
      setAccessToken(data.accessToken)
    }

    // If page is in "logged out" state and slicer send init, reload page
    if (shouldReloadOnInit) document.location.reload()
  }

  const logoutListener = () => {
    setConfig({})
  }

  useEffect(() => {
    if (isSlicerAvailable()) {
      const tokenListener = (event: SlicerEvent<string>) =>
        setConfig((prev) => ({
          ...prev,
          accessToken: event.detail.data
        }))
      const sessionIdListener = (event: SlicerEvent<string>) =>
        setConfig((prev) => ({
          ...prev,
          sessionId: event.detail.data
        }))

      window.addEventListener(EventTypes.INIT, initListener)
      window.addEventListener(EventTypes.LOGOUT, logoutListener)
      window.addEventListener(EventTypes.CREATE_ACCESS_TOKEN, tokenListener)
      window.addEventListener(EventTypes.CREATE_SESSION_ID, sessionIdListener)

      sendMessage({
        action: 'REQUEST_CONFIG'
      })
    }
  }, [])

  useEffect(() => {
    if (config?.language) {
      i18next.changeLanguage(config.language)
    }
  }, [config?.language])

  return <SlicerConfigContext.Provider value={config}>{children}</SlicerConfigContext.Provider>
}
