import { type TFunction } from 'i18next'
import { useTranslation } from 'react-i18next'

import { getNameFromPath } from '../helpers/files'
import { getJobHash } from '../helpers/jobs'

type Job = {
  id?: number
  display_name?: string
  display_path?: string
  path?: string
  hash?: string
  file?: { display_name?: string }
}

export function getJobTitle(t: TFunction, job: Job) {
  if ('display_name' in job && job.display_name) {
    return job.display_name
  }

  if ('file' in job) {
    if (job.file?.display_name) {
      return job.file.display_name
    }
  }

  if (('display_path' in job && job.display_path) || ('path' in job && job.path)) {
    return getNameFromPath(job.display_path || job.path)
  }

  if (job.id) {
    return `${t(`jobs.print-job`)} #${job.id}`
  }

  if ('origin_id' in job && job.origin_id) {
    return `${t(`jobs.print-job`)} #${job.origin_id}`
  }

  return `${t(`jobs.print-job`)} "${getJobHash(job)}"`
}

export function useJobTitle(job: Job) {
  const { t } = useTranslation()
  return getJobTitle(t, job)
}

export function getJobDisplayPath(job: { file?: { display_path?: string; path?: string } }) {
  if (job.file?.display_path) {
    return job.file.display_path
  }

  return job.file?.path
}
