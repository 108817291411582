import { useTranslation } from 'react-i18next'

/**
 * @todo refactor to clear function, without hooks
 */
export function useTeamName() {
  const { t } = useTranslation()

  const getTeamName = (team?: { name?: string; id?: number }): string => {
    if (!team) {
      return ''
    }

    return team.name || t('team.default-name', { id: team.id })
  }

  return { getTeamName }
}
