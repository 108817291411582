import { useContext } from 'react'

import { ThemeModeContext } from '../context/ThemeModeContext'

export function useColorMode() {
  const context = useContext(ThemeModeContext)
  if (!context) {
    throw new Error('<ThemeModeProvider> is missing in component tree!')
  }

  return context
}
