import {
  INotificationName,
  isAttentionNotification,
  isIncidentNotification,
  isInvitationNotification,
  isJobNotification,
  isPrinterNotification,
  isTestNotification,
  isTransferNotification,
  Notification,
  useConfig
} from '@prusaconnect/api'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { useTheme } from 'styled-components'

import { getJobHash } from '../../helpers/jobs'
import { ITimetype } from '../../interfaces/time'
import { FileHashPreview } from '../common/FileHashPreview'
import { Markdown } from '../common/Markdown'
import { SvgIcon } from '../common/SvgIcon'
import { EmptyAvatar } from '../helpers/Avatar'
import { Time } from '../helpers/time'
import { NotificationIconStyled, NotificationsHeader, StyledNotification } from './Notifications.styled'
import { useNotificationBody } from './useNotificationBody'

function getNotificationLink(notification: Notification) {
  if (isTestNotification(notification)) {
    return '/notifications/settings'
  }

  if (isInvitationNotification(notification)) {
    return `/invitation/${notification.data?.invitation_code}`
  }

  if (isIncidentNotification(notification)) {
    const uuid = notification.data?.printer_uuid
    return `/printer/${uuid}`
  }

  if (isTransferNotification(notification)) {
    const uuid = notification.data?.printer_uuid
    return `/printer/${uuid}/transfers`
  }

  if (isJobNotification(notification)) {
    const jobId = notification.data?.job_id
    const uuid = notification.data?.printer_uuid
    return `/printer/${uuid}/jobs/${jobId}`
  }

  if (isPrinterNotification(notification)) {
    const uuid = notification.data?.printer_uuid
    return `/printer/${uuid}`
  }

  return ''
}

function countUnreadNotifications(notifications: Notification[]) {
  return notifications.filter((notification) => !notification.read).length
}

function NotificationIcon({ notification }: { notification: Notification }) {
  const config = useConfig()
  const theme = useTheme()

  if (notification.name === INotificationName.FILAMENT_CHANGE_IN) {
    return (
      <NotificationIconStyled>
        <SvgIcon icon={theme.isDark ? 'spoolWhiteColorIcon' : 'spoolColorIcon'} size={30} />
      </NotificationIconStyled>
    )
  }

  if (isJobNotification(notification) && notification.data?.preview_url) {
    return <NotificationIconStyled url={notification.data?.preview_url} />
  }

  if (isJobNotification(notification)) {
    return (
      <NotificationIconStyled>
        <FileHashPreview hash={getJobHash(notification.data)} />
      </NotificationIconStyled>
    )
  }

  if (notification.name === INotificationName.INCIDENT_OFFLINE) {
    return (
      <NotificationIconStyled>
        <SvgIcon icon="noWifiIconIcon" size={30} />
      </NotificationIconStyled>
    )
  }

  if (isAttentionNotification(notification) || isIncidentNotification(notification)) {
    return (
      <NotificationIconStyled>
        <SvgIcon icon={theme.isDark ? 'attentionInverseIcon' : 'attentionIcon'} size={30} />
      </NotificationIconStyled>
    )
  }

  if (isInvitationNotification(notification) && config) {
    return (
      <NotificationIconStyled url={`${config.auth.avatar_server_url}${notification.data?.invitor_avatar}`}>
        {!notification.data?.invitor_avatar && <EmptyAvatar />}
      </NotificationIconStyled>
    )
  }

  if (notification.name === INotificationName.FW_UPDATE) {
    return (
      <NotificationIconStyled>
        <SvgIcon icon="firmwareIcon" size={30} />
      </NotificationIconStyled>
    )
  }

  if (notification.name === INotificationName.LINK_UPDATE) {
    return (
      <NotificationIconStyled>
        <SvgIcon icon={theme.isDark ? 'linkMicroSdInverseIcon' : 'linkMicroSdIcon'} size={30} />
      </NotificationIconStyled>
    )
  }

  if (notification.name === INotificationName.TRANSFER_ABORTED) {
    return (
      <NotificationIconStyled>
        <SvgIcon icon="downloadArrowColorIcon" size={30} />
      </NotificationIconStyled>
    )
  }

  if (notification.name === INotificationName.PRINTER_ERROR) {
    return (
      <NotificationIconStyled>
        <SvgIcon icon={theme.isDark ? 'stateErrorInverseIcon' : 'stateErrorIcon'} size={30} />
      </NotificationIconStyled>
    )
  }
  return <NotificationIconStyled />
}

function NotificationRow({ notification, setRead }: { notification: Notification; setRead: (id: number) => void }) {
  const notificationBody = useNotificationBody(notification)

  return notificationBody ? (
    <Link
      to={getNotificationLink(notification)}
      state={{
        incidentDialogIsOpened: isIncidentNotification(notification)
      }}
      onClick={() => setRead(notification.id)}
    >
      <StyledNotification read={!!notification.read}>
        <NotificationIcon notification={notification} />
        <div className="notification-body">
          <Markdown>{notificationBody}</Markdown>{' '}
          <span style={{ opacity: 0.4 }}>
            <Time unixTimestamp={notification.created} type={ITimetype.DISTANCE_TO_NOW} />
          </span>
        </div>
      </StyledNotification>
    </Link>
  ) : null
}

type Props = {
  notifications: Notification[]
  setRead: (id: number) => void
  setAllRead: () => void
}

export function Notifications({ notifications, setRead, setAllRead }: Props) {
  const { t } = useTranslation()

  return (
    <>
      <NotificationsHeader>
        <h2>{t('notifications.header', 'Notifications')}</h2>
        <div className="button-container">
          <button
            type="button"
            className={`set-all-read whitespace-nowrap ${countUnreadNotifications(notifications) === 0 && 'disabled'}`}
            data-exception-element="true"
            onClick={() => setAllRead()}
          >
            {t('notifications.mark-all-read', 'Mark All Read')}
          </button>
          <button type="button">
            <Link to="/notifications/settings">{t('notifications.settings', 'Settings')}</Link>
          </button>
        </div>
      </NotificationsHeader>

      {notifications.map((notification) => (
        <NotificationRow key={notification.id} notification={notification} setRead={setRead} />
      ))}
    </>
  )
}
