import { useQuery } from '@tanstack/react-query'
import ms from 'ms'

import { useApiClient } from '../api/react'

export function getBoardName(printerType: string) {
  if (printerType.match(/^1.(3.[59]|4.\d)$/)) {
    return 'xBuddy'
  }

  if (printerType.match(/^1.(2|3).\d$/)) {
    return 'Einsy RAMBO'
  }

  if (printerType.match(/^2.1.\d$/)) {
    return 'Buddy'
  }

  if (printerType.match(/^3.1.\d$/)) {
    return 'XLBuddy'
  }

  return ''
}

export function usePrinterTypes() {
  const api = useApiClient()
  const { data } = useQuery({
    queryKey: ['/printer-types'],
    queryFn: () => api.app.printers.getTypes(),
    gcTime: ms('1day')
  })
  return data?.printer_types || []
}

export function usePrinterType(printerTypeId?: string) {
  const printer_types = usePrinterTypes()
  return printer_types.find((printerType) => printerType.id === printerTypeId)
}

export function useFdmPrinterType() {
  const printer_types = usePrinterTypes()
  return printer_types.filter((printerType) => printerType.type !== 5)
}

export function useSlaPrinterTypes() {
  const printer_types = usePrinterTypes()
  return printer_types.filter((printerType) => printerType.type === 5)
}

export function isSlaPrinter(printerType: string) {
  return /^5/i.test(printerType)
}

export function isFdmPrinter(printerType: string) {
  return /^[123467]/i.test(printerType)
}
