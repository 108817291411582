import { type components, type paths, useApiClient } from '@prusaconnect/api'
import { useMutation } from '@tanstack/react-query'

import useServiceResponseHandler from '../../services/useServiceResponseHandler'

type UpdateData = components['schemas']['user_update']
type ProfileResponse = paths['/app/login']['patch']['responses']['200']['content']['application/json']

export function useUpdateProfile(onSuccess?: (response: ProfileResponse) => void) {
  const { rawApi } = useApiClient()
  const { displayError } = useServiceResponseHandler()

  const {
    mutate: update,
    isSuccess,
    isPending
  } = useMutation({
    mutationFn: (profileData: UpdateData) =>
      rawApi.PATCH('/app/login', { body: profileData }).then((response) => response.data as ProfileResponse),

    onSuccess,
    onError: (error: Error) => displayError(error)
  })

  return {
    update,
    isSuccess,
    isPending
  }
}
