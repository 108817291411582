import { ReactNode, useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { ReactComponent as OkIcon } from '../../../icons/general/ok.svg'
import { Button } from '../../primitives/Button'
import { Dialog, DialogButtons, DialogContent, DialogTrigger } from './Dialog'

type Props = {
  title: string
  body?: ReactNode
  children?: ReactNode
  onConfirm?: () => Promise<any> | void
  onCancel?: () => void
  confirmText?: string
  confirmIcon?: ReactNode
  cancelText?: string
  open?: boolean
  noChoice?: boolean
  disabled?: boolean
}

export function ConfirmDialog({
  title,
  body,
  children,
  onConfirm,
  onCancel,
  confirmText,
  confirmIcon,
  cancelText,
  open,
  noChoice,
  disabled
}: Props) {
  const [localOpen, setLocalOpen] = useState(false)
  const [confirmLoading, setConfirmLoading] = useState(false)

  const openState = open !== undefined ? open : localOpen

  const { t } = useTranslation()

  const handleSubmit = useCallback(async () => {
    if (onConfirm) {
      setConfirmLoading(true)
      await onConfirm()
    }
    setLocalOpen(false)
    setConfirmLoading(false)
  }, [onConfirm])

  const handleOpenClose = useCallback(
    (open: boolean) => {
      setLocalOpen(open)
      if (!open) {
        onCancel?.()
      }
    },
    [onCancel]
  )

  return (
    <Dialog open={openState} onOpenChange={handleOpenClose}>
      <DialogContent title={title}>
        <p>{body || t('are-you-sure', 'Are you sure you want to confirm this action?')}</p>

        <DialogButtons>
          {!noChoice && (
            <Button disabled={disabled} onClick={() => handleOpenClose(false)}>
              {cancelText || t('cancel', 'Cancel')}
            </Button>
          )}
          <Button icon={confirmIcon || OkIcon} onClick={handleSubmit} loading={confirmLoading} disabled={disabled}>
            {confirmText || t('confirm', 'Confirm')}
          </Button>
        </DialogButtons>
      </DialogContent>

      <DialogTrigger onClick={() => setLocalOpen(true)}>{children}</DialogTrigger>
    </Dialog>
  )
}
