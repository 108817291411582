import { useEffect } from 'react'
import { Container } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate } from 'react-router'
import styled from 'styled-components'

import { useGroupFilterData } from '../../../hooks/useGroupFilterData'
import { useTeams } from '../../../hooks/useTeams'
import { Icon } from '../../../icons'
import { PrintersViewEnum } from '../../../interfaces/view'
import { usePrintersOverviewStore } from '../../../store/printersOverview'
import { Row } from '../../bootstrap/Row'
import { AddNewPrinterButton } from '../../common/AddNewPrinterButton'
import { DocumentTitle } from '../../header/DocumentTitle'
import { Group as SubmenuGroup, GroupTitle, ViewItem } from '../../helpers/submenu'
import { Filters } from '../Filters'
import { Sorting } from '../filters/Sorting'
import { CardsView } from './CardsView'
import { GridView } from './GridView'
import { ListView } from './ListView'
import { QueueView } from './QueueView'

const FilterRow = styled(Container)`
  background: var(--background-body);
  padding: 1.5em 0;
`

const Group = styled(SubmenuGroup)`
  ${GroupTitle} {
    padding: 0;
  }
`

const initialViews = [PrintersViewEnum.TABLE, PrintersViewEnum.CARDS, PrintersViewEnum.QUEUE]

function getViewIcon(view: PrintersViewEnum): Icon {
  switch (view) {
    case PrintersViewEnum.TABLE:
      return 'rowsIcon'
    case PrintersViewEnum.GRID:
      return 'gridIcon'
    case PrintersViewEnum.QUEUE:
      return 'queueIcon'
    case PrintersViewEnum.CARDS:
      return 'cardsViewIcon'
  }
}

export function ViewIcons() {
  const location = useLocation()
  const { groupId, setParams } = usePrintersOverviewStore()
  const supportsGridView = !!groupId
  const views = supportsGridView ? [...initialViews, PrintersViewEnum.GRID] : initialViews

  const baseUrl = '/printers'

  return (
    <>
      {views.map((view) => (
        <ViewItem
          key={view}
          to={`${baseUrl}/${view}${location.search}`}
          onClick={() => setParams({ view })}
          icon={getViewIcon(view)}
          active={location.pathname === `${baseUrl}/${view}`}
        />
      ))}
    </>
  )
}

export function PrintersOverview() {
  const navigate = useNavigate()
  const { t } = useTranslation()
  const { groupId, teamId, resetFilter, view } = usePrintersOverviewStore()

  const teams = useTeams()
  const { groupList, isLoading } = useGroupFilterData()

  useEffect(() => {
    const isNonexistentGroup = !isLoading && groupId && !groupList.some((memberGroup) => memberGroup.id === groupId)
    const isNonexistentTeam = teamId && !teams.some((team) => team.id === teamId)

    if (isNonexistentGroup || isNonexistentTeam) {
      resetFilter()
    }
  }, [groupList, teams, isLoading, navigate, resetFilter, groupId, teamId])

  return (
    <>
      <DocumentTitle content={t('menu.printers')} />
      <FilterRow>
        <Row noGutters className="content-between items-center">
          <div className="flex grow flex-wrap gap-2">
            <Group title={t('groups.printer.view')}>
              <ViewIcons />
            </Group>

            <Group title={t('groups.printer.filters')}>
              <div className="flex grow flex-wrap items-center gap-1">
                <Filters />
              </div>
            </Group>

            <Group title={t('groups.printer.sort-by')} className="flex-wrap">
              <Sorting />
              <AddNewPrinterButton />
            </Group>
          </div>
        </Row>
      </FilterRow>
      {view === PrintersViewEnum.QUEUE && <QueueView />}
      {view === PrintersViewEnum.GRID && <GridView />}
      {view === PrintersViewEnum.CARDS && <CardsView />}
      {view !== PrintersViewEnum.QUEUE && view !== PrintersViewEnum.GRID && view !== PrintersViewEnum.CARDS && (
        <ListView />
      )}
    </>
  )
}
