import { useTranslation } from 'react-i18next'

import { printerColorMap } from '../../lib/colorScheme'
import { translateLabel } from '../../lib/printerState'
import { TooltipPosition } from '../primitives/Tooltip'
import { StateLabel } from './StateLabel'

type Props = {
  printerState: string
  tooltip?: string
  collapsible?: boolean
  tooltipPosition?: TooltipPosition
}

export function PrinterStateLabel({ printerState, ...props }: Props) {
  const { t } = useTranslation<string>()

  let infoTooltip
  switch (printerState) {
    case 'ATTENTION':
      infoTooltip = t('printer.attention.tooltip', `Please check the printer's screen for more info.`)
      break
    case 'ERROR':
      infoTooltip = t('printer.error.tooltip', `Please check the printer's screen for more info.`)
      break
    default:
      infoTooltip = props.tooltip
  }

  return (
    <StateLabel
      label={translateLabel(t, printerState)}
      color={printerColorMap[printerState]}
      tooltip={infoTooltip}
      side={props.tooltipPosition}
      collapsible={props.collapsible}
    />
  )
}
