import { PlainButton } from '../../components/primitives/PlainButton'
import { ReactComponent as ArrowsSort } from '../../icons/general/arrows-sort.svg'
import { ReactComponent as ArrowsSortDisabled } from '../../icons/general/arrows-sort-disabled.svg'
import { ReactComponent as NokIcon } from '../../icons/general/nok.svg'

export type SortParam<T extends string> = {
  key: T
  order: 'ASC' | 'DESC'
}

function SortIcon<T extends string>({ sortItem }: { sortItem?: SortParam<T> }) {
  if (!sortItem) {
    return <ArrowsSortDisabled size="16" />
  }

  if (sortItem.order === 'ASC') {
    return <ArrowsSort size="16" />
  }

  return <ArrowsSort size="16" style={{ transform: 'rotateX(180deg)' }} />
}

type Props<T extends string> = {
  sortBy: SortParam<T>[]
  options: readonly { label: string; value: T }[]
  onChange: (value: T) => void
  isResetVisible: boolean
  onReset: () => void
}

export function GenericSorting<T extends string>({ sortBy, options, onChange, isResetVisible, onReset }: Props<T>) {
  return (
    <div className="flex grow flex-wrap items-center gap-4 text-sm">
      {options.map((option) => (
        <PlainButton key={option.value} className="underline hover:text-primary" onClick={() => onChange(option.value)}>
          {option.label}
          <span className="ml-1">
            <SortIcon<T> sortItem={sortBy.find((item) => item.key === option.value)} />
          </span>
        </PlainButton>
      ))}
      {isResetVisible && (
        <PlainButton className="flex items-center" onClick={onReset}>
          <NokIcon /*fill={theme.colors.icons.secondary}*/ size={16} />
        </PlainButton>
      )}
    </div>
  )
}
