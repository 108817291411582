import {
  type components,
  ConnectState,
  IJobCamera,
  PlannedJobState,
  PrinterDetailJobInfo,
  PrintFile,
  ToolMapping
} from '@prusaconnect/api'

import { AfsJob } from './afs'
import { IPager, IPagerQuery, ITimeRangeQuery } from './pager'
import { IJobInfo, IMeshBedLevelingPoint, IPrinterSimpleView, PrinterUuid } from './printer'
import { ISource } from './user'

export type QueueRejected = components['schemas']['queue_rejected']

export type IJob = {
  origin_id?: number
  id?: number
  path?: string
  display_path?: string
  hash?: string
  file?: PrintFile
  state: IJobState
  start?: number
  end?: number
  start_print?: number
  end_print?: number
  source_info?: ISource
  mbl_points: IMeshBedLevelingPoint[]
  cameras?: IJobCamera[]
  display_name: string
  printer_uuid: PrinterUuid
  cancelable?: ICancelable
  rejected?: QueueRejected
  time_printing?: number
}

export enum IJobState {
  PRINTING = 'PRINTING',
  PAUSED = 'PAUSED',
  FIN_STOPPED = 'FIN_STOPPED',
  FIN_ERROR = 'FIN_ERROR',
  FIN_OK = 'FIN_OK',
  UNKNOWN = 'UNKNOWN'
}

export type IJobMoveRequest = {
  planned_job_id: number
  source_printer_id?: PrinterUuid
  position: number
}

export type IJobsQuery = IPagerQuery &
  ITimeRangeQuery & {
    state: IJobState[]
  }

export type IJobsResponse = {
  jobs: IJob[]
  pager: IPager
}

type IJobsStateProperties = {
  state: IJobState
  primaryColor: string
}

export enum IJobStatePrimaryColor {
  UNKNOWN = '#adadad',
  PRINTING = '#7da7d9',
  PAUSED = '#bd8cbf',
  FINISHED = '#92c78c',
  ERROR = '#e77174',
  ATTENTION = '#e7925e'
}

export const states: IJobsStateProperties[] = [
  {
    state: IJobState.PRINTING,
    primaryColor: IJobStatePrimaryColor.PRINTING
  },
  {
    state: IJobState.PAUSED,
    primaryColor: IJobStatePrimaryColor.PAUSED
  },
  {
    state: IJobState.FIN_STOPPED,
    primaryColor: IJobStatePrimaryColor.ATTENTION
  },
  {
    state: IJobState.FIN_ERROR,
    primaryColor: IJobStatePrimaryColor.ERROR
  },
  {
    state: IJobState.FIN_OK,
    primaryColor: IJobStatePrimaryColor.FINISHED
  },
  {
    state: IJobState.UNKNOWN,
    primaryColor: IJobStatePrimaryColor.UNKNOWN
  }
]

export type IPlannedJob = {
  id: number
  created: number
  path?: string
  display_name?: string
  display_path?: string
  hash?: string
  file?: PrintFile
  planned_start?: number
  planned_end?: number
  conditions: ICondition
  state: PlannedJobState
  source?: string
  source_info?: ISource
  rejected?: QueueRejected
  tool_mapping?: ToolMapping
}

export type IPrinterJobs = {
  job_info?: IJobInfo
  planned_jobs: IPlannedJob[]
  pager: IPager
}

export type IJobPeriod = {
  printingPeriod: {
    from: number
    to: number
  }
  fullJobPeriod: {
    from: number
    to: number
  }
}

export type ICondition = {
  file_in_cache: ISatisfied
  printer_ready?: ISatisfied
  wait_until?: ISatisfied & {
    expected: number // timestamp
  }
  material_type?: ISatisfied & {
    expected?: string // filament material
  }
  material_length?: ISatisfied
  nozzle_diameter?: ISatisfied
}

export type IOptionalCondition = {
  name: 'material_type' | 'nozzle_diameter' | 'material_length' | 'wait_until'
  enabled: boolean
}

export type ISatisfied = {
  satisfied?: boolean
  enabled: boolean
}

export type IPrinterDetailJobInfo = PrinterDetailJobInfo

export type IQueue = {
  printer: IPrinterSimpleView
  job_info?: IPrinterDetailJobInfo
  planned_jobs: IPlannedJob[]
}

export type IQueueRequest = {
  group_id?: number
  offset?: number
  limit?: number
  team_id?: number
  state_include?: ConnectState[]
  sort_by?: string
}

export type IQueueResponse = {
  queues: IQueue[]
  pager: IPager
}

type ICancelable = {
  objects: ICancelableObject[]
}

export type ICancelableObject = {
  id: number
  name?: string
  polygon?: [number, number][]
  canceled: boolean
}

/* ------------------------------ Job's end reasons ------------------------------ */

export enum StoppedPrintReasons {
  OTHER = 'OTHER',
  IGNORED = 'IGNORED',
  CLOGGED_NOZZLE = 'CLOGGED_NOZZLE',
  WARPING = 'WARPING',
  NON_ADHERENT_BED = 'NON_ADHERENT_BED',
  UNDER_EXTRUSION = 'UNDER_EXTRUSION',
  OVER_EXTRUSION = 'OVER_EXTRUSION',
  STRINGING_OR_OOZING = 'STRINGING_OR_OOZING',
  GAPS_IN_THIN_WALLS = 'GAPS_IN_THIN_WALLS',
  OVERHEATING = 'OVERHEATING',
  LAYER_SHIFTING = 'LAYER_SHIFTING',
  SPAGHETTI_MONSTER = 'SPAGHETTI_MONSTER',
  LAYER_SEPARATION = 'LAYER_SEPARATION',
  POOR_BRIDGING = 'POOR_BRIDGING'
}

export type IStoppedPrintReasonPayload = {
  reason: {
    tag: StoppedPrintReasons[]
    other?: string
  }
}

type IStoppedPrintReasonResponseBaseData = { key: string; code: string; message: string }

export type IStoppedPrintReasonResponse = {
  code: string
  message: string
  header: IStoppedPrintReasonResponseBaseData[]
  query: IStoppedPrintReasonResponseBaseData[]
  form: IStoppedPrintReasonResponseBaseData[]
}

export type ICreatedAfsJobResponse = {
  afs_jobs: AfsJob[]
}
