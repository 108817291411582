import { getCurrentUnixTimestamp } from '@prusa3d-platform/connect-utils'
import { translateLabel } from '@prusaconnect/ui'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { IPrinterSimpleView } from '../../../api/types/printer'
import { IConnectState } from '../../../api/types/state'
import { formatPercents } from '../../../helpers/formatters'
import { isNumber } from '../../../helpers/std'
import { useInterval } from '../../../helpers/useInterval'
import { ITimetype } from '../../../interfaces/time'
import { UncertaintySign } from '../../helpers/UncertaintySign'
import { getPrinterName } from '../utils'
import * as S from './GridPrinter.styled'

type Props = {
  printer: IPrinterSimpleView
}

enum IRotationPhase {
  PRINT_STARTED,
  PRINTING_TIME,
  REMAINING_TIME,
  ESTIMATED_END
}

export function GridPrinter(props: Props) {
  const { printer } = props
  const [timePrinting, setTimePrinting] = useState(printer.job_info?.time_printing || 0)
  const [timeRemaining, setTimeRemaining] = useState(printer.job_info?.time_remaining || 0)
  const [printStarted, setPrintStarted] = useState(getCurrentUnixTimestamp() - (printer.job_info?.time_printing || 0))
  const [estimatedEnd, setEstimatedEnd] = useState(
    printer.job_info?.time_remaining ? getCurrentUnixTimestamp() + (printer.job_info?.time_remaining || 0) : 0
  )
  const { t } = useTranslation()

  useInterval(() => {
    if (printer.connect_state === IConnectState.PRINTING) {
      setTimePrinting(timePrinting + 1)
      setTimeRemaining(timeRemaining - 1)
    }
  }, 1000)

  useEffect(() => {
    setTimePrinting(printer.job_info?.time_printing || 0)
    setPrintStarted(getCurrentUnixTimestamp() - (printer.job_info?.time_printing || 0))
  }, [printer.job_info?.time_printing])

  useEffect(() => {
    setTimeRemaining(printer.job_info?.time_remaining || 0)
    setEstimatedEnd(
      printer.job_info?.time_remaining ? getCurrentUnixTimestamp() + (printer.job_info?.time_remaining || 0) : 0
    )
  }, [printer.job_info?.time_remaining])

  const [rotationPhase, setRotationPhase] = useState(IRotationPhase.PRINT_STARTED)
  const [timeType, setTimeType] = useState(ITimetype.DATETIME)

  const getRotationValue = () => {
    switch (rotationPhase) {
      case IRotationPhase.PRINT_STARTED:
        return printStarted
      case IRotationPhase.PRINTING_TIME:
        return timePrinting
      case IRotationPhase.REMAINING_TIME:
        return timeRemaining
      case IRotationPhase.ESTIMATED_END:
        return estimatedEnd
    }
  }

  const getRotationPhase = () => {
    switch (rotationPhase) {
      case IRotationPhase.PRINT_STARTED:
        return t('table-column.print-started')
      case IRotationPhase.PRINTING_TIME:
        return t('table-column.printing-time')
      case IRotationPhase.REMAINING_TIME:
        return t('table-column.remaining-time')
      case IRotationPhase.ESTIMATED_END:
        return t('table-column.estimated-end')
      default:
        return ''
    }
  }

  useInterval(() => {
    switch (rotationPhase) {
      case IRotationPhase.PRINT_STARTED:
        setTimeType(ITimetype.DURATION)
        setRotationPhase(IRotationPhase.PRINTING_TIME)
        break
      case IRotationPhase.PRINTING_TIME:
        setTimeType(ITimetype.COUNTDOWN)
        setRotationPhase(IRotationPhase.REMAINING_TIME)
        break
      case IRotationPhase.REMAINING_TIME:
        setTimeType(ITimetype.DATETIME)
        setRotationPhase(IRotationPhase.ESTIMATED_END)
        break
      case IRotationPhase.ESTIMATED_END:
        setTimeType(ITimetype.DATETIME)
        setRotationPhase(IRotationPhase.PRINT_STARTED)
        break
    }
  }, 3000)

  const progress = printer.job_info?.progress

  return (
    <S.Container $state={printer.connect_state} $progress={printer.job_info?.progress} to={`/printer/${printer.uuid}`}>
      <S.Top>
        <S.Name className="printer-name">{getPrinterName(printer)}</S.Name>
      </S.Top>

      <S.Center>
        <S.Status>
          {translateLabel(t, printer.connect_state)}{' '}
          {printer.connect_state === IConnectState.PRINTING && isNumber(progress) && `: ${formatPercents(progress)}`}
        </S.Status>
      </S.Center>

      <S.Bottom>
        {printer.connect_state === IConnectState.PRINTING && printer.job_info?.time_printing && (
          <S.PrintingInfo>
            <S.TimeLabel>{getRotationPhase()}</S.TimeLabel>
            <S.TimeValue unixTimestamp={getRotationValue()} type={timeType} noSeconds />
            <UncertaintySign printerSpeed={printer.speed} />
          </S.PrintingInfo>
        )}
      </S.Bottom>
    </S.Container>
  )
}
