import { Tooltip } from '@prusaconnect/ui'
import { useTranslation } from 'react-i18next'

import { config } from '../config'
import { useInfoService } from '../services/useInfoService'
import { SvgIcon } from './common/SvgIcon'
import * as S from './Footer.styled'

const ICON_SIZE = 24

export function Footer() {
  const { t } = useTranslation()

  return (
    <S.Footer>
      <S.StyledContainer>
        <S.ColLeft>
          <S.Name>PRUSA CONNECT</S.Name>
        </S.ColLeft>
        <S.ColCenter>
          <Tooltip
            content={
              <div className="text-neutral-300 dark:text-neutral-700">
                <Versions />
              </div>
            }
            side="top"
            asChild
          >
            <S.Span className="text-neutral-300 dark:text-neutral-700">
              {new Date().getFullYear()} &copy; Prusa Research a.s.
            </S.Span>
          </Tooltip>
          <S.Delimeter />
          <S.A
            target="_blank"
            href="https://www.prusa3d.com/page/general-terms-and-conditions-of-use-of-the-prusa-websites_231226/"
            className="text-neutral-300 dark:text-neutral-700"
          >
            {t('footer.general-terms', 'General Terms and Conditions of Use of the PRUSA Websites')}
          </S.A>
          <S.A
            target="_blank"
            href="https://www.prusa3d.com/page/prusa-connect-prusa-link-terms-and-conditions_233705/"
            className="text-neutral-300 dark:text-neutral-700"
          >
            {t('footer.terms-connect', 'Prusa Connect & Prusa Link Terms and Conditions')}
          </S.A>
          <S.A
            target="_blank"
            href="https://www.prusa3d.com/page/privacy-policy_231258/"
            className="text-neutral-300 dark:text-neutral-700"
          >
            {t('footer.general-privacy-policy', 'Privacy Policy')}
          </S.A>
          <S.A
            target="_blank"
            href="https://www.prusa3d.com/page/prusa-connect-privacy-policy_233713/"
            className="text-neutral-300 dark:text-neutral-700"
          >
            {t('footer.connect-privacy-policy', 'Prusa Connect Privacy Policy')}
          </S.A>
          <S.A className="text-neutral-300 dark:text-neutral-700 ot-sdk-show-settings">
            {t('footer.cookies-consent')}
          </S.A>
          <S.A href="/minimal-requirements" className="text-neutral-300 dark:text-neutral-700">
            {t('footer.minimal-requirements')}
          </S.A>
          <S.A target="_blank" href="https://status.prusa3d.com/" className="text-neutral-300 dark:text-neutral-700">
            {t('footer.health-status', 'Status Page')}
          </S.A>
        </S.ColCenter>
        <S.ColRight>
          <S.IconLink target="_blank" href="https://www.facebook.com/PrusaResearch/" rel="noreferrer">
            <SvgIcon icon="socialFbIcon" size={ICON_SIZE} />
          </S.IconLink>
          <S.IconLink target="_blank" href="https://twitter.com/prusa3d" rel="noreferrer">
            <SvgIcon icon="socialTwitterIcon" size={ICON_SIZE} />
          </S.IconLink>
          <S.IconLink target="_blank" href="https://www.instagram.com/josefprusa/" rel="noreferrer">
            <SvgIcon icon="socialInstagramIcon" size={ICON_SIZE} />
          </S.IconLink>
        </S.ColRight>
      </S.StyledContainer>
    </S.Footer>
  )
}

function Versions() {
  const data = useInfoService()
  const versions: { label: string; value: string }[] = []
  const releaseVersion = config.appVersion

  if (releaseVersion) {
    versions.push({ label: 'FE v.', value: releaseVersion })
  }

  if (data) {
    const { version } = data
    versions.push({ label: 'BE v.', value: version.string })

    if (data.database_version?.script_count) {
      versions.push({ label: 'DB v.', value: data.database_version.script_count.toString() })
    }
  }

  return (
    <div className="text-center">
      {versions.map((version) => (
        <div key={version.label}>
          <small>{version.label}</small>
          {version.value}
        </div>
      ))}
    </div>
  )
}
