import Color from 'color'
import resolveConfig from 'tailwindcss/resolveConfig'

import tailwindConfig from '../tailwind.config'

const themeColors = resolveConfig(tailwindConfig).theme.colors.state
export type StateColor = keyof typeof themeColors
export { themeColors }

function getTextColor(baseColor: string) {
  const colorObj = Color(baseColor)
  const blackContrast = colorObj.contrast(Color('black'))
  const whiteContrast = colorObj.contrast(Color('white'))

  return blackContrast > whiteContrast ? '#000000' : '#ffffff'
}

type ColorPair = {
  background: string
  text: string
}

type Weight = 'default' | 100 | 300 | 500 | 700 | 900
export type ColorScheme = Record<Weight, ColorPair>

const lightnessModifiers: Record<Weight, [number, number]> = {
  default: [0, 0],
  100: [0.8, 0.4],
  300: [0.7, 0.35],
  500: [0.6, 0.25],
  700: [0.5, 0.15],
  900: [0, 0]
}

export const printerColorMap: Record<string, StateColor> = {
  UNKNOWN: 'graphite',
  OFFLINE: 'gray',
  IDLE: 'yellow',
  READY: 'olive',
  BUSY: 'beige',
  PRINTING: 'blue',
  PAUSED: 'purple',
  STOPPED: 'red',
  FINISHED: 'green',
  ERROR: 'burgundy',
  ATTENTION: 'orange',
  MANIPULATING: 'beige',
  PREPARED: 'olive',
  READY_FOR_PICK_UP: 'green'
}

export const jobColorMap: Record<string, StateColor> = {
  PRINTING: 'blue',
  PAUSED: 'purple',
  FIN_STOPPED: 'red',
  FIN_ERROR: 'burgundy',
  FIN_OK: 'dark-green',
  FIN_HARVESTED: 'green',
  UNKNOWN: 'graphite',
  PLANNED: 'graphite',
  PROCESSED: 'graphite',
  DELETED: 'graphite',
  DONE: 'dark-green',
  READY_FOR_PICK_UP: 'dark-green',
  NO_COMPATIBLE_JOBS_AVAILABLE: 'gray'
}

export const orderColorMap: Record<string, StateColor> = {
  CREATED: 'gray',
  APPROVED: 'gray',
  PROCESSING: 'blue',
  PAUSED: 'purple',
  FIN_STOPPED: 'red',
  FIN_ERROR: 'burgundy',
  COMPLETED: 'dark-green',
  FIN_HARVESTED: 'green',
  UNKNOWN: 'graphite',
  PLANNED: 'graphite',
  PROCESSED: 'graphite',
  DELETED: 'graphite'
}

export function getColorScheme(baseColor: StateColor, inverse = false): ColorScheme {
  const rgb = getComputedStyle(document.body).getPropertyValue(`--state-${baseColor || 'gray'}`)
  const color = Color(`rgb(${rgb})`)

  const hexColorVariants = Object.entries(lightnessModifiers).reduce((acc, key) => {
    const [type, [darken, lighten]] = key
    const regularColor = color.lighten(lighten).desaturate(0.25).hex()
    const inverseColor = color.darken(darken).desaturate(0.75).hex()
    return {
      ...acc,
      [type]: inverse
        ? { background: inverseColor, text: getTextColor(inverseColor) }
        : { background: regularColor, text: getTextColor(regularColor) }
    }
  }, {})

  return {
    ...hexColorVariants
  } as ColorScheme
}

export function getPrinterColorScheme(printerState: string, isDarkMode: boolean): ColorScheme {
  return getColorScheme(printerColorMap[printerState], isDarkMode)
}

export function getJobColorScheme(jobState: string, isDarkMode: boolean): ColorScheme {
  return getColorScheme(jobColorMap[jobState], isDarkMode)
}

export function getOrderColorScheme(orderState: string, isDarkMode: boolean): ColorScheme {
  return getColorScheme(orderColorMap[orderState], isDarkMode)
}
