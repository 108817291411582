import * as Sentry from '@sentry/react'
import React from 'react'
import { createRoutesFromChildren, matchRoutes, useLocation, useNavigationType } from 'react-router'

import { config, Environment } from '../config'

export function initializeSentry() {
  const dynamicSampleRate = config.environment === Environment.PRODUCTION ? 0.01 : 1.0

  Sentry.init({
    dsn: config.sentry_dsn,
    enabled: [Environment.PRODUCTION, Environment.TEST, Environment.DEV].includes(config.environment),
    environment: config.environment,
    release: `${config.appName.replace('@prusaconnect/', '')}@${config.appVersion}`,
    allowUrls: [/^https:\/\/(test.)?connect.prusa3d.com/, 'https://dev.connect.prusa3d.com/'],
    integrations: [
      new Sentry.Replay({
        maskAllText: true,
        blockAllMedia: true
      }),
      new Sentry.BrowserTracing({
        routingInstrumentation: Sentry.reactRouterV6Instrumentation(
          React.useEffect,
          useLocation,
          useNavigationType,
          createRoutesFromChildren,
          matchRoutes
        )
      })
    ],

    sampleRate: 0.01,
    enableTracing: true,
    tracesSampleRate: dynamicSampleRate,
    replaysSessionSampleRate: dynamicSampleRate,
    replaysOnErrorSampleRate: 0.1
  })
}
