import containerQueries from '@tailwindcss/container-queries'
import { Config } from 'tailwindcss'

export default {
  content: ['components/**/*.tsx'],
  theme: {
    colors: {
      primary: {
        DEFAULT: 'rgb(var(--ui-color-primary) / <alpha-value>)',
        faded: 'rgb(var(--ui-color-primary-faded) / <alpha-value>)',
        contrasting: 'rgb(var(--ui-color-primary-contrasting) / <alpha-value>)'
      },
      neutral: {
        100: 'rgb(var(--ui-neutral-100) / <alpha-value>)',
        150: 'rgb(var(--ui-neutral-150) / <alpha-value>)',
        200: 'rgb(var(--ui-neutral-200) / <alpha-value>)',
        300: 'rgb(var(--ui-neutral-300) / <alpha-value>)',
        400: 'rgb(var(--ui-neutral-400) / <alpha-value>)',
        500: 'rgb(var(--ui-neutral-500) / <alpha-value>)',
        600: 'rgb(var(--ui-neutral-600) / <alpha-value>)',
        700: 'rgb(var(--ui-neutral-700) / <alpha-value>)',
        800: 'rgb(var(--ui-neutral-800) / <alpha-value>)',
        900: 'rgb(var(--ui-neutral-900) / <alpha-value>)'
      },
      state: {
        graphite: 'rgb(var(--state-graphite) / <alpha-value>)',
        gray: 'rgb(var(--state-gray) / <alpha-value>)',
        yellow: 'rgb(var(--state-yellow) / <alpha-value>)',
        olive: 'rgb(var(--state-olive) / <alpha-value>)',
        beige: 'rgb(var(--state-beige) / <alpha-value>)',
        blue: 'rgb(var(--state-blue) / <alpha-value>)',
        purple: 'rgb(var(--state-purple) / <alpha-value>)',
        red: 'rgb(var(--state-red) / <alpha-value>)',
        green: 'rgb(var(--state-green) / <alpha-value>)',
        'dark-green': 'rgb(var(--state-dark-green) / <alpha-value>)',
        burgundy: 'rgb(var(--state-burgundy) / <alpha-value>)',
        orange: 'rgb(var(--state-orange) / <alpha-value>)',
        lime: 'rgb(var(--state-lime) / <alpha-value>)',
        aqua: 'rgb(var(--state-aqua) / <alpha-value>)'
      },
      temp: {
        hot: 'rgb(var(--temp-hot) / <alpha-value>)',
        cold: 'rgb(var(--temp-cold) / <alpha-value>)'
      },
      error: {
        bg: 'rgb(var(--error-bg) / <alpha-value>)',
        'bg-soft': 'rgb(var(--error-bg-soft) / <alpha-value>)',
        border: 'rgb(var(--error-border) / <alpha-value>)',
        text: 'rgb(var(--error-text) / <alpha-value>)'
      }
    },
    extend: {
      keyframes: {
        'warm-up': {
          '0%, 100%': { color: 'rgb(var(--temp-hot))' },
          '75%': { color: 'inherit' }
        },
        'cool-down': {
          '0%, 100%': { color: 'rgb(var(--temp-cold))' },
          '75%': { color: 'inherit' }
        },
        'button-pulse': {
          '0%': {
            opacity: '0.75',
            'background-color': 'rgb(var(--ui-neutral-500))'
          },
          '50%': {
            opacity: '0.25',
            'background-color': 'rgb(var(--ui-color-primary))'
          },
          '100%': {
            opacity: '0.75',
            'background-color': 'rgb(var(--ui-neutral-500))'
          }
        }
      },
      animation: {
        'warm-up': 'warm-up 1.5s ease-in-out infinite',
        'cool-down': 'cool-down 1.5s ease-in-out infinite',
        'button-pulse': 'button-pulse 2s linear infinite'
      }
    }
  },
  darkMode: ['class', '[data-theme="DARK"]'],
  plugins: [containerQueries]
} satisfies Config
