import { FirmwareFile, IFile, PrintFile, TreeNodeBase } from '@prusaconnect/api'

import { PrinterUuid } from './printer'

export type IUploadedFileResponse = {
  hash: string
  name: string
  size: number
  type: IFileType
}

export enum IFileType {
  FOLDER = 'FOLDER',
  FILE = 'FILE',
  PRINT_FILE = 'PRINT_FILE',
  FIRMWARE = 'FIRMWARE',
  EMPTY = ''
}

export type ITreeChild = ITreeFolder | IFile

export type ITreeFolder = TreeNodeBase & {
  type: IFileType.FOLDER
  file_count: number
  hidden?: boolean
  id: number
  m_timestamp?: number
  tree_synced?: boolean
  folder_synced?: boolean
  // typecheck
  meta?: any
  size?: number
  uploaded?: number
}

export type IUploadQueueFile = {
  path?: string
  url?: string
  hash?: string
  team_id: number
  to_print?: boolean
  to_queue?: boolean
  // Schedule jobs
  wait_until?: number
}

export type IUploadQueueFirmwareFile =
  | (Partial<IUploadQueueFile> & {
      path: string
      url: string
    })
  | (Partial<IUploadQueueFile> & {
      path: string
      team_id: number
      hash: string
    })

export type ITransferMoveRequest = {
  file_id: number
  position: number
}

export type IDeleteFilesRequestBody = {
  delete_all?: boolean
  hashes: string[]
}

export type IEditFileAttrs = {
  name: string
  hash?: string
  teamId?: number
}

export type Fws = {
  [key: PrinterUuid]: FirmwareFile | null
}

export function isPrintFile(file?: IFile): file is PrintFile {
  return file?.type === IFileType.PRINT_FILE
}
