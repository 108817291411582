import { Button, Dialog, DialogButtons, DialogContent } from '@prusaconnect/ui'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import { useUpdateProfile } from '../api/services/useAuth'
import { useAuthState } from '../context/authStore'
import { useUserResolver } from '../Contexts'
import { useLogoutService } from '../services/useLogoutService'
import { Markdown } from './common/Markdown'

const Body = styled.div`
  font-size: 0.875rem;

  a {
    text-decoration: underline;
  }
`

export const TermsAndConditionsDialog = () => {
  const { t } = useTranslation()
  const { user, isAuthenticated } = useAuthState()
  const { logoutUser } = useLogoutService()
  const { refetchUserData } = useUserResolver()
  const [showDialog, setShowDialog] = useState(false)
  const { update: updateProfile } = useUpdateProfile(refetchUserData)

  useEffect(() => {
    if (typeof user.conditions_signed === 'boolean' && !user.conditions_signed && isAuthenticated) {
      setShowDialog(true)
    }
  }, [user.conditions_signed, isAuthenticated])

  return (
    <Dialog open={showDialog} onOpenChange={setShowDialog}>
      <DialogContent title={t('terms-and-conditions-privacy-policy', 'Terms and Conditions and Privacy Policy')}>
        <Body>
          <p>
            <Markdown>
              {t(
                'terms-and-conditions-privacy-policy.intro',
                'Before you continue using our services, we kindly ask you to review and agree to our [Terms and Conditions](https://www.prusa3d.com/page/prusa-connect-prusa-link-terms-and-conditions_233705/) and [Privacy Policy](https://www.prusa3d.com/page/prusa-connect-privacy-policy_233713).'
              )}
            </Markdown>
          </p>
          <p>
            {t(
              'terms-and-conditions-privacy-policy.main',
              'By proceeding with the use of our platform, you acknowledge that you have read, understood, and accepted the terms outlined in our Terms and Conditions, which govern the use of our website and services.'
            )}
          </p>
          <p>
            {t(
              'terms-and-conditions-privacy-policy.final',
              'Your trust is essential to us, and we assure you that your information will be handled securely and in accordance with applicable laws.'
            )}
          </p>
        </Body>

        <DialogButtons>
          <Button
            onClick={() => {
              setShowDialog(false)
              logoutUser()
            }}
          >
            {t('terms-and-conditions-privacy-policy.button.decline', 'Decline')}
          </Button>
          <Button
            onClick={() => {
              updateProfile({ conditions_signed: true })
              setShowDialog(false)
            }}
          >
            {t('terms-and-conditions-privacy-policy.button.confirm', 'I Agree')}
          </Button>
        </DialogButtons>
      </DialogContent>
    </Dialog>
  )
}
