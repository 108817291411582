export enum PrinterType {
  MK25 = '1.2.5',
  MK25S = '1.2.6',
  MK3 = '1.3.0',
  MK3S = '1.3.1',
  MK35 = '1.3.5',
  MK35S = '1.3.6',
  MK39 = '1.3.9',
  MK39S = '1.3.10',
  MK4 = '1.4.0',
  MK4S = '1.4.1',
  MINI = '2.1.0',
  XL = '3.1.0',
  IX = '4.1.0',
  SL1 = '5.1.0',
  SL1S = '5.1.1',
  M1 = '5.2.0',
  HT90 = '6.1.0',
  DELTIQ_2 = '6.2.0',
  DELTIQ_2_PLUS = '6.2.1',
  CORE_ONE = '7.1.0',
  NEXT = '8.1.0'
}
