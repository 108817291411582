import styled, { css } from 'styled-components'

import { textEllipsis } from '../helpers/styled'

const titleStyle = css`
  flex: 1;
  min-width: 0;
  ${textEllipsis};
  font-size: 1.125rem;
  font-weight: 700;
  color: var(--color-text);
  margin-top: 5px;
`

export const Title = styled.div`
  ${titleStyle}
`
