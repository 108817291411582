import { useQuery, UseQueryOptions } from '@tanstack/react-query'

import { useApiClient } from '../client/apiClientContext'
import { REFETCH_PRINT_QUEUE, REFETCH_PRINTER_DETAIL, REFETCH_PRINTER_LIST } from '../lib/fetchIntervals'
import { queryKey } from '../lib/queryKeys'
import type { components, paths } from '../schema/schema'

export type Printer = components['schemas']['printer_detail_view']
export type FdmPrinter = components['schemas']['fdm_printer_detail']
export type SlaPrinter = components['schemas']['sla_printer_detail']
export type PrinterSimpleView = components['schemas']['printer_simple_view']
export type PrinterState = components['schemas']['connect_state']
export type Material = components['schemas']['material_enum']
export type ToolMapping = components['schemas']['tool_mapping']
export type ConnectState = components['schemas']['connect_state']
export type PrinterModel = components['schemas']['printer_model']
export type MmuPrinterTelemetry = components['schemas']['mmu_telemetry']
export type PrinterTools = components['schemas']['tools_detail']
export type PrinterDetailJobInfo = components['schemas']['printer_detail_job_info']

export type PrinterToolsItem = components['schemas']['tool_multitool'] & { toolId: number }
export type PrinterSlotItem = components['schemas']['slot_detail'] & { slotId: string }
export function printerTools(tools: Readonly<PrinterTools>) {
  return {
    isMultitool() {
      return '2' in tools
    },
    tools(): PrinterToolsItem[] {
      return (
        Object.entries(tools)
          // Filter out MMU slots
          .filter(([toolId]) => !toolId.includes('.'))
          .map(([toolId, data]) => ({ toolId: parseInt(toolId, 10), ...data }))
      )
    },
    isMultiMaterial(): boolean {
      const firstTool = tools['1']
      return !!(firstTool && 'mmu' in firstTool && firstTool.mmu?.enabled)
    },
    mmu():
      | (components['schemas']['tool_mmu'] & {
          slots: { active?: boolean; material: string; slotId: number }[]
          activeSlot: number
        })
      | undefined {
      if (!this.isMultiMaterial()) return undefined

      const firstTool = tools['1']
      const slots = Object.entries(tools)
        // Filter out tools
        .filter(([slotId]) => slotId.includes('.'))
        .map(([slotId, data]) => ({
          // Slot Id is '1.2', parse just second number
          slotId: parseInt(slotId.split('.')[1], 10),
          ...(data as { active?: boolean; material: string })
        }))
      return { ...firstTool, slots, activeSlot: slots.find((slot) => slot.active)?.slotId ?? 0 }
    },
    /** @deprecated this returns only first nozzle diameter, all nozzle diameters are in `tools().map(t => t?.nozzle_diameter)` */
    nozzleDiameter: () => tools['1']?.nozzle_diameter,
    allDataPresent() {
      const tools = this.tools()
      return (
        tools.length > 0 &&
        tools.every(
          (tool) => tool.nozzle_diameter !== undefined && tool.high_flow !== undefined && tool.hardened !== undefined
        )
      )
    }
  }
}

export type PrintersFilter = {
  /** This filter will be first in list if match filters */
  printerUuid?: string
  printerModel?: PrinterModel
  material?: Material[]
  nozzleDiameter?: number[]
  nozzleHardened?: boolean[]
  nozzleHighFlow?: boolean[]
}

export function isFdmPrinter(printer: Printer | PrinterSimpleView): printer is FdmPrinter {
  return 'printer_type' in printer && /^[123467]/i.test(printer.printer_type)
}

export function isSlaPrinter(printer: Printer | PrinterSimpleView): printer is SlaPrinter {
  return 'printer_type' in printer && /^5/i.test(printer.printer_type)
}

export function printerModelIsSla(printerModel: string): boolean {
  return printerModel.startsWith('SL') || printerModel.startsWith('M1')
}

export function isPrinting(printer?: Printer): boolean {
  if (printer && 'connect_state' in printer) {
    return ['PRINTING', 'PAUSED', 'ATTENTION'].includes(printer.connect_state)
  }
  return false
}

export function isPrinterCompatible(
  {
    printer_model,
    supported_printer_models
  }: { printer_model?: PrinterModel; supported_printer_models?: PrinterModel[] },
  targetPrinterModel: PrinterModel
): boolean {
  if (!printer_model || !targetPrinterModel) return false

  return (printer_model === targetPrinterModel || supported_printer_models?.includes(targetPrinterModel)) ?? false
}

export type PrinterListParams = NonNullable<paths['/app/printers']['get']['parameters']['query']>

export function usePrinters(params: PrinterListParams) {
  const { rawApi } = useApiClient()
  const { data, isLoading, isFetched } = useQuery({
    queryKey: queryKey(['printers', params]),
    refetchInterval: REFETCH_PRINTER_LIST,
    placeholderData: (previousData) => previousData,
    queryFn: () =>
      rawApi
        .GET('/app/printers', {
          params: {
            query: params
          }
        })
        .then((res) => res.data)
  })

  const printerSimpleView = (data?.printers as PrinterSimpleView[]) || []
  const printers: PrinterSimpleView[] = printerSimpleView.map((printer) => {
    return {
      ...printer,
      name: printer.name || printer.printer_type_name,
      state: printer.connect_state as PrinterState
    }
  })

  return { printers, isLoading, isFetched }
}

// Using this hacked up type for options is pretty ugly to be honest.
export function usePrinter(uuid: string, options?: Omit<UseQueryOptions, 'queryKey' | 'queryFn'>) {
  const { rawApi } = useApiClient()
  return useQuery({
    queryKey: queryKey(['printer', uuid]),
    refetchInterval: REFETCH_PRINTER_DETAIL,
    queryFn: () =>
      rawApi
        .GET(`/app/printers/{printer_uuid}`, {
          params: { path: { printer_uuid: uuid } }
        })
        .then((res) => res.data),
    ...options
  })
}

export function usePrinterTypes() {
  const { rawApi } = useApiClient()
  const { data } = useQuery({
    queryKey: queryKey(['printer-types']),
    queryFn: () => rawApi.GET('/app/printer-types').then((res) => res.data)
  })

  return { printerTypes: data?.printer_types || [] }
}

export function usePrintQueue(uuid: string) {
  const { rawApi } = useApiClient()
  const { data } = useQuery({
    queryKey: queryKey(['printer', uuid, 'queue']),
    refetchInterval: REFETCH_PRINT_QUEUE,
    queryFn: () =>
      rawApi
        .GET(`/app/printers/{printer_uuid}/queue`, {
          params: { path: { printer_uuid: uuid } }
        })
        .then((res) => res.data)
  })

  const queuedJobs = data?.planned_jobs || []

  return { queuedJobs }
}

/** @todo Do we realy need count of jobs without jobs itself? */
export function useQueuedJobsCount(uuid: string) {
  const { rawApi } = useApiClient()
  const { data } = useQuery({
    queryKey: queryKey(['printer', uuid, 'queue', { limit: 0 }]),
    refetchInterval: REFETCH_PRINT_QUEUE,
    queryFn: () =>
      rawApi
        .GET(`/app/printers/{printer_uuid}/queue`, {
          params: { path: { printer_uuid: uuid } }
        })
        .then((res) => res.data)
  })

  const jobCount = data?.pager.total || 0

  return { jobCount }
}
