import { useCallback, useEffect } from 'react'

import { useAuthState } from '../context/authStore'
import { getEnvironment, Globals } from './getEnvironment'

export function useSleekplan() {
  const { user } = useAuthState()
  const sleek = getEnvironment(Globals.$sleek)

  const setSleekUser = useCallback(() => {
    sleek.setUser({
      mail: user?.email,
      id: user?.id,
      name: user?.public_name
    })
  }, [sleek, user?.email, user?.id, user?.public_name])

  useEffect(() => {
    // Sleekplan is not loaded yet
    if (!sleek || Array.isArray(sleek)) {
      window.addEventListener('sleek:init', () => {
        setSleekUser()
      })
    } else {
      setSleekUser()
    }
  }, [sleek, setSleekUser])
}
