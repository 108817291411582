import dayjs from 'dayjs'
import duration from 'dayjs/plugin/duration'
import { useTranslation } from 'react-i18next'

import { THINSP } from '../../../lib/textSymbols'

dayjs.extend(duration)

type Props = {
  /**
   * Duration in seconds
   */
  duration: number
  displaySeconds?: boolean
}

export function Duration({ duration, displaySeconds = false }: Props) {
  const { t } = useTranslation()

  const durationInstance = dayjs.duration(duration, 'seconds')
  const { days, hours, minutes, seconds } = {
    days: durationInstance.days(),
    hours: durationInstance.hours(),
    minutes: durationInstance.minutes(),
    seconds: durationInstance.seconds()
  }

  let output: string

  if (duration < 60) {
    output = `< 1${THINSP}${t('time.less-than-1-minute', 'Less than 1 minute')}`
  } else {
    const timeParts: string[] = []

    if (days) {
      timeParts.push(`${days}${THINSP}${t('time.day-short', 'd')}`)
    }

    if (hours || (days && minutes)) {
      timeParts.push(`${hours}${THINSP}${t('time.hour-short', 'h')}`)
    }

    if (minutes || (hours && displaySeconds && seconds)) {
      timeParts.push(`${minutes}${THINSP}${t('time.minute-short', 'm')}`)
    }

    if (displaySeconds && seconds) {
      timeParts.push(`${seconds}${THINSP}${t('time.second-short', 's')}`)
    }

    output = timeParts.join(' ')
  }

  return <span>{output}</span>
}
