import { THINSP } from '../../../lib/textSymbols'

export function Percentage({ value }: { value: number }) {
  let formatted = value.toFixed(0)

  const isNan = Number.isNaN(Number(formatted))
  formatted = isNan ? '100' : formatted

  const isInfinite = !Number.isFinite(Number(formatted))
  formatted = isInfinite ? '100' : formatted

  return (
    <span>
      {formatted}
      {THINSP}%
    </span>
  )
}
