import * as Sentry from '@sentry/browser'
import { type LoaderFunction, redirect } from 'react-router'
import { shared } from 'use-broadcast-ts'
import { create } from 'zustand'

import { TWO_FACTOR_AUTH_LS } from '../components/login/TwoFactorAuthDialog'
import { getEnvironment, Globals } from '../helpers/getEnvironment'
import { getAndClearRedirectUrl } from '../hooks/useRedirectUrl'

function generateRandomString(length: number): string {
  let text = ''
  const possible = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'

  for (let i = 0; i < length; i++) {
    text += possible.charAt(Math.floor(Math.random() * possible.length))
  }

  return text
}

async function generateCodeChallenge(codeVerifier: string): Promise<string> {
  const digest = await crypto.subtle.digest('SHA-256', new TextEncoder().encode(codeVerifier))

  return btoa(String.fromCharCode(...new Uint8Array(digest)))
    .replace(/=/g, '')
    .replace(/\+/g, '-')
    .replace(/\//g, '_')
}

function parseJwt(token: string) {
  const base64Url = token.split('.')[1]
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/')
  const jsonPayload = decodeURIComponent(
    window
      .atob(base64)
      .split('')
      .map((c) => `%${`00${c.charCodeAt(0).toString(16)}`.slice(-2)}`)
      .join('')
  )

  try {
    return JSON.parse(jsonPayload)
  } catch (_e) {
    // Ignore, implicit return undefined
  }
}

const REDIRECT_URI = `${getEnvironment(Globals.VITE_HOMEPAGE_URL)}/login/auth-callback`

// Timeout for the token refresh, not shared between tabs
let timeout: NodeJS.Timeout | undefined

export const useJwtStore = create<{
  // Public functions
  getAccessToken: () => string | null
  setTokens: (access: string, refresh?: string) => void
  initiateLogin: () => Promise<void>
  completeLogin: (code: string) => Promise<void>
  logout: () => void

  // Internal state
  __accessToken: string | null
  __refreshToken: string | null

  // Internal functions
  __getAccessTokenExpiration: () => number | undefined
  __clearUserData: () => void
  __setupRefreshTimeout: () => void
  __refreshTokens: () => Promise<void>
  __registerUser: (accessToken: string) => Promise<void>
}>(
  shared((set, get) => ({
    // Public part of the store
    getAccessToken: () => get().__accessToken,
    setTokens: (access: string, refresh?: string) => {
      localStorage.setItem('auth.access_token', access)
      if (refresh) localStorage.setItem('auth.refresh_token', refresh)
      document.cookie = `auth.access_token=${access}; path=/;`
      set({ __accessToken: access, __refreshToken: refresh ?? null })
    },
    // First step of the login process, create a code verifier and challenge, then redirect to the authorize endpoint
    initiateLogin: async () => {
      const codeVerifier = generateRandomString(128)
      window.sessionStorage.setItem('code_verifier', codeVerifier)

      const codeChallenge = await generateCodeChallenge(codeVerifier)

      const args = new URLSearchParams({
        response_type: 'code',
        client_id: getEnvironment(Globals.VITE_ACCOUNT_CLIENT_ID),
        code_challenge_method: 'S256',
        code_challenge: codeChallenge,
        redirect_uri: REDIRECT_URI
      })

      window.location.href = `${getEnvironment(Globals.VITE_ACCOUNT_URL)}/o/authorize/?${args}`
    },
    // Second step of the login process, exchange the code for an access token and refresh token
    completeLogin: async (code: string) => {
      const response = await fetch(`${getEnvironment(Globals.VITE_ACCOUNT_URL)}/o/token/`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        },
        body: new URLSearchParams({
          grant_type: 'authorization_code',
          code,
          client_id: getEnvironment(Globals.VITE_ACCOUNT_CLIENT_ID),
          redirect_uri: REDIRECT_URI,
          code_verifier: window.sessionStorage.getItem('code_verifier') as string
        })
      })
      const data = await response.json()

      if (response.ok) {
        window.sessionStorage.removeItem('code_verifier')

        const state = get()
        await state.__registerUser(data.access_token)
        state.setTokens(data.access_token, data.refresh_token)
      } else {
        Sentry.captureException(new Error('Error during completeLogin'), { extra: data })
      }
    },
    // Logout by clearing all tokens and redirecting to the account logout page
    logout: () => {
      get().__clearUserData()
      window.location.href = `${getEnvironment(Globals.VITE_ACCOUNT_URL)}/logout/?next=${encodeURIComponent(
        getEnvironment(Globals.VITE_HOMEPAGE_URL)
      )}`
    },

    // Internal part of the store
    __accessToken: localStorage.getItem('auth.access_token'),
    __refreshToken: localStorage.getItem('auth.refresh_token'),
    __getAccessTokenExpiration: () => {
      // Parse JWT and check expiration
      const token = get().__accessToken
      if (!token) return
      const data = parseJwt(token)
      if (typeof data.exp === 'number') {
        return data.exp * 1000 // Convert to milliseconds
      }
    },
    __clearUserData: () => {
      window.sessionStorage.clear()

      const twoFactorAuth = localStorage.getItem(TWO_FACTOR_AUTH_LS)
      window.localStorage.clear()
      if (twoFactorAuth !== null) {
        localStorage.setItem(TWO_FACTOR_AUTH_LS, twoFactorAuth)
      }

      document.cookie = 'auth.access_token=; path=/;'
    },
    __setupRefreshTimeout: () => {
      const exp = get().__getAccessTokenExpiration()
      const refreshToken = get().__refreshToken
      if (!exp || !refreshToken) return

      if (timeout) clearTimeout(timeout)
      const delay = exp - Date.now() - 60_000 // Refresh 1 minute before expiration
      timeout = setTimeout(get().__refreshTokens, delay)
      console.debug('Setting up refresh timeout in ', delay / 1000 / 60, 'min')
    },

    // Use the refresh token to get a new token pair
    __refreshTokens: async () => {
      const refreshToken = get().__refreshToken
      if (!refreshToken) {
        return
      }

      const refresh = async () => {
        const response = await fetch(`${getEnvironment(Globals.VITE_ACCOUNT_URL)}/o/token/`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
          },
          body: new URLSearchParams({
            grant_type: 'refresh_token',
            refresh_token: refreshToken as string,
            client_id: getEnvironment(Globals.VITE_ACCOUNT_CLIENT_ID)
          })
        })
        const data = await response.json()

        if (response.ok) {
          const state = get()
          state.setTokens(data.access_token, data.refresh_token)
        } else {
          Sentry.captureException(new Error('Error during refreshTokens'), { extra: data })
          get().__clearUserData()
          window.location.href = '/' // redirect to root, force user to login again
        }
      }

      // Use the lock API to prevent multiple tabs from refreshing the token at the same time
      try {
        if (navigator.locks) {
          await navigator.locks.request('refresh-tokens', {}, async () => {
            // Check if the token was already refreshed by another tab
            if (refreshToken === get().__refreshToken) await refresh()
          })
        } else {
          await refresh()
        }
      } catch (e) {
        Sentry.captureException(e)
      }
    },
    __registerUser: async (accessToken: string) => {
      await fetch(`${window.location.origin}/app/register`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${accessToken}`
        }
      })
      // I have no idea what to do, if request fails
    }
  }))
)

// Setup the refresh timeout when the store is created
useJwtStore.getState().__setupRefreshTimeout()

// Setup refresh timeout when the token is changed, this is needed when the token is changed by another tab
useJwtStore.subscribe((state) => state.__setupRefreshTimeout())

/**
 * Complete JWT login with code
 * functions is called in route /login/auth-callback
 */
export const OAuthJWTCallback: LoaderFunction = async ({ request }) => {
  const url = new URL(request.url)
  const code = url.searchParams.get('code')
  if (code) {
    await useJwtStore.getState().completeLogin(code)
  } // TODO: what to do in fail case?

  const redirectUrl = getAndClearRedirectUrl()
  return redirect(redirectUrl || '/')
}

/**
 * Refresh JWT tokens, before app will do anything
 */
export const RefreshJwtLoader: LoaderFunction = async () => {
  const state = useJwtStore.getState()
  const exp = state.__getAccessTokenExpiration()
  if (!exp) return {}

  if (exp < Date.now()) {
    console.debug('Refreshing tokens')
    await state.__refreshTokens()
  }
  return {}
}
