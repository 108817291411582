import { useMutation, useQuery } from '@tanstack/react-query'

import { useApiClient } from '../client/apiClientContext'
import { REFETCH_USER_INVITATIONS } from '../lib/fetchIntervals'
import type { components, paths } from '../schema/schema'

type UserQuery = paths['/app/users']['get']['parameters']['query']
export type MutateUserData = paths['/app/users']['post']['requestBody']['content']['application/json']
export type ICreatedUser = paths['/app/users']['post']['responses']['201']['content']['application/json']
export type IUser = paths['/app/users/{user_id}']['get']['responses']['200']['content']['application/json']
export type IUserInvitation = components['schemas']['invitation_invitee']

export function useUsers(query: UserQuery) {
  const { rawApi } = useApiClient()
  return useQuery({
    queryKey: ['users', query],
    queryFn: () => rawApi.GET('/app/users', { params: { query } }).then((response) => response.data),
    placeholderData: (previousData: any) => previousData
  })
}

export function useUser(id: number) {
  const { rawApi } = useApiClient()
  const query = useQuery({
    queryKey: ['user', id],
    queryFn: () =>
      rawApi.GET('/app/users/{user_id}', { params: { path: { user_id: id } } }).then((response) => response.data)
  })
  return query.data
}

type CallbackFn = () => void

export function useDeleteUser(onSuccess: CallbackFn) {
  const { rawApi } = useApiClient()
  return useMutation({
    mutationFn: (params: { userId: number }) =>
      rawApi.DELETE('/app/users/{user_id}', { params: { path: { user_id: params.userId } } }),
    onSuccess
  })
}

type MutateParams = {
  formData: MutateUserData
  userId?: number
}

export function useMutateUser(onSuccess: (response: IUser | ICreatedUser) => void) {
  const { rawApi } = useApiClient()
  const mutateUser = useMutation({
    mutationFn: (mutateParams: MutateParams) => {
      const formData = mutateParams.formData as MutateUserData
      if (mutateParams.userId) {
        return rawApi
          .PATCH('/app/users/{user_id}', {
            params: { path: { user_id: mutateParams.userId } },
            body: mutateParams.formData
          })
          .then((data) => data?.data as IUser)
      }

      return rawApi.POST('/app/users', { body: formData }).then((response) => response?.data as ICreatedUser)
    },
    onSuccess
  })

  return {
    mutateUser: mutateUser.mutate
  }
}

export function useInvitations(isEnabled = true) {
  const { $api } = useApiClient()
  return $api.useQuery(
    'get',
    '/app/invitations',
    {},
    {
      refetchInterval: REFETCH_USER_INVITATIONS,
      enabled: isEnabled
    }
  )
}
