import { ReactNode } from 'react'
import styled from 'styled-components'

import { Dropdown } from '../Dropdown'
import { SvgIcon } from '../SvgIcon'
import { AdaptiveButtonContext } from './AdaptiveButtonContext'
import { Mode, useRenderMode } from './useRenderMode'

const Buttons = styled.div<{ show: boolean }>`
  display: flex;
  gap: 1rem;
  ${(props) => !props.show && 'visibility: hidden;'};
`

type Props = {
  show?: boolean
  children: ReactNode
}

export function SimpleActionsRenderer({ children, show = true }: Props) {
  const mode = useRenderMode()

  // @ts-ignore
  const isTouchDevice = 'ontouchstart' in window || navigator.maxTouchPoints > 0 || navigator.msMaxTouchPoints > 0

  if (mode === Mode.DROPDOWN || isTouchDevice) {
    return (
      <AdaptiveButtonContext value="dropdown">
        <Dropdown
          trigger={<SvgIcon icon="dotsIcon" size={20} className="fill-neutral-900 hover:fill-primary" />}
          placement="bottom-end"
          // dropdown in inside <a> tag
          onMenuClick={(e) => e.preventDefault()}
        >
          {children}
        </Dropdown>
      </AdaptiveButtonContext>
    )
  }

  return (
    <AdaptiveButtonContext value="simple">
      <Buttons show={show}>{children}</Buttons>
    </AdaptiveButtonContext>
  )
}
