import type { TFunction } from 'i18next'

export function translateLabel(t: TFunction, label: string) {
  switch (label) {
    case 'MANIPULATING':
      return t('printer.state.manipulated', 'Manipulating')
    case 'UNKNOWN':
      return t('printer.state.unknown', 'Unknown')
    case 'OFFLINE':
      return t('printer.state.offline', 'Offline')
    case 'IDLE':
      return t('printer.state.idle', 'Idle')
    case 'READY':
      return t('printer.state.ready', 'Ready')
    case 'BUSY':
      return t('printer.state.busy', 'Busy')
    case 'PRINTING':
      return t('printer.state.printing', 'Printing')
    case 'PAUSED':
      return t('printer.state.paused', 'Paused')
    case 'STOPPED':
      return t('printer.state.stopped', 'Stopped')
    case 'FINISHED':
      return t('printer.state.finished', 'Finished')
    case 'ERROR':
      return t('printer.state.error', 'Error')
    case 'ATTENTION':
      return t('printer.state.attention', 'Attention')
    default:
      return t('printer.state.unknown', 'Unknown')
  }
}
