import { Dialog, DialogButtons, DialogContent, PlainButton } from '@prusaconnect/ui'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocalStorage } from 'react-use'

import { useAuthState } from '../../context/authStore'
import { ButtonAnchor } from '../common/ButtonLink'
import { Markdown } from '../common/Markdown'

export const TWO_FACTOR_AUTH_LS = '2FA_MODAL_SHOWN'

export const TwoFactorAuthDialog = () => {
  const { t } = useTranslation()
  const [showDialog, setShowDialog] = useState(false)
  const [dialogShown, setDialogShown] = useLocalStorage<boolean>(TWO_FACTOR_AUTH_LS, false)
  const { user, isAuthenticated } = useAuthState()

  useEffect(() => {
    try {
      const termsAndConditionsSigned = user.conditions_signed
      const cookieDialogClosed = document.cookie.includes('OptanonAlertBoxClosed=')
      if (
        termsAndConditionsSigned &&
        user.otp_enabled === false &&
        !dialogShown &&
        isAuthenticated &&
        cookieDialogClosed
      ) {
        setShowDialog(true)
      }
    } catch (_error) {
      console.warn(`Invalid value for ${TWO_FACTOR_AUTH_LS} property in LocalStorage`)
    }
  }, [isAuthenticated, user])

  const dismissDialog = () => {
    setDialogShown(true)
    setShowDialog(false)
  }

  return (
    <Dialog
      open={showDialog}
      onOpenChange={(open) => {
        if (!open) {
          dismissDialog()
        }
      }}
    >
      <DialogContent title={t('2fa.title', '2-Step Verification recommended')}>
        <p>{t('2fa.intro', 'We strongly suggest to use 2-step verification as an extra layer of protection.')}</p>
        <p>
          {t(
            '2fa.main',
            '2-Step Verification offers significant advantages in online security and reduces the risk of unauthorized access even if the password is compromised.'
          )}
        </p>
        <p>
          <Markdown>
            {t(
              '2fa.final',
              'More information how to set up 2-Step verification is available at our <a target="_blank" href="https://help.prusa3d.com/article/prusaaccount-2-step-verification_230402">help</a>.'
            )}
          </Markdown>
        </p>

        <DialogButtons>
          <PlainButton onClick={dismissDialog} className="text-sm hover:underline focus:underline outline-none">
            {t('2fa.button.dismiss', "Don't remind me again")}
          </PlainButton>
          <ButtonAnchor target="_blank" href={`${import.meta.env.VITE_ACCOUNT_URL}/two-factor`}>
            {t('2fa.button.set', 'Set 2-Step Verification')}
          </ButtonAnchor>
        </DialogButtons>
      </DialogContent>
    </Dialog>
  )
}
