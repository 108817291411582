import { useInfiniteQuery, useMutation, useQuery } from '@tanstack/react-query'
import { useEffect } from 'react'

import { useApiClient } from '../client/apiClientContext'
import { REFETCH_NOTIFICATIONS_UNSEEN } from '../lib/fetchIntervals'
import { queryKey } from '../lib/queryKeys'
import { MutationOptions } from '../lib/types'
import type { components } from '../schema/schema'

export type Notification = components['schemas']['notification']
export type InvitationNotification = components['schemas']['invitation_notification']
export type TestNotification = components['schemas']['test_notification']
export type PrinterData = components['schemas']['printer_data_notification']
export type JobData = components['schemas']['job_data_notification']
export type TransferAbortedReasons = components['schemas']['transfer_aborted_reasons']
export type FwUpdateNotification = Notification & { data: { firmware: string; prusa_link: string } }

export type JobDataNotification = Notification & { data: JobData }

export enum INotificationName {
  FILAMENT_CHANGE_IN = 'FILAMENT_CHANGE_IN',
  FW_UPDATE = 'FW_UPDATE',
  LINK_UPDATE = 'LINK_UPDATE',
  INCIDENT_OFFLINE = 'INCIDENT_OFFLINE',
  INCIDENT_OTHER = 'INCIDENT_OTHER',
  INVITATION = 'INVITATION',
  JOB_FINISHED = 'JOB_FINISHED',
  PRINTER_ATTENTION = 'PRINTER_ATTENTION',
  PRINTER_ERROR = 'PRINTER_ERROR',
  PRINTER_PAUSED = 'PRINTER_PAUSED',
  PRINTER_PRINTING = 'PRINTER_PRINTING',
  PRINTER_RESUMED = 'PRINTER_RESUMED',
  TEST_NOTIFICATION = 'TEST_NOTIFICATION',
  TRANSFER_ABORTED = 'TRANSFER_ABORTED'
}

export function isInvitationNotification(notification: Notification): notification is InvitationNotification {
  return notification.name === INotificationName.INVITATION && notification.category === 'CONNECT'
}

export function isTestNotification(notification: Notification): notification is TestNotification {
  return notification.name === INotificationName.TEST_NOTIFICATION
}

export function isPrinterNotification(notification: Notification): boolean {
  return 'data' in notification && 'printer_name' in notification.data
}

export function isJobNotification(notification: Notification): notification is JobDataNotification {
  return 'data' in notification && 'job_id' in notification.data && notification.data?.job_id !== undefined
}

export function isAttentionNotification(notification: Notification): boolean {
  return notification.name === INotificationName.PRINTER_ATTENTION
}

export function isFwUpdateNotification(notification: Notification): notification is FwUpdateNotification {
  return notification.name === INotificationName.FW_UPDATE
}

export function isIncidentNotification(notification: Notification): boolean {
  return notification.name === 'INCIDENT_OFFLINE' || notification.name === 'INCIDENT_OTHER'
}

export function isTransferNotification(notification: Notification): boolean {
  return notification.name === 'TRANSFER_ABORTED'
}

export function useNotifications() {
  const { rawApi } = useApiClient()
  const notificationsPerPage = 20
  const monthAgo = Math.floor((Date.now() - 2629800000) / 1000)

  const { refetch: refetchUnseen, data: unseenData } = useQuery({
    queryKey: queryKey(['notifications', 'unseen']),
    queryFn: () => rawApi.GET('/app/notifications/unseen'),
    refetchInterval: REFETCH_NOTIFICATIONS_UNSEEN
  })

  const { data, refetch, isLoading, isFetching, fetchNextPage, hasNextPage, dataUpdatedAt } = useInfiniteQuery({
    queryKey: queryKey(['notifications']),
    queryFn: (pageParam) =>
      rawApi.GET('/app/notifications', {
        params: {
          query: {
            limit: notificationsPerPage,
            offset: pageParam.pageParam,
            from: monthAgo
          }
        }
      }),
    initialPageParam: 0,
    getNextPageParam: (lastPage, pages) => {
      if (!lastPage || !pages) {
        return
      }
      const totalPages = Math.ceil(lastPage.data?.pager.total || 0 / notificationsPerPage)
      const hasNextPage = pages.length < totalPages
      return hasNextPage ? (lastPage.data?.pager.offset || 0) + notificationsPerPage : undefined
    },
    refetchOnWindowFocus: false,
    enabled: false
  })

  useEffect(() => {
    refetchUnseen()
  }, [dataUpdatedAt, refetchUnseen])

  const notifications =
    data?.pages?.reduce((acc: Notification[], current) => {
      if (!current.data?.notifications) {
        return acc
      }
      acc = [...acc, ...current.data.notifications]
      return acc
    }, []) || []

  const { mutate: setAllRead } = useMutation({
    mutationFn: () => rawApi.POST('/app/notifications'),
    onSuccess: () => refetch()
  })

  const { mutate: setRead } = useMutation({
    mutationFn: (id: number) =>
      rawApi.PATCH('/app/notifications/{notification_id}', {
        params: {
          path: { notification_id: id }
        },
        body: {
          read: true
        }
      }),
    onSuccess: () => refetch()
  })

  return {
    count: unseenData?.data?.unseen || 0,
    notifications,
    setRead,
    setAllRead,
    isLoading,
    isFetching,
    refetch,
    fetchNextPage,
    hasNextPage
  }
}

export function useEditTeamSettings(options?: MutationOptions) {
  const { $api } = useApiClient()

  const mutation = $api.useMutation('put', '/app/teams/{team_id}/severity', options)

  return {
    ...mutation,
    mutate(teamId: number, settings: components['schemas']['notification_medium_severity']) {
      mutation.mutate({ body: settings, params: { path: { team_id: teamId } } })
    }
  }
}
