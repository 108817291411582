import { Link } from 'react-router-dom'
import styled, { css } from 'styled-components'

import { textEllipsis } from '../helpers/styled'

const HOURS_IN_INTERVAL = 6

const DAY_HEIGHT = 40
const DAY_MARGIN = 6
export const DAY = 24
export const SCROLL_OFFSET = 100 // pixels shown before current time line

export const TIME_INTERVAL_WIDTH = 200
export const HOUR_WIDTH = TIME_INTERVAL_WIDTH / HOURS_IN_INTERVAL // base
export const MINUTE_WIDTH = HOUR_WIDTH / 60

export const Wrapper = styled.div`
  margin-bottom: 1rem;
`

export const Notice = styled.p`
  text-align: center;
  margin: 0 0 1rem 0;
`

export const QueueContainer = styled.div<{ noGrid?: boolean; height?: number }>`
  position: relative;
  height: 100px;
  margin-bottom: 1rem;
  border-top: 1px solid var(--border-secondary);
  cursor: pointer;
  overflow: auto;
  user-select: none;

  &:hover {
    cursor: grab;
  }

  &:active {
    cursor: grabbing;
  }
`

export const TimeWrapper = styled.div`
  position: relative;
  border-bottom: 1px solid var(--border-secondary);
  margin-bottom: ${DAY_MARGIN}px;

  &::before {
    display: block;
    position: absolute;
    content: '';
    top: 100%;
    left: -20px;
    width: 20px;
    height: 1px;
    background: #d5d5d5;
  }
`

export const TimeHeader = styled.div`
  width: ${TIME_INTERVAL_WIDTH}px;
  font-size: 11px;
  text-align: left;
`

export const TimeMark = styled.div<{
  height?: number
}>`
  width: ${TIME_INTERVAL_WIDTH}px;
  border-left: 1px solid #707070;
  height: ${({ height }) => height || 9}px;
`

export const Day = styled.div<{ $isFirstPrinter?: boolean; $width?: number }>`
  position: relative;
  height: ${DAY_HEIGHT}px;
  width: ${({ $width }) => $width}px;
  margin: ${DAY_MARGIN}px 0;

  ${({ $isFirstPrinter }) =>
    $isFirstPrinter &&
    css`
      margin: 0;
    `}
`

export const CurrentTimeLine = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  border-left: 1px solid red;
  transition: all 500ms ease-out;
`

const jobStyles = css`
  height: 100%;
  position: absolute;
  display: flex;
  align-items: center;
  color: var(--color-text);
  overflow: hidden;

  &:hover {
    color: var(--color-text);
    text-decoration: underline;
  }
`

export const JobLink = styled(Link)`
  ${jobStyles}
`

export const Job = styled.div`
  ${jobStyles}
`

export const JobContent = styled.div<{ $active?: boolean; $isPrintAllowed?: boolean }>`
  font-size: 0.9rem;
  font-weight: 500;
  line-height: ${DAY_HEIGHT}px;
  flex: 1;
  height: 100%;
  padding: 0 0.25rem;
  ${textEllipsis};

  ${({ $active, $isPrintAllowed }) =>
    $active
      ? css`
          background: #7da7d97f;
        `
      : $isPrintAllowed
        ? css`
            background: #a3d39c7f;
          `
        : css`
            background: #e6777d7f;
          `}
`

export const Controls = styled.div`
  text-align: right;
`

export const ButtonLink = styled.button`
  font-weight: 300;
  font-size: 0.6875rem;
  text-transform: uppercase;
  color: var(--color-text) !important;
  text-decoration: underline;
  background-color: transparent;
  &:hover {
    text-decoration: none;
  }
  border: none;
  margin-left: 1rem;
`

export const ButtonTitle = styled.span`
  font-weight: 300;
  font-size: 0.6875rem;
  text-transform: uppercase;
  color: var(--color-text) !important;
  margin-left: 10px;
`
