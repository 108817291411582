import { PrintFile } from '@prusaconnect/api'

import { IFileType } from './file'
import { QueueRejected } from './job'
import { IGcodeMetadata } from './metadata'
import { PrinterUuid, TransferType } from './printer'
import { ISource } from './user'

export type IQueuedTransfers = {
  files: ITransferFile[]
}

export type IHistoricTransfers = {
  transfers: IHistoricTransfer[]
}

type DownloadCondition = {
  enabled: boolean
  satisfied: boolean
}

type DownloadConditionName =
  | 'destination_exists'
  | 'downloadable_state'
  | 'file_uploaded'
  | 'free_space'
  | 'no_current_transfer'
  | 'storage_exists'
  | 'storage_writable'
  | 'unique_name'

export type ITransferFile = {
  file?: PrintFile
  id: number
  hash: string
  team_id: number
  sort_order: number
  path: string
  display_path?: string
  source: string
  url?: string
  start?: number
  progress?: number
  time_remaining?: number
  size?: number
  team_name?: string
  preview_url?: string
  to_print?: boolean
  display_name?: string
  transferred?: number
  rejected?: QueueRejected
  conditions?: Record<DownloadConditionName, DownloadCondition>
}

export enum IUploadState {
  UPLOADING = 'UPLOADING',
  UPLOADED = 'UPLOADED',
  ERROR = 'ERROR'
}

export type IConnectUploads = {
  uploads: IConnectUpload[]
}

type IConnectUpload = {
  hash: string
  id: number
  name: string
  progress: number
  size: number
  source: string
  start: number
  state: IUploadState
  team_id: number
  path?: string
}

type ISourceFile<Type extends IFileType = IFileType> = {
  type: Type
  uploaded: number
  printer_types: string[]
  meta: IGcodeMetadata
  hash: string
  display_name: string
  name: string
  size: number
  team_id: number
  preview_url?: string
  preview_mimetype: string
}

type ISourceTeam = {
  id: number
  name: string
  description: string
}

export type IHistoricTransfer = {
  end: number
  id: number
  hash: string
  origin_id: number
  path: string
  printer_uuid: PrinterUuid
  size: number
  source: string
  source_file: ISourceFile
  source_info: ISource
  source_team: ISourceTeam
  planned: IPlannedTransfer
  start: number
  state: ITransferState
  team_id: number
  type: TransferType
}

type IPlannedTransfer = {
  hash: string
  id: number
  path: string
  team_id: number
}

export type IUploadedTransferFile = {
  size: number
  display_name: string
  total: number
  transferred: number
  // typecheck
  display_path?: string
  path?: string
  id?: number
  progress?: number
}

export enum ITransferState {
  TRANSFERING = 'TRANSFERING',
  PAUSED = 'PAUSED',
  FIN_STOPPED = 'FIN_STOPPED',
  FIN_ERROR = 'FIN_ERROR',
  FIN_OK = 'FIN_OK',
  UNKNOWN = 'UNKNOWN'
}

type ITransferStateProperties = {
  state: ITransferState
  primaryColor: string
}

enum ITransferStatePrimaryColor {
  UNKNOWN = '#adadad',
  TRANSFERING = '#7da7d9',
  PAUSED = '#bd8cbf',
  FINISHED = '#92c78c',
  ERROR = '#e77174',
  ATTENTION = '#e7925e'
}

export const states: ITransferStateProperties[] = [
  {
    state: ITransferState.TRANSFERING,
    primaryColor: ITransferStatePrimaryColor.TRANSFERING
  },
  {
    state: ITransferState.PAUSED,
    primaryColor: ITransferStatePrimaryColor.PAUSED
  },
  {
    state: ITransferState.FIN_STOPPED,
    primaryColor: ITransferStatePrimaryColor.ATTENTION
  },
  {
    state: ITransferState.FIN_ERROR,
    primaryColor: ITransferStatePrimaryColor.ERROR
  },
  {
    state: ITransferState.FIN_OK,
    primaryColor: ITransferStatePrimaryColor.FINISHED
  },
  {
    state: ITransferState.UNKNOWN,
    primaryColor: ITransferStatePrimaryColor.UNKNOWN
  }
]
