import { queryKey, REFETCH_JOBS } from '@prusaconnect/api'
import { ConfirmDialog, LoadingButton, Tooltip } from '@prusaconnect/ui'
import { CountBadge } from '@prusaconnect/ui/components/partials/CountBadge'
import { useQuery, useQueryClient } from '@tanstack/react-query'
import React, { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled, { useTheme } from 'styled-components'

import { useApiClient } from '../../api/react'
import { IPrinter, IPrinterSimpleView } from '../../api/types/printer'
import { IConnectState } from '../../api/types/state'
import { useCanControl } from '../../context/permissionsStore'
import { useDevice } from '../../helpers/useDevice'
import { useCommandMutation } from '../../hooks/commands/useCommandMutation'
import { useSupportedCommandByUuid } from '../../hooks/commands/useSupportedCommands'
import { isSlaPrinter } from '../../hooks/usePrinterType'
import { printerInState } from '../common/PrinterStateTag'
import { SvgIcon } from '../common/SvgIcon'

const SetReadyStyledButton = styled(LoadingButton)<{
  $color?: string
  lg: boolean
  borderColor?: string
}>`
  display: flex;
  white-space: nowrap;
  svg {
    margin-right: ${({ lg }) => (lg ? '0.25rem;' : '0;')};
  }

  ${({ borderColor }) => `border: 1px solid ${borderColor};`}

  ${({ $color }) =>
    $color &&
    `
    color: ${$color};
  `}
`

export function useSetPrinterReadyDialog() {
  const [showSetPrinterReadyDialog, setShowSetPrinterReadyDialog] = useState(false)

  const onSetPrinterReady = useCallback(() => {
    setShowSetPrinterReadyDialog(true)
  }, [])

  const onCancel = useCallback(() => {
    setShowSetPrinterReadyDialog(false)
  }, [])

  return {
    showSetPrinterReadyDialog,
    onSetPrinterReady,
    onCancel
  }
}

type DialogProps = {
  onCancel: () => void
  printer: IPrinterSimpleView
}

const SET_PRINTER_READY = 'SET_PRINTER_READY'
const getCommandSetReadyMutationKey = (uuid: string) => [SET_PRINTER_READY, uuid]

export const SetReadyButtonDialog = (props: DialogProps) => {
  const { t } = useTranslation()
  const { onCancel, printer } = props
  const isSla = isSlaPrinter(printer.printer_type)

  const queryClient = useQueryClient()

  const setReadyMutation = useCommandMutation(
    printer,
    {
      command: SET_PRINTER_READY,
      mutationKey: getCommandSetReadyMutationKey(props.printer.uuid),
      // to ensure on every request, that there will be a new state, cause it is used in set ready button
      cacheTime: 0
    },
    () => {
      const currentPrinterData = queryClient.getQueryData<IPrinter>(queryKey(['printer', props.printer.uuid]))
      queryClient.setQueryData(queryKey(['printer', props.printer.uuid]), {
        ...currentPrinterData,
        connect_state: IConnectState.READY,
        state: IConnectState.READY
      })
      onCancel()
    }
  )

  const query = {
    offset: 0,
    limit: 1
  }

  const api = useApiClient()
  const { isLoading } = useQuery({
    queryKey: [`/queue/${printer.uuid}/${JSON.stringify(query)}`],
    queryFn: () => api.app.jobs.getPrinterQueue(printer.uuid, query),
    refetchInterval: REFETCH_JOBS,
    gcTime: 0
  })

  // prevent dialogs from switching each other
  if (isLoading) {
    return null
  }

  return (
    <ConfirmDialog
      open
      title={t('printer.queue.set-prepared.confirm-title')}
      body={
        isSla
          ? t('printer.queue.set-prepared-sla.confirm-body', 'Is the printer ready?')
          : t('printer.queue.set-prepared.confirm-body')
      }
      onCancel={onCancel}
      onConfirm={() => setReadyMutation.executeAsync(undefined)}
    />
  )
}

type SetPrinterReadyButtonProps = {
  printer: IPrinterSimpleView
  plannedJobsCount?: number
  color?: string
  borderColor?: string
  onSetPrinterReady: () => void
}

export const SetPrinterReadyButton = React.memo(
  ({ printer, plannedJobsCount, color, borderColor, onSetPrinterReady }: SetPrinterReadyButtonProps) => {
    const { t } = useTranslation()
    const theme = useTheme()
    const canControl = useCanControl(printer.team_id)
    const [readyMutationStatus, setReadyMutationStatus] = useState<string>()
    const { lg } = useDevice()
    const queryClient = useQueryClient()
    const abortSetReadyMutation = useCommandMutation(printer, {
      command: 'CANCEL_PRINTER_READY'
    })

    const { executable } = useSupportedCommandByUuid('SET_PRINTER_READY', printer)
    const isSetPrinterNotReadyEnabled = abortSetReadyMutation.isAvailable
    const buttonDisabled = !(isSetPrinterNotReadyEnabled || executable)
    const isSetReadyMutationLoading = readyMutationStatus === 'loading'
    useEffect(() => {
      queryClient.getMutationCache().subscribe(({ mutation }) => {
        const mutationKey = mutation?.options.mutationKey
        if (mutationKey?.includes(SET_PRINTER_READY) && mutationKey?.includes(printer.uuid)) {
          setReadyMutationStatus(mutation?.state.status)
        }
      })
    }, [printer.uuid, queryClient])

    const onButtonClick = isSetPrinterNotReadyEnabled
      ? async () => {
          await abortSetReadyMutation.executeAsync({})
          queryClient.refetchQueries({ queryKey: queryKey(['printer', printer.uuid]) })
        }
      : () => onSetPrinterReady()

    const jobsInQueue = typeof plannedJobsCount === 'number' && plannedJobsCount > 0

    let buttonTooltip = ''
    if (abortSetReadyMutation.isPending) {
      buttonTooltip = t('loading')
    } else if (!canControl) {
      buttonTooltip = t('printer.tooltip.rights')
    } else if (buttonDisabled) {
      buttonTooltip = printerInState(t, printer.connect_state)
    } else if (jobsInQueue) {
      buttonTooltip = t('jobs.in-queue', { defaultValue: '{count} jobs in print queue', count: plannedJobsCount })
    }

    return (
      <Tooltip content={buttonTooltip} side="bottom" asChild>
        <div className="relative">
          {jobsInQueue && <CountBadge count={plannedJobsCount} />}
          <SetReadyStyledButton
            disabled={isSetReadyMutationLoading || abortSetReadyMutation.isPending || !canControl || buttonDisabled}
            isLoading={isSetReadyMutationLoading || abortSetReadyMutation.isPending}
            onClick={onButtonClick}
            $color={color}
            borderColor={borderColor}
            lg={lg}
          >
            <SvgIcon
              icon={isSetPrinterNotReadyEnabled ? 'printerHeaderNotPreparedIcon' : 'printerHeaderPreparedIcon'}
              size={25}
              fill={theme.colors.primary}
            />
            {lg &&
              (isSetPrinterNotReadyEnabled
                ? t('printer.queue.set-not-prepared.button-label')
                : t('printer.queue.set-prepared.button-label'))}
          </SetReadyStyledButton>
        </div>
      </Tooltip>
    )
  }
)
