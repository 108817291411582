import { Language } from '@prusaconnect/prusalator-i18next'
import { format as formatDate } from 'date-fns'
import { cs, de, enUS, es, fr, it, pl } from 'date-fns/locale'
import { useTranslation } from 'react-i18next'

import { ReactComponent as ConnectLogo } from '../../../public/prusa_connect_logo.svg'
import { useAuthState } from '../../context/authStore'
import { getEnvironment, Globals } from '../../helpers/getEnvironment'
import { useDevice } from '../../helpers/useDevice'
import { Markdown } from '../common/Markdown'
import { AfsHeaderMenu } from './AfsHeaderMenu'
import { DocumentTitle } from './DocumentTitle'
import { Drawer } from './Drawer'
import * as S from './Header.styled'
import { HeaderMenu } from './HeaderMenu'
import { LanguageDropdown } from './LanguageDropdown'
import { NotificationsDropdown } from './NotificationsDropdown'
import { UserDropdown } from './UserDropdown'

function TopMenu() {
  if (AFS_ENABLED) {
    return <AfsHeaderMenu />
  }
  return <HeaderMenu />
}

export const OUTAGE_MESSAGE_ID = 'global-outage-message'

type IOutageMaintenance = { text: string; start_time: number; end_time: number }

function OutageMessage() {
  const { t, i18n } = useTranslation()
  let locale
  switch (i18n.language) {
    case Language.cs:
      locale = cs
      break
    case Language.de:
      locale = de
      break
    case Language.es:
      locale = es
      break
    case Language.pl:
      locale = pl
      break
    case Language.it:
      locale = it
      break
    case Language.fr:
      locale = fr
      break
    default:
      locale = enUS
  }

  const outageMessage = getEnvironment(Globals.OUTAGE_MESSAGE)
  const maintenance = getEnvironment(Globals.OUTAGE_MESSAGE_MAINTENANCE)
  const fallbackMessage = getEnvironment(Globals.OUTAGE_MESSAGE_FALLBACK)

  let outageObj: IOutageMaintenance | null
  try {
    outageObj = maintenance ? JSON.parse(maintenance) : null
  } catch (_error) {
    outageObj = null
  }

  if (!outageMessage && !outageObj && !fallbackMessage) {
    return null
  }

  return (
    <S.OutageMessage id={OUTAGE_MESSAGE_ID}>
      {fallbackMessage ? (
        <Markdown>{fallbackMessage}</Markdown>
      ) : (
        <Markdown>
          {outageObj && Object.keys(outageObj).length
            ? t(outageObj.text, {
                defaultValue: `There will be a scheduled maintenance from {start} to {end}. Thank you for your patience.`,
                start: formatDate(Number(outageObj.start_time), 'P p', { locale }),
                end: formatDate(Number(outageObj.end_time), 'P p', { locale })
              })
            : t(outageMessage as string)}
        </Markdown>
      )}
    </S.OutageMessage>
  )
}

export function Header() {
  const { isAuthenticated } = useAuthState()
  const { lg } = useDevice()

  return (
    <>
      <DocumentTitle />
      <S.Sticky>
        <S.Header>
          <S.HeaderContainer className="container">
            <S.Left>
              {isAuthenticated && !lg && <Drawer />}
              {!!lg && (
                <S.Logo to="/">
                  <ConnectLogo />
                </S.Logo>
              )}
              {isAuthenticated && !!lg && <TopMenu />}
            </S.Left>

            {!lg && (
              <S.Center>
                <S.Logo to="/">
                  <ConnectLogo />
                </S.Logo>
              </S.Center>
            )}

            <S.Right>
              <LanguageDropdown />
              {isAuthenticated && <NotificationsDropdown />}
              <UserDropdown />
            </S.Right>
          </S.HeaderContainer>
        </S.Header>
        <OutageMessage />
      </S.Sticky>
    </>
  )
}
