export enum Layers {
  MODAL = 1070, // default Bootstrap modal z-index
  HAMBURGER = 1002,
  DRAWER = 1001,
  BACKDROP = 1000,
  DROPDOWN = 102,
  // eslint-disable-next-line @typescript-eslint/no-duplicate-enum-values
  MEGAMENU = 102,
  HEADER = 101,
  ASIDE = 100,
  ASIDE_OVERLAY = 99,
  PRINTER_HEADER = 98
}
