import { useLocalStorage } from 'react-use'

export function useRedirectUrl() {
  const [redirectUrl, setRedirectUrl, removeRedirectUrl] = useLocalStorage<string>('redirectUrl')

  return {
    redirectUrl,
    removeRedirectUrl,
    setupRedirectUrl() {
      const { pathname, search } = window.location
      const redirectUrl = `${pathname}${search}`
      setRedirectUrl(redirectUrl)
    }
  }
}

export function getAndClearRedirectUrl(): string | undefined {
  const redirectUrl = localStorage.getItem('redirectUrl')
  if (redirectUrl) {
    localStorage.removeItem('redirectUrl')
    try {
      const value = JSON.parse(redirectUrl)
      if (typeof value === 'string') return value
    } catch (_error) {
      // Ignore, implicit return undefined
    }
  }
}
