import { Language } from '@prusaconnect/prusalator-i18next'
import * as Sentry from '@sentry/react'
import { createContext, ReactNode, useContext, useMemo, useReducer } from 'react'

import { IUser } from '../api/types/user'

type AuthState = {
  isAuthenticated: boolean | undefined
  user: IUser
}

/** Actions + Reducer */

enum AuthActionTypes {
  LOGIN = 'LOGIN',
  LOGOUT = 'LOGOUT'
}

const loginAction = (user: IUser) =>
  ({
    type: AuthActionTypes.LOGIN,
    user
  }) as const

const logoutAction = () =>
  ({
    type: AuthActionTypes.LOGOUT
  }) as const

type AuthAction = ReturnType<typeof loginAction> | ReturnType<typeof logoutAction>

function authReducer(state: AuthState, action: AuthAction): AuthState {
  switch (action.type) {
    case AuthActionTypes.LOGIN:
      return { ...state, isAuthenticated: true, user: action.user }
    case AuthActionTypes.LOGOUT:
      return { ...state, isAuthenticated: false }
    default:
      return state
  }
}

/** Context  */

const AUTH_INITIAL_STATE: AuthState = {
  isAuthenticated: undefined,
  user: { username: '', default_team_id: 0, id: 0, public_name: '', language: Language.en }
}

const AuthStateContext = createContext<{
  authState: AuthState
  authDispatch: (action: AuthAction) => void
}>({
  authState: AUTH_INITIAL_STATE,
  authDispatch: () => {}
})

export function AuthStateContextProvider({ children }: { children: ReactNode }) {
  const [AuthState, dispatchToAuth] = useReducer(authReducer, AUTH_INITIAL_STATE)

  const value = useMemo(
    () => ({ authState: AuthState, authDispatch: dispatchToAuth }),

    [AuthState.isAuthenticated, AuthState.user, dispatchToAuth]
  )

  return <AuthStateContext.Provider value={value}>{children}</AuthStateContext.Provider>
}

/* Hooks */

function setSentryUser(user: IUser | null) {
  Sentry.setUser(
    user
      ? {
          id: user.id?.toString(),
          username: user.username,
          email: user.email
        }
      : null
  )
}

export function useAuthActions() {
  const { authDispatch } = useContext(AuthStateContext)

  const login = (user: IUser) => {
    setSentryUser(user)
    authDispatch(loginAction(user))
  }

  const logout = () => {
    setSentryUser(null)
    authDispatch(logoutAction())
  }

  return { login, logout }
}

export function useAuthState() {
  const { authState } = useContext(AuthStateContext)
  return authState
}
