type ErrorBody = {
  [key: string]: string | number
}

/**
 * @deprecated This is only an approximate, basic error format. It is used for legacy API client implementation.
 */
export class ApiError extends Error {
  code: string
  response: Response
  status: number
  statusText: string
  team_id?: number
  constructor(message: string, body: ErrorBody, response: Response) {
    super(message)

    this.code = body.code as string
    this.team_id = body.team_id as number
    this.response = response
    this.status = response.status
    this.statusText = response.statusText
  }
}
