import { z } from 'zod'

import { TemperatureUnits } from '../lib/textSymbols'

const FieldName = z.enum([
  'printer_model',
  'printer_type',
  'printing_time',
  'material',
  'layer_height',
  'print_end',
  'size',
  'nozzle_diameter',
  'nozzle',
  'support_material',
  'brim_width',
  'fill_density',
  'location',
  'team',
  'printing_progress',
  'printing_job_name',
  'time_remaining',
  'remaining_time',
  'estimated_end',
  'estimated_printing_time',
  'date',
  'print_started',
  'filament_change_in',
  'exposure_time_first',
  'exposure_time',
  'resin_used',
  'total_height',
  'total_layers',
  'current_layer',
  'resin_consumed',
  'firmware',
  'speed',
  'heatbed',
  'z_axis',
  'height',
  'order_progress',
  'order_items',
  'weight',
  'sheet',
  'color',
  'material_with_color',
  'count',
  'tags',
  'order_estimated_end'
])

const StatusBarFieldName = z.enum([
  'slots',
  'nozzle_temperature',
  'bed_temperature',
  'material',
  'speed',
  'current_z_height',
  'chamber_temperature',
  'current_layer',
  'cover_closed',
  'uv_led_temperature',
  'cpu_temperature'
])

const PrinterFamily = z.enum(['fdm', 'sla'])
const PreferencesState = z.enum(['idle', 'printing'])
const ViewType = z.enum(['cards', 'table'])
const TimeFormat = z.enum(['24h', '12h'])
const Locale = z.enum(['en', 'en-gb'])
const TemperatureUnit = z.enum([TemperatureUnits.CELSIUS, TemperatureUnits.FAHRENHEIT])
const DateFormat = z.enum(['l', 'll', 'L', 'LL'])
const ViewMode = z.enum(['cards', 'table', 'queue'])
const Dimensions = z.enum(['mm', 'in'])
const DarkModePreferences = z.enum(['LIGHT', 'DARK'])

const Field = z.object({
  visible: z.boolean().optional(),
  name: z.never()
})

const DataFieldParam = Field.extend({
  name: FieldName
})

const StatusBarParam = Field.extend({
  name: StatusBarFieldName
})

const JobsPreferences = z.object({
  params: z.record(PrinterFamily, z.array(DataFieldParam))
})

const FilesPreferences = z.object({
  params: z.record(PrinterFamily, z.record(PreferencesState, z.array(DataFieldParam)))
})

const PrintersPreferences = z.object({
  mode: ViewMode.optional(),
  params: z.record(PrinterFamily, z.record(ViewType, z.record(PreferencesState, z.array(DataFieldParam))))
})

const OrdersPreferences = z.object({
  mode: ViewMode.optional(),
  params: z.array(DataFieldParam)
})

const StatusBarPreferences = z.object({
  params: z.record(PrinterFamily, z.array(StatusBarParam))
})

export const UserPreferencesSchema = z.object({
  jobs: JobsPreferences,
  files: FilesPreferences,
  printers: PrintersPreferences,
  orders: OrdersPreferences,
  status_bar: StatusBarPreferences,
  dark_mode: DarkModePreferences.optional(),

  units: z.object({
    dimensions: Dimensions,
    temp: TemperatureUnit
  }),

  date_time: z.object({
    date_format: DateFormat,
    locale: Locale,
    time_format: TimeFormat
  })
})

export const UserSubmittedPreferencesSchema = UserPreferencesSchema.deepPartial()

export type UserPreferences = z.infer<typeof UserPreferencesSchema>
export type UserSubmittedPreferences = z.infer<typeof UserSubmittedPreferencesSchema>

export type PrinterParam = z.infer<typeof DataFieldParam>
export type StatusBarParam = z.infer<typeof StatusBarParam>
export type DateFormat = z.infer<typeof DateFormat>
export type TimeFormat = z.infer<typeof TimeFormat>
export type FieldName = z.infer<typeof FieldName>
export type ViewMode = z.infer<typeof ViewMode>
