import { useEffect, useState } from 'react'

import { getNormalizedSlicerData, NormalizedCompatiblePrinterParams } from '../../helpers/getNormalizedSlicerData'
import { CompatiblePrinterParams, InitOptions } from '../publicApi'
import {
  CloseCurrentDialogMessage,
  RequestOpenInBrowserMessage,
  SlicerMessage,
  WebAppReadyMessage
} from '../slicerMessageInterface'
import { EventTypes, SlicerEvent } from '../types'

declare global {
  // eslint-disable-next-line @typescript-eslint/consistent-type-definitions
  interface Window {
    _prusaSlicer: {
      postMessage: (message: SlicerMessage) => void
    }
  }

  // eslint-disable-next-line @typescript-eslint/consistent-type-definitions
  interface WindowEventMap {
    [EventTypes.INIT]: SlicerEvent<InitOptions>
    [EventTypes.CREATE_ACCESS_TOKEN]: SlicerEvent<string>
    [EventTypes.CREATE_SESSION_ID]: SlicerEvent<string>
    [EventTypes.REQUEST_COMPATIBLE_PRINTER]: SlicerEvent<CompatiblePrinterParams>
  }
}

export function isSlicerAvailable(): boolean {
  return Boolean(window._prusaSlicer && window._prusaSlicer.postMessage !== undefined)
}

export function sendMessage(message: SlicerMessage) {
  if (!window._prusaSlicer || !window._prusaSlicer.postMessage) {
    console.error('Slicer interface not available')
    return
  }

  console.debug('Sending message to slicer:', message)

  window._prusaSlicer.postMessage(message)
}

/**
 * Components implementing this hook will notify Slicer that they are ready to receive commands.
 */
export function useAppReady(componentName: string) {
  const [isReady, setIsReady] = useState(false)
  useEffect(() => {
    if (!isReady) {
      const message: WebAppReadyMessage = {
        action: 'WEBAPP_READY',
        componentName
      }

      sendMessage(message)
      setIsReady(true)
    }
  }, [isReady, componentName])
}

export function useRequestCompatiblePrinterParams(): NormalizedCompatiblePrinterParams | null {
  const [printerParams, setPrinterParams] = useState<NormalizedCompatiblePrinterParams | null>(null)

  useEffect(() => {
    const listener = (event: SlicerEvent<CompatiblePrinterParams>) => {
      setPrinterParams(getNormalizedSlicerData(event.detail.data))
    }

    window.addEventListener(EventTypes.REQUEST_COMPATIBLE_PRINTER, listener)

    return () => {
      window.removeEventListener(EventTypes.REQUEST_COMPATIBLE_PRINTER, listener)
    }
  }, [])

  return printerParams
}

export function openInBrowser(url: string) {
  const message: RequestOpenInBrowserMessage = {
    action: 'REQUEST_OPEN_IN_BROWSER',
    url
  }

  sendMessage(message)
}

export function closeCurrentWindow() {
  const message: CloseCurrentDialogMessage = {
    action: 'CLOSE_DIALOG'
  }

  sendMessage(message)
}
