import styled from 'styled-components'

import { IPager } from '../../api/types/pager'
import { Limitation } from '../helpers/limitation'
import { Pagination } from '../helpers/pagination'

type Props = {
  pager?: IPager
  setLimit: (limit: number) => void
  setOffset: (offset: number) => void
  itemsPerPage?: [number, number, number]
  autoHide?: boolean
}

const StyledContainer = styled.div`
  display: flex;
  flex-wrap: wrap;

  .pagination {
    margin: 0;
    font-size: 0.875rem;

    .page-item .page-link {
      font-weight: 400;
      color: var(--color-primary);
    }

    .page-item.active .page-link {
      color: white;
      background-color: var(--color-primary);
      border-color: var(--color-primary);
    }
  }
`

export function Pager({ pager, setLimit, setOffset, itemsPerPage, autoHide }: Props) {
  if (autoHide && (!pager || pager.total <= pager.limit)) {
    return null
  }

  return (
    <StyledContainer>
      <div className="flex">
        <Pagination pager={pager} setOffset={setOffset} />
      </div>
      <div className="ml-auto flex items-end">
        <Limitation limit={pager?.limit} setOffset={setOffset} setLimit={setLimit} itemsPerPage={itemsPerPage} />
      </div>
    </StyledContainer>
  )
}
