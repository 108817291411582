import { initI18n } from '@prusaconnect/prusalator-i18next'
import { StrictMode } from 'react'
import { createRoot } from 'react-dom/client'

import { App } from './App'
import { initializeSentry } from './helpers/initializeSentry'
import { getInitialLanguage } from './interfaces/locale'
import { isSlicerAvailable } from './slicer/hooks/useSlicer'
import interceptExternalLinkClicks from './slicer/interceptExternalLinkClicks'
import { SlicerConfigProvider } from './slicer/SlicerConfigContext'

const language = getInitialLanguage()

if (!AFS_ENABLED) {
  initializeSentry()
}

const i18n = await initI18n(language)

const container = document.getElementById('root')
const root = createRoot(container as HTMLElement)
root.render(
  <StrictMode>
    <SlicerConfigProvider>
      <App i18next={i18n} />
    </SlicerConfigProvider>
  </StrictMode>
)

// Intercept clicks on external links and open them in the browser if the app is running in PrusaSlicer.
if (isSlicerAvailable()) {
  document.body.addEventListener('click', interceptExternalLinkClicks)
}
