import { Language } from '@prusaconnect/prusalator-i18next'

import { IMembership, ITeamInvitedUser, ITeamUser } from './team'

export type ILogin = {
  user: IUser
}

export type ILogoutResponse = {
  redirect_url?: string
}

export type IUser = {
  id: number
  username: string
  language: Language
  first_name?: string
  last_name?: string
  public_name: string
  email?: string
  default_team_id: number
  teams?: IMembership[]
  is_admin?: boolean
  origin?: IOrigin
  password_is_generated?: boolean
  avatar?: string
  otp_enabled?: boolean
  conditions_signed?: boolean
  conditions_signed_on?: number
  created_on?: number
  preferences?: any
}

export type ISource = {
  id: number
  avatar?: string
  first_name?: string
  last_name?: string
  public_name?: string
  username?: string
}

export enum IOrigin {
  CONNECT = 'CONNECT',
  PRUSA_AUTH = 'PRUSA_AUTH'
}

export type IUserInvitation = {
  id: number
  name?: string
  valid?: number
  refused?: number
  code: string
  rights_ro: boolean
  rights_use: boolean
  rights_rw: boolean
  team: {
    id: number
    name: string
    description?: string
    user_count?: number
  }
  user: {
    username?: string
    first_name?: string
    last_name?: string
    public_name: string
    avatar?: string
  }
}

export type IUserInvitationsResponse = {
  invitations: IUserInvitation[]
  refused: IUserInvitation[]
}

export function getUserName(
  user?: (IUser | ITeamUser | ITeamInvitedUser) & { first_name?: string; last_name?: string; name?: string }
) {
  if (!user) {
    return ''
  }

  return user.public_name
    ? user.public_name
    : user.first_name && user.last_name
      ? `${user.first_name} ${user.last_name}`
      : user.username || user.name || ''
}
