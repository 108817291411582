import { Navigate, Outlet } from 'react-router-dom'

import { useAuthState } from '../context/authStore'

export function GuestOnlyRoute() {
  const { isAuthenticated } = useAuthState()

  if (isAuthenticated === true) {
    return <Navigate replace to="/printers" />
  }

  return <Outlet />
}
