import Color from 'color'
import { MouseEvent, ReactNode } from 'react'
import { Link } from 'react-router-dom'
import styled, { css, useTheme } from 'styled-components'

import attentionIcon from '../../svg/icons/attention.svg'
import attentionInverseIcon from '../../svg/icons/attention-inverse.svg'
import { getColorScheme } from '../../theme/getColorScheme'
import { DataItem, DataItemList } from './DataItemList'
import ScrollContainer from './ScrollContainer'

const ROW_HEIGHT = '7rem'
const DEFAULT_ACCENT_COLOR = '#c5c5c5'

const Image = styled.div<{ $baseColor?: string }>`
  grid-area: image;
  background-color: ${(props) => props.$baseColor};
  align-items: center;
  justify-content: center;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`

const Content = styled.div<{ $backgroundColor?: string; $progressBarColor?: string; $progress?: number }>`
  grid-area: content;
  display: flex;
  position: relative;
  flex-direction: column;
  min-width: 0;
  background-color: ${(props) => props.$backgroundColor};
  background: linear-gradient(
    to right,
    ${(props) => props.$progressBarColor} ${(props) => props.$progress || 0}%,
    ${(props) => props.$backgroundColor} 0%
  );
  padding: 0.3rem 1rem;
`

const ItemContainer = styled(Link)<{ $backgroundColor?: string; $isTransparent?: boolean; $progress?: number }>`
  display: grid;
  grid-template-areas:
    'image content'
    '. attentions';
  grid-template-rows: ${ROW_HEIGHT} 1fr;
  grid-template-columns: ${ROW_HEIGHT} auto;
  color: var(--color-secondary);
  opacity: ${(props) => (props.$isTransparent ? 0.5 : 1)};
  filter: ${(props) => (props.$isTransparent ? 'grayscale()' : 'none')};

  &:hover {
    color: var(--color-secondary);

    ${Image} {
      background: ${(props) =>
        Color(
          props.$backgroundColor === 'transparent'
            ? window.document.documentElement.getAttribute('--background-body') || '#fff'
            : props.$backgroundColor
        )
          .darken(0.05)
          .hex()};
    }

    ${Content} {
      background: linear-gradient(
        to right,
        ${(props) =>
            Color(
              props.$backgroundColor === 'transparent'
                ? window.document.documentElement.getAttribute('--background-body') || '#fff'
                : props.$backgroundColor
            )
              .darken(0.1)
              .hex()}
          ${(props) => props.$progress || 0}%,
        ${(props) =>
            Color(
              props.$backgroundColor === 'transparent'
                ? window.document.documentElement.getAttribute('--background-body') || '#fff'
                : props.$backgroundColor
            )
              .darken(0.05)
              .hex()}
          0%
      );
    }
  }
`

const ContentHeading = styled.div`
  display: flex;
  align-items: center;
  flex: 1;
  gap: 0.5rem;

  h3 {
    font-weight: 700;
    flex: 1 1;
    font-size: 1.125rem;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    line-height: normal;
  }
`

const ControlsContainer = styled.div`
  display: flex;
  gap: 1rem;
`

const ScrollableContainer = styled(ScrollContainer)`
  margin: 0 -0.7rem;
  padding: 0 0.7rem;
`

const DataItems = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
`

const AttentionList = styled.ul<{ $accentColor?: string; $hideIcon?: boolean }>`
  grid-area: attentions;
  background-color: ${(props) => props.$accentColor};
  font-weight: 400;
  list-style: none;
  padding: 0.3rem 0.7rem;
  margin: 0;

  li {
    font-size: 0.9rem;
    ${(p) =>
      p.$hideIcon
        ? ''
        : css`
            padding-left: 2.4rem;
          `}
    margin: 0.2rem;
  }

  ${(props) =>
    props.$hideIcon
      ? ''
      : css`
          li:first-child {
            background: url(${(props) => (props.theme.isDark ? attentionInverseIcon : attentionIcon)}) no-repeat left
              center;
            background-size: 1.7rem;
          }
        `}
`

const ProgressLine = styled.div<{ $backgroundColor?: string; $progressBarColor?: string; $progress: number }>`
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 3px;
  margin: 0 -1rem;

  background-color: ${(props) => props.$backgroundColor || 'transparent'};
  background: linear-gradient(
    to right,
    ${(props) => props.$progressBarColor} ${(props) => props.$progress || 0}%,
    ${(props) => props.$backgroundColor || 'transparent'} 0%
  );
`

type Props = {
  title: string
  link: string
  baseColor?: string
  progress?: number
  isTransparent?: boolean
  imageElement?: ReactNode
  statusBadge?: ReactNode
  controls?: ReactNode
  dataItems?: DataItem[]
  dataComponents?: ReactNode | null
  attentionList?: string[]
  attentionListHideIcon?: boolean
}

export function ListItem(props: Props) {
  const theme = useTheme()
  const colorScheme = props.baseColor ? getColorScheme(props.baseColor, theme.name === 'dark') : null
  const accentColor = colorScheme?.backgroundSecondary.background || DEFAULT_ACCENT_COLOR
  const backgroundColor = colorScheme?.background.background || 'transparent'
  const progressBarColor = colorScheme?.progress.background || '#e1e1e1'
  const progressLineColor = props.baseColor || '#adadad'

  return (
    <ItemContainer
      to={props.link}
      style={{ cursor: props.link === '#' ? 'default' : 'pointer' }}
      $isTransparent={props.isTransparent}
      $backgroundColor={backgroundColor}
      $progress={props.progress}
    >
      <Image $baseColor={backgroundColor}>{props.imageElement && props.imageElement}</Image>
      <Content $backgroundColor={backgroundColor} $progressBarColor={progressBarColor} $progress={props.progress}>
        <ContentHeading>
          <h3>{props.title}</h3>
          <ControlsContainer
            onClick={(e: MouseEvent) => {
              e.preventDefault()
            }}
          >
            {props.controls}
          </ControlsContainer>
        </ContentHeading>
        <ScrollableContainer background={backgroundColor}>
          <DataItems>
            {props.statusBadge && props.statusBadge}
            {!!props.dataItems?.length && <DataItemList dataItems={props.dataItems} />}
            {props.dataComponents}
          </DataItems>
        </ScrollableContainer>
        {props.progress ? (
          <ProgressLine
            $progress={props.progress}
            $backgroundColor={backgroundColor}
            $progressBarColor={progressLineColor}
          />
        ) : null}
      </Content>
      {props.attentionList && props.attentionList.length > 0 && (
        <AttentionList $accentColor={accentColor} $hideIcon={props.attentionListHideIcon}>
          {props.attentionList.map((attention) => (
            <li key={attention}>{attention}</li>
          ))}
        </AttentionList>
      )}
    </ItemContainer>
  )
}
