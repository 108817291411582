/* eslint-disable @typescript-eslint/no-duplicate-enum-values */
import dayjs from 'dayjs'
import duration, { Duration } from 'dayjs/plugin/duration'

export enum ITimetype {
  DATETIME = 'DATETIME',
  TIME = 'TIME',
  DATE = 'DATE',
  DURATION = 'DURATION',
  COUNTDOWN = 'COUNTDOWN',
  DISTANCE_TO_NOW = 'DISTANCE_TO_NOW'
}

// https://day.js.org/docs/en/display/format
export enum IDateTimeFormat {
  L_LT = 'L LT', // MM/DD/YYYY h:mm A - 08/16/2018 8:02 PM
  L_LTS = 'L LTS', // MM/DD/YYYY h:mm:ss A - 08/16/2018 8:02:18 PM
  LL_LT = 'LL LT', // MMMM D, YYYY h:mm A - August 16, 2018 8:02 PM
  LL_LTS = 'LL LTS', // MMMM D, YYYY h:mm:ss A - August 16, 2018 8:02:18 PM
  LLL = 'LL LT', // MMMM D, YYYY h:mm A - August 16, 2018 8:02 PM
  LLLS = 'LL LTS', // MMMM D, YYYY h:mm:ss A - August 16, 2018 8:02:23 PM
  LLLL = 'dddd, LL LT', // dddd, MMMM D, YYYY h:mm A - Thursday, August 16, 2018 8:02 PM
  l_LT = 'l LT', // M/D/YYYY h:mm A - 8/16/2018 8:02 PM
  l_LTS = 'l LTS', // M/D/YYYY h:mm:ss A - 8/16/2018 8:02:18 PM
  ll_LT = 'll LT', // MMM D, YYYY h:mm A - Aug 16, 2018 8:02 PM
  ll_LTS = 'll LTS', // MMM D, YYYY h:mm:ss A - Aug 16, 2018 8:02:18 PM
  lll = 'll LT', // MMM D, YYYY h:mm A - Aug 16, 2018 8:02 PM
  llls = 'll LTS', // MMM D, YYYY h:mm:ss A - Aug 16, 2018 8:02:23 PM
  llll = 'ddd, ll LT' // ddd, MMM D, YYYY h:mm A - Thu, Aug 16, 2018 8:02 PM
}

export enum ITimeFormat {
  LT = 'LT', // h:mm A - 8:02 PM
  LTS = 'LTS' // h:mm:ss A - 8:02:18 PM
}

export enum IDateFormat {
  L = 'L', // MM/DD/YYYY - 08/16/2018
  LL = 'LL', // MMMM D, YYYY - August 16, 2018
  l = 'l', // M/D/YYYY - 8/16/2018
  ll = 'll' // MMM D, YYYY - Aug 16, 2018
}

export enum IDateTimeFullFormat {
  LTF = 'HH:mm', // HH:mm - 20:02
  LTSF = 'HH:mm:ss', // HH:mm:ss - 20:02:18
  LF = 'L', // MM/DD/YYYY - 08/16/2018
  L_LTF = 'L HH:mm', // MM/DD/YYYY HH:mm - 08/16/2018 20:02
  L_LTSF = 'L HH:mm:ss', // MM/DD/YYYY HH:mm:ss - 08/16/2018 20:02:18
  LLF = 'LL', // MMMM D, YYYY - August 16, 2018
  LL_LTF = 'LL HH:mm', // MMMM D, YYYY HH:mm - August 16, 2018 20:02
  LL_LTSF = 'LL HH:mm:ss', // MMMM D, YYYY HH:mm:ss - August 16, 2018 20:02:18
  LLLF = 'MMMM D, YYYY HH:mm', // August 16, 2018 20:02
  LLLSF = 'MMMM D, YYYY HH:mm:ss', // August 16, 2018 20:02:23
  LLLLF = 'dddd, MMMM D, YYYY HH:mm', // Thursday, August 16, 2018 20:02
  lF = 'l', // M/D/YYYY - 8/16/2018
  l_LTF = 'l HH:mm', // M/D/YYYY HH:mm - 8/16/2018 20:02
  l_LTSF = 'l HH:mm:ss', // M/D/YYYY HH:mm:ss - 8/16/2018 20:02:18
  llF = 'll', // MMM D, YYYY - Aug 16, 2018
  ll_LTF = 'll HH:mm', // MMM D, YYYY HH:mm - Aug 16, 2018 20:02
  ll_LTSF = 'll HH:mm:ss', // MMM D, YYYY HH:mm:ss - Aug 16, 2018 20:02:18
  lllF = 'MMM D, YYYY HH:mm', // Aug 16, 2018 20:02
  lllsF = 'MMM D, YYYY HH:mm:ss', // Aug 16, 2018 20:02:23
  llllF = 'ddd, MMM D, YYYY HH:mm' // Thu, Aug 16, 2018 20:02
}

/**
 * Create dayjs duration from input like '1d 5h 20m 5s'
 * @param duration
 */
dayjs.extend(duration)
export function parseDuration(duration: string): Duration {
  const haveDays = duration.includes('d')
  const durationInput = `P${haveDays ? '' : 'T'}${duration.replaceAll(' ', '').replace('d', 'dt').toUpperCase()}`
  return dayjs.duration(durationInput)
}
