import { CAMERA_STALE_TIME_IDLE, CAMERA_STALE_TIME_PRINTING } from '@prusa3d-platform/connect-utils'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { useEffect, useMemo, useRef } from 'react'

import { useApiClient } from '../client/apiClientContext'
import { REFETCH_CAMERA_IDLE, REFETCH_CAMERA_PRINTING, REFETCH_PRINTER_CAMERAS } from '../lib/fetchIntervals'
import { queryKey } from '../lib/queryKeys'
import type { components } from '../schema/schema'

export type IJobCamera = components['schemas']['cameras_for_job'][0]
export type ICamera = components['schemas']['camera_response'] | IJobCamera
export type ICameraUpdateInput = { id: number } & components['schemas']['camera_request']
export type CameraRotation = components['schemas']['camera_rotation']

/** @todo change api, FE don't need this functions, it is more optional way than call every printer about camera details */
export function useAllCameras(limit = 100) {
  const { rawApi } = useApiClient()

  return useQuery({
    queryKey: queryKey(['cameras', { limit }]),
    queryFn: () => rawApi.GET('/app/cameras', { params: { query: { limit } } }).then((res) => res.data)
  })
}

export function usePrinterCameras(printer_uuid: string) {
  const { rawApi } = useApiClient()

  return useQuery({
    queryKey: queryKey(['printer', printer_uuid, 'cameras']),
    refetchInterval: REFETCH_PRINTER_CAMERAS,
    queryFn: () =>
      rawApi
        .GET(`/app/printers/{printer_uuid}/cameras`, {
          params: { path: { printer_uuid } }
        })
        .then((res) => res.data)
  })
}

export function usePrinterRegisteredCameras(printer_uuid: string) {
  const cameras = usePrinterCameras(printer_uuid)

  return useMemo(() => {
    const registered = cameras.data?.cameras?.filter((camera) => camera.registered) || []

    return { ...cameras, data: { ...cameras.data, cameras: registered } }
  }, [cameras])
}

export function usePrinterCameraLastImage({
  printerUuid,
  printerIsPrinting,
  resolution,
  forceFastRefetch,
  enabled = true
}: Omit<useLatestCameraImageProps, 'cameraId'>) {
  const allCameras = useAllCameras()

  const firstCamera = allCameras.data?.cameras.find((c) => c.printer_uuid === printerUuid)

  return useLatestCameraImage({
    printerUuid,
    printerIsPrinting,
    cameraId: firstCamera?.id as number,
    resolution,
    enabled: !!firstCamera?.id && enabled,
    forceFastRefetch,
    rotation: firstCamera?.config?.rotation
  })
}

export function useRegisterCamera(printer_uuid: string) {
  const { rawApi } = useApiClient()
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: (origin?: 'WEB' | 'OTHER') =>
      rawApi.POST(`/app/printers/{printer_uuid}/camera`, { params: { path: { printer_uuid }, query: { origin } } }),
    onSuccess: (response) => {
      queryClient.invalidateQueries({ queryKey: queryKey(['printer', printer_uuid, 'cameras']) })
      return response
    }
  })
}

export function useRemoveCamera(printer_uuid: string) {
  const { rawApi } = useApiClient()
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: (camera_id: number) => rawApi.DELETE(`/app/cameras/{camera_id}`, { params: { path: { camera_id } } }),
    onSuccess: () => queryClient.invalidateQueries({ queryKey: queryKey(['printer', printer_uuid, 'cameras']) })
  })
}

export function useUpdateCamera(printer_uuid: string) {
  const { rawApi } = useApiClient()
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: (data: { id: number } & ICameraUpdateInput) =>
      rawApi.PUT(`/app/cameras/{camera_id}`, { params: { path: { camera_id: data.id } }, body: data }),
    onSuccess: () => queryClient.invalidateQueries({ queryKey: queryKey(['printer', printer_uuid, 'cameras']) })
  })
}

type useLatestCameraImageProps = {
  printerUuid: string
  printerIsPrinting: boolean
  cameraId: number
  /** This type will be fetched directly from api, to prevent more same requests */
  resolution: 'thumbnail' | 'snapshot'
  enabled?: boolean
  /** Refetch like camera printer is in state printing, ignore real state */
  forceFastRefetch?: boolean
  rotation?: CameraRotation
}

/**
 * @deprecated This must be moved to normal API data, not this crazy way how to read latest timestamp
 */
export function useLatestCameraImage({
  printerUuid,
  printerIsPrinting,
  cameraId,
  resolution: imageType,
  enabled = true,
  forceFastRefetch,
  rotation
}: useLatestCameraImageProps) {
  const staleTime = printerIsPrinting ? CAMERA_STALE_TIME_PRINTING : CAMERA_STALE_TIME_IDLE

  const actualObjectUrl = useRef<string | null>(null)
  const oldObjectUrl = useRef<string | null>(null)

  const { rawApi } = useApiClient()

  // Clear old object urls when closing component
  useEffect(() => {
    return () => {
      if (actualObjectUrl.current) URL.revokeObjectURL(actualObjectUrl.current)
      if (oldObjectUrl.current) URL.revokeObjectURL(oldObjectUrl.current)
    }
  }, [])

  return useQuery({
    enabled: cameraId !== undefined && enabled,
    placeholderData: (previousData) => previousData,
    queryKey: queryKey(['printer', printerUuid, 'cameras', { cameraId, imageType, rotation }]),
    queryFn: async () => {
      const endpoint =
        imageType === 'thumbnail' ? '/thumbnail/camera/{camera_id}' : '/app/cameras/{camera_id}/snapshots/last'

      // Retype to /app/.... to prevent TS error, thumbnail is not part of api, this is way how to load thumbnails with same code
      const { response } = await rawApi.GET(endpoint as '/app/cameras/{camera_id}/snapshots/last', {
        params: { path: { camera_id: cameraId }, query: { printer_uuid: printerUuid } },
        parseAs: 'stream'
      })

      if (!response.ok) {
        return
      }

      const dateString = response.headers.get('last-modified')
      const timestamp = new Date(dateString || '').getTime() / 1000

      const newObjectUrl = URL.createObjectURL(await response.blob())
      if (oldObjectUrl.current) URL.revokeObjectURL(oldObjectUrl.current)
      if (actualObjectUrl.current) oldObjectUrl.current = actualObjectUrl.current
      actualObjectUrl.current = newObjectUrl

      return {
        timestamp,
        isStale: Date.now() / 1000 - timestamp > staleTime,
        imageObjectUrl: actualObjectUrl.current,
        rotation
      }
    },
    refetchInterval({ state }) {
      if (forceFastRefetch) return REFETCH_CAMERA_PRINTING

      const isStale = state.data ? Date.now() / 1000 - state.data.timestamp > staleTime : false

      if (printerIsPrinting && !isStale) {
        return REFETCH_CAMERA_PRINTING
      }
      return REFETCH_CAMERA_IDLE
    }
  })
}
