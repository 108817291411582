import { exhaustiveGuard } from '@prusa3d-platform/connect-utils'
import { LoadingButton, PlainButton, Tooltip } from '@prusaconnect/ui'
import { MouseEvent, ReactNode, useRef } from 'react'

import { Icon } from '../../../icons'
import { DropdownItemButton } from '../Dropdown'
import { SvgIcon } from '../SvgIcon'
import { useAdaptiveButtonContext } from './AdaptiveButtonContext'

type ActionProps = {
  trigger?: (e: MouseEvent<Element>) => void
  label: string
  icon?: Icon
  className?: string
  isLoading: boolean
  isDisabled?: boolean
  disabledTooltip?: string
  iconNode?: ReactNode
  showLabel?: boolean
}

export function AdaptiveButton({ icon, ...props }: ActionProps) {
  const context = useAdaptiveButtonContext()

  switch (context) {
    case 'simple':
      return (
        <Tooltip content={props.isDisabled ? props.disabledTooltip : undefined}>
          <LoadingButton disabled={props.isLoading || props.isDisabled} onClick={props.trigger} {...props}>
            {icon && <SvgIcon icon={icon} />}
            {props.label}
          </LoadingButton>
        </Tooltip>
      )
    case 'dropdown':
      return <DropdownButton icon={icon} {...props} />
    case 'icon':
      return <IconButton icon={icon} {...props} />
    default:
      return exhaustiveGuard(context)
  }
}

function DropdownButton(actionProps: ActionProps) {
  return (
    <Tooltip content={actionProps.isDisabled ? actionProps.disabledTooltip : undefined}>
      <DropdownItemButton
        onClick={!actionProps.isDisabled ? actionProps.trigger : () => {}}
        disabled={!!actionProps.isDisabled}
        className={actionProps.className}
        role="menuitem"
        tabIndex={0}
      >
        {actionProps.icon && <SvgIcon icon={actionProps.icon} />}
        {actionProps.label}
      </DropdownItemButton>
    </Tooltip>
  )
}

function IconButton(actionProps: ActionProps) {
  const buttonRef = useRef<HTMLButtonElement | null>(null)

  return (
    <Tooltip content={actionProps.isDisabled ? actionProps.disabledTooltip : actionProps.label}>
      <PlainButton
        className={actionProps.className}
        disabled={!!actionProps.isDisabled}
        onClick={(e) => {
          actionProps.trigger?.(e)
          buttonRef.current?.blur()
        }}
      >
        {actionProps.icon && <SvgIcon size={25} icon={actionProps.icon} />}
        {actionProps.showLabel && actionProps.label}
      </PlainButton>
    </Tooltip>
  )
}
