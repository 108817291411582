import { useEffect, useState } from 'react'

import { IPrinterDetailJobInfo } from '../api/types/job'
import { IPrinterSimpleView } from '../api/types/printer'

const stoppedPrintEventName = 'STOPPED_PRINT_CUSTOM_EVENT'

export const dispatchPrintStoppedEvent = (printerUuid: string, printerType: string, jobId?: number) => {
  const stoppedPrintEvent = new CustomEvent(stoppedPrintEventName, {
    detail: {
      printStopped: true,
      printerUuid,
      printerType,
      jobId
    }
  })
  window.dispatchEvent(stoppedPrintEvent)
}

export function useStoppedPrint() {
  const [showStoppedPrintReasonsDialog, setShowStoppedPrintDialogs] = useState(false)
  const [jobId, setJobId] = useState<IPrinterDetailJobInfo['id']>()
  const [uuid, setUuid] = useState<IPrinterSimpleView['uuid']>()
  const [printerType, setPrinterType] = useState<IPrinterSimpleView['printer_type']>('')

  useEffect(() => {
    window.addEventListener(stoppedPrintEventName, printStoppedEventHandler)
  }, [])

  const printStoppedEventHandler = (e: Event) => {
    const event = e as CustomEvent
    if (event.detail.printStopped) {
      setShowStoppedPrintDialogs(true)
      setJobId(event.detail.jobId)
      setUuid(event.detail.printerUuid)
      setPrinterType(event.detail.printerType)
    }
  }

  return {
    jobId,
    uuid,
    printerType,
    showStoppedPrintReasonsDialog,
    setShowStoppedPrintDialogs
  }
}
