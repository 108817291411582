import classNames from 'classnames'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { ProgressBar } from '../primitives/ProgressBar'
import { formatSize } from './units/FileSize'
import { Percentage } from './units/Percentage'

type Props = {
  free: number
  total?: number
  isTeamInfo?: boolean
}

export function UsedStorageInfo(props: Props) {
  const { total, free, isTeamInfo = false } = props
  const { t } = useTranslation()

  const bar = useMemo(() => {
    if (total === undefined) {
      return null
    }
    let used = total - free
    // Avoid overflow
    if (used > total) {
      used = total
    }

    return <ProgressBar now={used} max={total} label={<Percentage value={(used / total) * 100} />} />
  }, [free, total])

  const info = useMemo(() => {
    if (total === undefined) {
      return t('printer.file.free-space-info', {
        free: formatSize(free),
        defaultValue: '{free} free'
      })
    }

    const used = total - free

    if (free < 0) {
      return t('printer.file.space-used', {
        free: formatSize(used),
        defaultValue: '{free} used'
      })
    }

    return t('printer.file.complete-space-info', {
      used: formatSize(used),
      free: formatSize(free),
      total: formatSize(total),
      defaultValue: '{used} of {total} ({free} left)'
    })
  }, [free, t, total])

  return (
    <div
      className={classNames('my-2 mx-3 text-[0.8rem]', {
        'flex flex-grow shrink-0 basis-auto items-center gap-2 text-[1rem] my-2 mx-0': !isTeamInfo
      })}
    >
      {isTeamInfo && <div className="flex justify-between">{t('storage.quota', 'Storage quota')}</div>}
      {bar}
      <div className="whitespace-nowrap">{info}</div>
    </div>
  )
}
