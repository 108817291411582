import { ReactNode } from 'react'

type Props = {
  now: number
  max: number
  label?: ReactNode
}

export function ProgressBar(props: Props) {
  const { now, max, label } = props
  const width = (now / max) * 100

  return (
    <div className="relative flex items-center justify-center h-5 w-full min-w-[100px] bg-neutral-200 dark:bg-neutral-500">
      <div className="absolute inset-0 h-5 bg-primary" style={{ width: `${width}%` }} />
      <div className="text-[0.9rem] font-medium relative">{label}</div>
    </div>
  )
}
