import { THINSP } from '../../../lib/textSymbols'

export function formatSize(size: number) {
  const units = ['B', 'KB', 'MB', 'GB', 'TB']

  let unitIndex = 0
  let sizeInUnit = size

  while (sizeInUnit >= 1024 && unitIndex < units.length - 1) {
    sizeInUnit /= 1024
    unitIndex++
  }

  // Sizes smaller than 100 are displayed with two decimal places
  let formattedValue = sizeInUnit < 100 ? sizeInUnit.toFixed(2) : sizeInUnit.toFixed(0)

  // Remove decimal places if are zeros
  if (formattedValue.endsWith('.00')) {
    formattedValue = formattedValue.slice(0, -3)
  }

  return formattedValue + THINSP + units[unitIndex]
}

export function FileSize({ size = 0 }: { size?: number }) {
  return <span>{formatSize(size)}</span>
}
