import classNames from 'classnames'
import { ReactNode, useEffect } from 'react'

import { ReactComponent as AttentionIcon } from '../../../icons/general/attention.svg'
import { ReactComponent as InfoIcon } from '../../../icons/general/info.svg'
import { ReactComponent as CloseIcon } from '../../../icons/general/nok.svg'
import { Button } from '../../primitives/Button'

type Props = {
  title: string
  body: ReactNode
  severity?: 'info' | 'error'
  timeout?: number
  onClose?: () => void
  actions?: {
    label: string
    onClick: () => void
  }[]
}

export function Toast({ title, body, severity = 'info', timeout, onClose, actions }: Props) {
  useEffect(() => {
    if (timeout) {
      const timer = setTimeout(() => {
        onClose?.()
      }, timeout)
      return () => clearTimeout(timer)
    }
  }, [onClose, timeout])

  return (
    <div className="shadow-sm max-w-[24rem] word-break-all break-words text-sm">
      <div
        className={classNames(
          'font-semibold p-2 rounded-t-sm border flex justify-between items-center dark:border-neutral-500 gap-4',
          {
            'dark:bg-neutral-400 bg-neutral-100 border-neutral-200': severity === 'info',
            'bg-error-bg text-error-text border-error-border': severity === 'error'
          }
        )}
      >
        <div
          className={classNames('flex items-center gap-2', {
            'icon-error': severity === 'error'
          })}
        >
          {severity === 'info' && <InfoIcon className="w-5 h-5" />}
          {severity === 'error' && <AttentionIcon className="w-6 h-6" />}
          {title}
        </div>
        <div onClick={onClose} className="cursor-pointer icon-faded">
          <CloseIcon />
        </div>
      </div>
      <div
        className={classNames('p-2 rounded-b-sm border border-t-0 dark:border-neutral-500 relative', {
          'bg-neutral-100 border-neutral-200 dark:bg-neutral-400': severity === 'info',
          'bg-error-bg-soft text-error-text border-error-border': severity === 'error'
        })}
      >
        {typeof body === 'string' ? (
          <div dangerouslySetInnerHTML={{ __html: getSimpleHtml(body) }} />
        ) : (
          <div>{body}</div>
        )}
        {actions && (
          <div className="flex justify-end gap-2 mt-2">
            {actions.map((action) => (
              <Button key={action.label} onClick={action.onClick} fullWidth>
                {action.label}
              </Button>
            ))}
          </div>
        )}
        {timeout && (
          <div className="h-1 w-full overflow-hidden absolute bottom-0 left-0">
            <div
              className="h-full bg-neutral-200 origin-left"
              style={{
                animation: `${timeout}ms linear forwards progress-shrink`
              }}
            />
          </div>
        )}
      </div>
    </div>
  )
}

const getSimpleHtml = (text: string) => {
  return text.replace(/\*\*(.*?)\*\*/g, '<b>$1</b>').replace(/\*(.*?)\*/g, '<i>$1</i>')
}
