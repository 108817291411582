type Props = {
  statusCode: number
  statusMessage: string
}

export function SimpleError(props: Props) {
  return (
    <div className="flex justify-center items-center mt-5">
      <h1 className="mr-3 pr-3 align-top border-r inline-block content-center">{props.statusCode}</h1>
      <div className="inline-block align-middle">
        <h2 className="font-normal lead">{props.statusMessage}</h2>
      </div>
    </div>
  )
}
