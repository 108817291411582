import classNames from 'classnames'
import { AriaRole, ButtonHTMLAttributes, LegacyRef, MouseEventHandler, ReactNode } from 'react'

export type CommonButtonProps<T> = ButtonHTMLAttributes<T> & {
  className?: string
  children: ReactNode
  type?: 'button' | 'submit' | 'reset'
  role?: AriaRole
  tabIndex?: number
}

type Props = CommonButtonProps<HTMLButtonElement> & {
  expand?: boolean
  disabled?: boolean
  className?: string
  onClick?: MouseEventHandler<HTMLButtonElement>
  ref?: LegacyRef<HTMLButtonElement>
}

export function PlainButton({ children, expand, disabled, className, onClick, type = 'button', ...props }: Props) {
  return (
    <button
      type={type}
      disabled={disabled}
      onClick={onClick}
      ref={props.ref}
      role={props.role}
      tabIndex={props.tabIndex}
      className={classNames(
        'bg-inherit border-none whitespace-nowrap text-center content-center inline-flex items-center',
        { 'w-full': expand, 'cursor-not-allowed': disabled },
        className
      )}
      {...props}
    >
      {children}
    </button>
  )
}
