/* eslint-disable @typescript-eslint/no-empty-object-type */
// TODO remove this file
export enum HttpMethod {
  GET = 'GET',
  POST = 'POST',
  PUT = 'PUT',
  PATCH = 'PATCH',
  DELETE = 'DELETE'
}

export type CancelablePromise<T> = Promise<T> & {
  cancel: () => void
}

export type ExtraHeaders = { [key: string]: string | number }

export type RequestFactory = {
  makeRequest: <TReponse, TBody extends {}>(
    method: RequestInit['method'],
    url: string,
    body?: TBody
  ) => CancelablePromise<TReponse>
  get: <TReponse>(url: string) => CancelablePromise<TReponse>
  delete: <TReponse, TBody extends {} = {}>(url: string, body?: TBody) => CancelablePromise<TReponse>
  post: <TReponse, TBody extends {} = {}>(
    url: string,
    body?: TBody,
    headers?: ExtraHeaders
  ) => CancelablePromise<TReponse>
  put: <TReponse, TBody extends {} = {}>(url: string, body?: TBody) => CancelablePromise<TReponse>
  patch: <TReponse, TBody extends {} = {}>(url: string, body?: TBody) => CancelablePromise<TReponse>
}

export type NoContent204 = void
export type Created201 = void
