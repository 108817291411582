import { queryKey, REFETCH_PRINTER_CLOUD_FILES, REFETCH_TRANSFERS } from '@prusaconnect/api'
import { useQuery } from '@tanstack/react-query'

import { useApiClient } from '../../../../api/react'
import { useUploadContext } from '../../../../context/uploadContext'

export function useAllCloudFiles(teamId: number) {
  const api = useApiClient()

  return useQuery({
    queryKey: queryKey(['team', teamId, 'files']),
    queryFn: () => {
      return api.app.files.getFiles(teamId)
    },
    refetchInterval: REFETCH_PRINTER_CLOUD_FILES,
    placeholderData: (previousData) => previousData
  })
}

export function useStorageQuota(teamId: number) {
  const api = useApiClient()

  const { refetch, data } = useQuery({
    queryKey: queryKey(['team', teamId, 'files', 'quota']),
    queryFn: () => {
      return api.app.files.getFiles(teamId)
    },
    enabled: false,
    placeholderData: (previousData) => previousData
  })

  const { capacity = 0, total = 0 } = data || {}
  const free = capacity - total

  return {
    total: capacity,
    free,
    refetch
  }
}

export function useConnectUploads(teamId: number, uniqueContextId: string) {
  const api = useApiClient()

  const { state } = useUploadContext(uniqueContextId)

  return useQuery({
    queryKey: queryKey(['team', teamId, 'files', 'uploads']),
    queryFn: () => api.app.transfers.getOngoingUploads(teamId),
    placeholderData: (previousData) => previousData,
    refetchInterval: (query) => {
      return Object.keys(state || {}).length || query.state.data?.uploads.length ? 1000 : REFETCH_TRANSFERS
    }
  })
}
