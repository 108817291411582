import { useConfig } from '@prusaconnect/api'
import { useLocation } from 'react-router'

import { isSlicerAvailable } from '../slicer/hooks/useSlicer'

function replaceHost(urlString: string) {
  const url = new URL(urlString)
  return `${window.location.origin}${url.pathname}${url.search}`
}

function getRedirectURI(backendRedirectUrl: string) {
  const redirectURI = new URL(replaceHost(backendRedirectUrl))
  return redirectURI
}

export function usePrusaAuthUrl() {
  const config = useConfig()
  const isInSlicer = isSlicerAvailable()

  if (!config || isInSlicer) {
    return null
  }

  const { auth } = config

  if (!auth.server_url) return

  const url = new URL(`${auth.server_url}/o/authorize/`)
  if (auth.client_id) {
    url.searchParams.append('client_id', auth.client_id)
  }
  url.searchParams.append('response_type', 'code')
  url.searchParams.append('scope', 'basic_info')
  if (auth.redirect_url) {
    url.searchParams.append('redirect_uri', getRedirectURI(auth.redirect_url).href)
  }

  return url
}

export function usePrusaAuthEditUrl() {
  const config = useConfig()
  const location = useLocation()
  if (!config) {
    return ''
  }
  const { auth } = config
  const isWelcomePage = location.pathname.match(/^\/welcome/)
  const updatedLocation = isWelcomePage ? '/' : location.pathname

  return `${auth.server_url}/profile-edit/?next=${window.location.origin}${updatedLocation}`
}
