import { PlainButton } from '@prusaconnect/ui'
import { Link as RouterLink } from 'react-router-dom'
import styled from 'styled-components'

import { textEllipsis } from '../helpers/styled'
import { Title } from '../jobs/JobTitle'
import { Label as ParamsLabel, Value as ParamsValue } from '../printers/overview/styled'

export const Left = styled.div`
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
`

export const Right = styled.div`
  flex: 1;
  min-width: 0;
  display: flex;
  flex-direction: column;
`

export const RightParams = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
  margin-top: 1rem;
`

export const Params = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
  margin-top: 1rem;
  flex-wrap: wrap;
  ${textEllipsis};
`

export const Param = styled.div`
  min-width: 0;
`

export const Label = styled(ParamsLabel)`
  display: block;
  :first-letter {
    text-transform: uppercase;
  }
`

export const Value = styled(ParamsValue)`
  display: block;
  ${textEllipsis};
`

export const PrinterTitle = styled(Title)`
  font-size: 1.5rem;
`

export const RowLink = styled(RouterLink)`
  display: flex;
  gap: 0;
  min-width: 0;
  height: 100%;
  padding: 0.625rem;

  color: var(--color-text);
  &:hover {
    color: var(--color-text);
  }
  &:hover ${PrinterTitle} {
    color: var(--color-primary) !important;
  }

  border-left: 1px solid var(--border-secondary);
  &:first-child {
    border-left: none;
  }
`

export const Menu = styled.div`
  background: var(--background-secondary);
  border: 1px solid var(--border-secondary);
  color: #212529;
  padding: 1rem 3rem 0.5rem 1rem;
  text-align: left;
  z-index: 1000;
`

export const PrinterGrid = styled.div<{ $gridTemplateColumns: string }>`
  display: grid;
  grid-template-columns: ${({ $gridTemplateColumns }) => $gridTemplateColumns};
`

export const ColImg = styled.div`
  min-width: 7.5rem;
  max-width: 11.25rem;
  flex-shrink: 0;
  flex-grow: 1;
  div {
    padding: 1rem;
    img {
      border-radius: 3px;
      background-color: rgba(250, 104, 49, 0.2);
    }
  }

  img {
    width: 100%;
  }
`

export const CloseButton = styled.button`
  position: absolute;
  right: 1.125rem;
`

export const ToggleButton = styled(PlainButton)`
  height: 100%;
  display: block;
`

export const Footer = styled.div`
  margin-top: 0.5em;
  padding-top: 0.5em;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  border-top: 1px solid var(--border-secondary);
  justify-content: center;
`

export const FooterTitle = styled.span`
  display: block;
  text-transform: uppercase;
  font-weight: 700;
  padding: 0 0.5em;
  font-size: 0.75rem;
  color: var(--color-text);
`
