import * as Sentry from '@sentry/react'
import { useTranslation } from 'react-i18next'

import { tryParse } from '../helpers/tryParse'
import { useErrorHandler } from '../hooks/errors/useErrorHandler'
import { ServiceException, ServiceHttpException } from '../interfaces/exception'
import { useToast } from '../store/toastStore'

const useServiceResponseHandler = () => {
  const { t } = useTranslation()
  const errorHandler = useErrorHandler()
  const toast = useToast()

  const displayError = (error: Error) => {
    try {
      const title = t('service.error', { name: error.name || '' })
      const message = error.message || ''
      toast.add(title, message, { severity: 'error' })
    } catch {
      toast.add(t('service.error.unknown'), '', { severity: 'error' })
    }

    console.error(error)
  }

  const displayHttpError = (response: Response, payload?: any) => {
    const { title, message } = errorHandler(response, payload, false)

    toast.add(title, message, { severity: 'error' })
  }

  const displaySuccess = (title: string, body: string) => {
    toast.add(title, body)
  }

  const handleError = (
    url: string,
    request: RequestInit,
    response: Response | undefined,
    error: Error,
    display: boolean
  ) => {
    if (display) displayError(error)
    reportError(url, request, response, error)
  }

  const handleHttpError = (url: string, request: RequestInit, response: Response, payload: any, display: boolean) => {
    if (display) displayHttpError(response, payload)
    reportHttpError(url, request, response, payload)
  }

  const reportError = (url: string, request: RequestInit, response: Response | undefined, error: Error) => {
    const exception = new ServiceException({
      error,
      request: {
        body: tryParse(request.body) || undefined,
        headers: request.headers,
        keepalive: request.keepalive,
        method: request.method,
        mode: request.mode
      },
      response: response
        ? {
            headers: response.headers,
            ok: response.ok,
            redirected: response.redirected,
            status: response.status,
            type: response.type,
            url: response.url
          }
        : undefined,
      url
    })

    Sentry.captureException(exception)
  }

  const reportHttpError = (url: string, request: RequestInit, response: Response, payload?: any) => {
    const exception = new ServiceHttpException({
      request: {
        body: tryParse(request.body) || undefined,
        headers: request.headers,
        keepalive: request.keepalive,
        method: request.method,
        mode: request.mode
      },
      response: {
        headers: response.headers,
        ok: response.ok,
        redirected: response.redirected,
        status: response.status,
        type: response.type,
        url: response.url
      },
      url,
      payload
    })

    Sentry.captureException(exception)
  }

  return {
    displayError,
    displayHttpError,
    displaySuccess,
    handleError,
    handleHttpError
  }
}

export default useServiceResponseHandler
