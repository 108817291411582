import { Link } from 'react-router-dom'
import styled, { css } from 'styled-components'

import { textEllipsis } from '../../helpers/styled'

const DAY_HEIGHT = 40
const DAY_MARGIN = 6
const DAY_HALVES = 24 * 2
export const PRINTER_NAME_WIDTH = 200
export const SCROLL_OFFSET = 100 // pixels shown before current time line

const HALF_HOUR_WIDTH = 90
export const MINUTE_WIDTH = HALF_HOUR_WIDTH / 30

export const gridColumnStyles = css`
  display: grid;
  grid-template-columns: ${PRINTER_NAME_WIDTH}px auto 1fr;
`

export const ColumnTitleContainer = styled.div`
  ${gridColumnStyles}
  margin-top: 1rem;
  padding-left: 1rem;
`

export const ColumnTitle = styled.div`
  font-weight: 400;
  font-size: 0.75rem;
`

export const QueueContainer = styled.div`
  ${gridColumnStyles}
  position: relative;
  margin-bottom: 1rem;
  border-top: 1px solid #d5d5d5;
  cursor: pointer;
  overflow: auto;

  &:hover {
    cursor: grab;
  }

  &:active {
    cursor: grabbing;
  }
`

export const TimeWrapper = styled.div`
  position: relative;
  border-bottom: 1px solid #d5d5d5;
  margin-bottom: ${DAY_MARGIN}px;

  &::before {
    display: block;
    position: absolute;
    content: '';
    top: 100%;
    left: -20px;
    width: 20px;
    height: 1px;
    background: #d5d5d5;
  }
`

export const TimeHeader = styled.div`
  width: ${HALF_HOUR_WIDTH}px;
  font-size: 11px;
  text-align: left;
`

export const TimeMark = styled.div`
  width: ${HALF_HOUR_WIDTH}px;
  border-left: 1px solid #707070;
  height: 9px;
`

export const DayLine = styled.div`
  position: absolute;
  height: 1px;
  width: 100%;
  background: var(--queue-view-border);
  bottom: ${DAY_HEIGHT + DAY_MARGIN}px;
  left: 0;
  right: 0;

  &::before {
    display: block;
    position: absolute;
    content: '';
    left: -20px;
    width: 20px;
    height: 1px;
    background: var(--queue-view-border);
  }
`

export const Day = styled.div<{ $isFirstPrinter: boolean }>`
  position: relative;
  height: ${DAY_HEIGHT}px;
  width: ${DAY_HALVES * HALF_HOUR_WIDTH}px;
  margin: ${DAY_MARGIN}px 0;

  ${({ $isFirstPrinter }) =>
    $isFirstPrinter &&
    css`
      margin: 0;
    `}
`

export const CurrentTimeLine = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  border-left: 1px solid red;
  transition: all 500ms ease-out;
`

export const Gradient = styled.div<{ $visible?: boolean }>`
  width: 20px;
  position: sticky;
  left: 200px;
  z-index: 1;
  height: 100%;
  ${(props) =>
    props.$visible /* background-image doesnt work with transparent bg */ &&
    css`
      width: 5px;
      background: #bbbbbb;
      mask-image: linear-gradient(-90deg, rgba(255, 255, 255, 0) 0, rgba(255, 255, 255, 1) 100%);
    `}
`

export const PrinterName = styled.div<{ $isFirstPrinter?: boolean }>`
  display: flex;
  align-items: center;
  position: sticky;
  padding-left: 11px;
  left: 0;
  z-index: 2;
  background: var(--background-body);
  outline: 1px solid var(--queue-view-border);

  svg {
    flex-shrink: 0;
  }

  a {
    ${textEllipsis};
    font-weight: bold;
    color: var(--color-text) !important;
    &:hover {
      color: var(--color-primary) !important;
    }
  }

  ${({ $isFirstPrinter }) =>
    $isFirstPrinter &&
    css`
      padding-top: 2rem;
      padding-bottom: 6px;
    `}
`

const jobStyles = css`
  height: 100%;
  position: absolute;
  display: flex;
  align-items: center;
  color: var(--color-text);
  overflow: hidden;
`

export const JobLink = styled(Link)`
  ${jobStyles}
`

export const Job = styled.div`
  ${jobStyles}
`

export const JobContent = styled.div<{ $active?: boolean; $isPrintAllowed?: boolean }>`
  font-size: 0.9rem;
  font-weight: 500;
  line-height: ${DAY_HEIGHT}px;
  flex: 1;
  height: 100%;
  padding: 0 0.25rem;
  ${textEllipsis};

  ${({ $active, $isPrintAllowed }) =>
    $active
      ? css`
          background: #7da7d97f;
        `
      : $isPrintAllowed
        ? css`
            background: #a3d39c7f;
          `
        : css`
            background: #e6777d7f;
          `}
`
