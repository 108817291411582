import { useQuery } from '@tanstack/react-query'

import { useApiClient } from '../api/react'

export function useGroups(isEnabled = true) {
  const { app } = useApiClient()

  return useQuery({
    queryKey: ['groups'],
    queryFn: () => app.printerGroups.getGroups({ limit: 1000 }),
    networkMode: 'offlineFirst',
    enabled: isEnabled
  })
}

export function useAfsGroupId() {
  // const groupQuery = useGroups()
  // const firstGroup = groupQuery.data?.groups?.[0]
  // just for FormNext id = 8 :)
  return 8 // firstGroup?.id || 1 // TODO
}
