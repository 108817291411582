import { useEffect, useMemo, useState } from 'react'
import styled from 'styled-components'

import { ReactComponent as AvatarIcon } from '../../svg/avatar.svg'

const SIZE = 35

const AvatarImg = styled.img<{
  size: number
}>`
  border-radius: 50%;
  height: ${({ size }) => `${size}px`};
  width: ${({ size }) => `${size}px`};
  max-width: ${({ size }) => `${size}px`};
`

type Props = {
  avatar?: string
  avatarServerUrl: string
  name: string
  className?: string
  size?: number
}

export function EmptyAvatar({ title, className, size }: { title?: string; className?: string; size?: number }) {
  const sizeInPx = size || SIZE
  return <AvatarIcon className={className} height={sizeInPx} width={sizeInPx} title={title} />
}

export enum ThumbnailSizes {
  NORMAL = '50x50',
  DOUBLE = '100x100'
}

/**
 * Generates webp thumbnail links
 * auth/avatars/e6/e69218ba-623d-471e-8569-538581e99444.jpeg
 *->
 * auth/avatars/e6/thumbs/cover/50x50/jpeg/e69218ba-623d-471e-8569-538581e99444.webp
 */
export function getAvatarThumbnail(serverUrl: string, size: ThumbnailSizes, avatarUrl?: string) {
  if (!avatarUrl) {
    return null
  }

  let prefix
  let filename

  try {
    const parts = avatarUrl.split('/')
    prefix = parts.slice(0, -1).join('/')
    filename = parts.slice(-1)[0]
  } catch (_e) {
    return null
  }

  if (!filename) {
    return null
  }

  const [basename, extension] = filename.split('.')
  if (!extension) {
    return null
  }

  return `${serverUrl}${prefix}/thumbs/cover/${size}/${extension}/${basename}.webp`
}

export function Avatar(props: Props) {
  const { avatarServerUrl, avatar, size } = props
  const [isLoaded, setIsLoaded] = useState(false)
  const [isError, setIsError] = useState(false)

  const picture = useMemo(() => {
    const src = getAvatarThumbnail(avatarServerUrl, ThumbnailSizes.NORMAL, avatar)
    const src2x = getAvatarThumbnail(avatarServerUrl, ThumbnailSizes.DOUBLE, avatar)

    return src && src2x
      ? {
          srcSet: `${src} 1x, ${src2x} 2x`,
          src
        }
      : null
  }, [avatarServerUrl, avatar])

  useEffect(() => {
    setIsLoaded(false)

    if (!picture) {
      setIsError(true)
    } else {
      const img = new Image()
      img.src = picture.src
      img.onerror = () => {
        setIsError(true)
      }
      img.onload = () => {
        setIsLoaded(true)
        setIsError(false)
      }
    }
  }, [picture])

  if (isError || !isLoaded || !picture) {
    return <EmptyAvatar size={size} className={props.className} />
  }

  return (
    <picture className={props.className}>
      <source srcSet={picture.srcSet} />
      <AvatarImg loading="lazy" src={picture.src} alt={props.name} size={size || SIZE} />
    </picture>
  )
}
