import { IPager, IPagerQuery } from './pager'

export enum Material {
  ABS = 'ABS',
  ASA = 'ASA',
  BVOH = 'BVOH',
  CPE = 'CPE',
  FLEX = 'FLEX',
  HIPS = 'HIPS',
  PC = 'PC',
  PP = 'PP',
  PLA = 'PLA',
  PETG = 'PETG',
  PVA = 'PVA',
  PVB = 'PVB',
  XT = 'XT',
  PA = 'PA',
  EDGE = 'EDGE',
  METAL = 'METAL',
  NGEN = 'NGEN',
  CUSTOM = 'CUSTOM'
}

export type IFilamentColor = {
  name: string
  hex: string
}

export type IFilament = {
  team_id: number
  manufacturer: string
  material: Material
  bed_temperature: number
  nozzle_temperature: number
  /** in mg/cm3 so we don't have to deal with floats */
  density: number
  spool_weight?: number
}

export type ICreatedFilament = IFilament & {
  id: number
  default: boolean
}

export type IFilamentsRequestQuery = IPagerQuery

export type IFilamentsResponse = {
  filaments: ICreatedFilament[]
  pager?: IPager
}
