import { useMutation } from '@tanstack/react-query'
import { useTranslation } from 'react-i18next'

import { useApiClient } from '../../api/react'
import { IKwargs, IQueueCommandResponse } from '../../api/types/commandQueue'
import { IFinishedEvent } from '../../api/types/event'
import { PrinterUuid } from '../../api/types/printer'
import { IConnectState } from '../../api/types/state'
import { printerInState } from '../../components/common/PrinterStateTag'
import { usePermissions } from '../../context/permissionsStore'
import { Icon } from '../../icons'
import { useToast } from '../../store/toastStore'
import { useErrorHandler } from '../errors/useErrorHandler'
import { useIsAdmin } from '../useLoggedUser'
import { useSupportedCommandByUuid } from './useSupportedCommands'

type Options = {
  icon?: Icon
  label?: string
  command: string
  successMessage?: {
    title: string
    body: string
  }
  permissions?: {
    read?: boolean
    write?: boolean
    use?: boolean
  }
  mutationKey?: string[]
  cacheTime?: number
}

export function useCommandMutation(
  printer: Readonly<{ uuid: PrinterUuid; connect_state: IConnectState; team_id: number }>,
  options: Options,
  onSuccess?: (response?: { command: IQueueCommandResponse; event: IFinishedEvent }) => void,
  onError?: (e: any) => void
) {
  const { uuid, team_id } = printer
  const toast = useToast()
  const permissions = usePermissions(team_id)
  const isAdmin = useIsAdmin()
  const { t } = useTranslation()

  const { executable, supported } = useSupportedCommandByUuid(options.command, printer)

  const getIsAvailable = () => {
    if (!supported) {
      return { isAvailable: false, reason: t('printer.command.unsupported', 'Unsupported functionality') }
    }
    if (!executable) {
      return { isAvailable: false, reason: printerInState(t, printer.connect_state) }
    }

    // No permissions specified -> allow
    if (!options.permissions) {
      return { isAvailable: true }
    }

    // Check for permission
    const read = options.permissions.read ? permissions.canRead : true
    const write = options.permissions.write ? permissions.canWrite || isAdmin : true
    const use = options.permissions.use ? permissions.canUse : true

    return {
      isAvailable: read && write && use,
      reason: t('printer.command.insufficient-permissions', `You don't have sufficient permissions`)
    }
  }

  const { isAvailable, reason } = getIsAvailable()
  const api = useApiClient()
  const errorHandler = useErrorHandler()

  const {
    mutate: execute,
    mutateAsync: executeAsync,
    isPending,
    isSuccess
  } = useMutation({
    mutationFn: (kwargs?: IKwargs) => {
      if (isAvailable) {
        return api.app.commandQueue.addCommandSync(uuid, {
          command: options.command,
          kwargs
        })
      }

      return Promise.resolve(undefined)
    },

    onSuccess: (response?: { command: IQueueCommandResponse; event: IFinishedEvent }) => {
      if (options.successMessage) {
        const { title, body } = options.successMessage
        toast.add(title, body)
      }
      onSuccess?.(response)
    },

    onError: (error: any) => {
      onError?.(error)
      errorHandler(error.response, error)
    },

    ...(options.mutationKey ? { mutationKey: options.mutationKey } : []),
    gcTime: options.cacheTime
  })

  return {
    icon: options.icon,
    label: options.label,
    isAvailable,
    isAvailableReason: reason,
    execute,
    executeAsync,
    isSuccess,
    isPending,
    commandName: options.command
  }
}
