import { ReactNode } from 'react'
import { v4 as uuid } from 'uuid'
import { create } from 'zustand'

export type Toast = {
  toastId?: string
  title: string
  body?: ReactNode
  severity?: 'info' | 'error'
  timeout?: number
  onClose?: () => void
  actions?: {
    label: string
    onClick: () => void
  }[]
}

type ToastStore = {
  toasts: Toast[]
  add: (title: string, body: ReactNode, props?: Omit<Toast, 'title' | 'body'>) => void
  remove: (toastId: string) => void
}

const useToastStore = create<ToastStore>((set) => ({
  toasts: [],
  add: (title, body, props) =>
    set((state) => ({
      toasts: [
        ...state.toasts,
        {
          ...props,
          title,
          body,
          toastId: uuid()
        }
      ]
    })),
  remove: (toastId) =>
    set((state) => ({
      toasts: state.toasts.filter((toast) => toast.toastId !== toastId)
    }))
}))

export function useToast() {
  const add = useToastStore((state) => state.add)
  const remove = useToastStore((state) => state.remove)
  return { add, remove }
}

export function useToastList() {
  return useToastStore((state) => state.toasts)
}
