import { Material, PrinterModel } from '@prusaconnect/api'

import { ColorMode } from '../context/ThemeModeContext'
import { EventTypes, SlicerEvent } from './types'

/**
 * Abstraction wrapper for custom event triggering
 * @param eventName
 * @param data
 * @private
 */
function sendMessage<T>(eventName: EventTypes, data: T) {
  const customEvent: SlicerEvent<T> = new CustomEvent(eventName, {
    detail: {
      apiVersion: 1,
      data
    }
  })
  window.dispatchEvent(customEvent)
}

/**
 * Set access token so the app can authenticate with the backend
 * @deprecated use init method instead
 * @param token - access token
 */
export function setAccessToken(token: string) {
  sendMessage(EventTypes.CREATE_ACCESS_TOKEN, token)
}

/**
 * Legacy method for setting session id as an authentication method
 * @deprecated use init method instead
 * @param sessionId - session id string
 */
export function setSessionId(sessionId: string) {
  sendMessage(EventTypes.CREATE_SESSION_ID, sessionId)
}

export type ConnectLanguage = 'en' | 'cs' | 'de' | 'es' | 'fr' | 'it' | 'pl'

export type InitOptions = {
  accessToken?: string
  sessionId?: string
  language?: ConnectLanguage
  clientVersion?: string
  colorMode?: ColorMode
}

/**
 * Set language to cs/de/es/fr/it/pl or en if not supported
 * Slicer input language is in format cs_CZ
 */
function slicerLanguageToConnectLanguage(input: string): ConnectLanguage {
  // Catalan spanish is spanish spanish for connect
  if (input === 'ca_ES') return 'es'
  const languagePart = input.split('_')[0]

  if (languagePart && ['cs', 'de', 'es', 'fr', 'it', 'pl'].includes(languagePart)) {
    return languagePart as ConnectLanguage
  }

  return 'en'
}

/**
 * Initialize Connect using the provided options
 * @param options
 */
export function init(options: InitOptions) {
  if (options.sessionId) {
    document.cookie = `SESSID=${options.sessionId}; path=/`
  }

  if (options?.language) {
    options.language = slicerLanguageToConnectLanguage(options.language)
  }

  sendMessage(EventTypes.INIT, options)
}

export type CompatiblePrinterParams = {
  /**
   * Unique Printer ID if we want to match a specific printer
   */
  printerUuid?: string

  /**
   * User can rename the file
   * */
  filename?: string

  /**
   * Printer type in dot notation, e.g. `1.3.1`
   * */
  printerType?: string

  /**
   * Printer model name, e.g. `MK4S, MK3, MINI, XL` etc.
   */
  printerModel?: PrinterModel

  /**
   * Material type. Allowed formats are the following:
   * - Single material name as a string, e.g. `PLA`
   * - Array of material names per tool, e.g. `['PLA', 'PETG']` (Available as of PrusaSlicer 2.8.1)
   * - Multiple materials as a semicolon-delimited strings per tool, e.g. `PLA;PETG`
   */
  material?: string | Material[]

  /**
   * Diameter of the nozzle in millimeters, e.g. `0.4`
   */
  nozzleDiameter?: number

  /**
   * Whether the printer's nozzle is capable of printing abrasive materials.
   * Boolean array per tool, e.g. `[true, false]`
   * Available as of PrusaSlicer 2.8.1
   */
  filament_abrasive?: boolean[]

  /**
   * The printer needs a high-flow nozzle installed
   * Boolean array per tool, e.g. `[true, false]`
   * Available as of PrusaSlicer 2.8.1
   */
  high_flow?: boolean[]

  /**
   * Array of nozzle diameters per tool
   * Available as of PrusaSlicer 2.8.1
   */
  nozzle_diameter?: number[]
}

/**
 * Request compatible printer based on the provided parameters
 * @param printerParams
 */
export function requestCompatiblePrinter(printerParams: CompatiblePrinterParams) {
  sendMessage(EventTypes.REQUEST_COMPATIBLE_PRINTER, printerParams)
}

/**
 * Workaround for PrusaSlicer 2.8.1-specific bug where the `filament_abrasive` and `high_flow` keys are flipped
 * @deprecated use requestCompatiblePrinter instead
 * @param printerParams
 */
export function requestCompatiblePrinterLegacy(printerParams: CompatiblePrinterParams) {
  const swappedPrinterParams = { ...printerParams }
  if (printerParams.filament_abrasive !== undefined) {
    swappedPrinterParams.high_flow = printerParams.filament_abrasive
  }

  if (printerParams.high_flow !== undefined) {
    swappedPrinterParams.filament_abrasive = printerParams.high_flow
  }

  sendMessage(EventTypes.REQUEST_COMPATIBLE_PRINTER, swappedPrinterParams)
}

/**
 * Flush all credentials and config (does not remove cached data)
 */
export function logout() {
  document.cookie = 'SESSID=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;'
  sendMessage(EventTypes.LOGOUT, null)
}

/**
 * Global API interface available in the `window` object of the webview
 */
export type ConnectPublicAPI = {
  _prusaConnect_v1: {
    setAccessToken: typeof setAccessToken
    setSessionId: typeof setSessionId
    init: typeof init
    requestCompatiblePrinter: typeof requestCompatiblePrinterLegacy
    logout: typeof logout
  }
  _prusaConnect_v2: {
    init: typeof init
    requestCompatiblePrinter: typeof requestCompatiblePrinter
    logout: typeof logout
  }
}

declare global {
  // eslint-disable-next-line @typescript-eslint/consistent-type-definitions
  interface Window {
    _prusaConnect_v1: ConnectPublicAPI['_prusaConnect_v1']
    _prusaConnect_v2: ConnectPublicAPI['_prusaConnect_v2']
  }
}

window._prusaConnect_v1 = {
  setAccessToken,
  setSessionId,
  init,
  requestCompatiblePrinter: requestCompatiblePrinterLegacy,
  logout
}

window._prusaConnect_v2 = {
  init,
  requestCompatiblePrinter,
  logout
}
