import { createContext, ReactNode, useContext, useEffect } from 'react'
import { Outlet } from 'react-router-dom'

import { IPrinter, IPrinterSimpleView } from './api/types/printer'
import { FrontendVersionWatchdog } from './components/helpers/FrontendVersionWatchdog'
import { TwoFactorAuthDialog } from './components/login/TwoFactorAuthDialog'
import { StoppedPrintReasonsDialog } from './components/printers/actions/StoppedPrintReasonsDialog'
import { TermsAndConditionsDialog } from './components/TermsAndConditionsDialog'
import { AuthStateContextProvider, useAuthState } from './context/authStore'
import { DialogProvider } from './context/DialogContext'
import { DownloadContextProvider } from './context/downloadContext'
import { ThemeModeProvider } from './context/ThemeModeContext'
import { UploadContextProvider } from './context/uploadContext'
import { UserPreferencesProvider } from './context/UserPreferencesProvider'
import { useDownloadingFirmware, useFlashFirmware, useUploadedFirmwareToFlash } from './hooks/useFlashFirmware'
import { useRedirectUrl } from './hooks/useRedirectUrl'
import { useLoginPing } from './services/useLoginPing'
import { isSlicerAvailable } from './slicer/hooks/useSlicer'

const UserResolverContext = createContext<{ refetchUserData: () => void }>({
  refetchUserData: () => undefined
})

export const useUserResolver = () => useContext(UserResolverContext)

function UserResolver({ children }: { children: ReactNode }) {
  /**
   * Enables login ping & pauses rendering until the very first request is resolved
   */
  const { refetch } = useLoginPing()
  const { isAuthenticated } = useAuthState()
  const { redirectUrl, removeRedirectUrl } = useRedirectUrl()

  // Delete old token info from older app versions
  localStorage.removeItem('token')

  // Redirect after login to last page
  if (isAuthenticated === true && redirectUrl) {
    removeRedirectUrl()
    // Prevent redirecting to external URLs
    if (redirectUrl.startsWith('/')) {
      // Native navigation, not react router, because the rest of page should not be rendered
      window.location.replace(redirectUrl)
    }
    return
  }

  // Wait until we know if the user is authenticated
  if (isAuthenticated === undefined) {
    return
  }

  return <UserResolverContext.Provider value={{ refetchUserData: refetch }}>{children}</UserResolverContext.Provider>
}

function FirmwareUploader({
  printer,
  hash,
  teamId, // user default team
  stopDispatching
}: {
  printer: IPrinterSimpleView
  hash: string
  teamId: number
  stopDispatching: () => void
}) {
  const installFirmwareToPrinter = useFlashFirmware(printer as IPrinter)

  const { data: getParticularFirmwareByVersion } = useDownloadingFirmware(printer.uuid, hash, Number(teamId))

  useEffect(() => {
    if (getParticularFirmwareByVersion?.path && installFirmwareToPrinter?.isAvailable) {
      installFirmwareToPrinter.execute(
        { path: getParticularFirmwareByVersion.path },
        {
          onSuccess: () => {
            stopDispatching()
          }
        }
      )
    }
  }, [getParticularFirmwareByVersion?.path])

  return null
}

export function Contexts() {
  const { printer, hash, teamId, stopDispatching } = useUploadedFirmwareToFlash()

  const insideSlicer = isSlicerAvailable()

  return (
    <AuthStateContextProvider>
      <UserResolver>
        <ThemeModeProvider>
          <UserPreferencesProvider>
            <UploadContextProvider>
              {/* DialogProvider depends on UploadContextProvider, StartPrintModal have UploadAndPrintFile which depends on UploadContextProvider  */}
              <DialogProvider>
                <FrontendVersionWatchdog />
                <TermsAndConditionsDialog />
                <DownloadContextProvider>
                  <Outlet />
                </DownloadContextProvider>
                {!insideSlicer && <TwoFactorAuthDialog />}
                <StoppedPrintReasonsDialog />
                {printer && hash && teamId && (
                  <FirmwareUploader printer={printer} hash={hash} teamId={teamId} stopDispatching={stopDispatching} />
                )}
              </DialogProvider>
            </UploadContextProvider>
          </UserPreferencesProvider>
        </ThemeModeProvider>
      </UserResolver>
    </AuthStateContextProvider>
  )
}
