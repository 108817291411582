import styled, { css } from 'styled-components'

export const countLabel = css`
  display: block;
  position: absolute;
  top: -0.5rem;
  right: -0.4rem;
  font-size: 0.7rem;
  font-weight: bold;
  color: #fff;
  min-width: 1rem;
  background-color: var(--color-primary);
  border-radius: 0.5rem;
  padding: 0 0.3rem;
  margin: 0;
`

export const NotificationHeaderButton = styled.div`
  display: block;
  position: relative;
  margin-right: 0.5rem;
  margin-top: 5px;

  .notification-count {
    ${countLabel}
  }
`

export const NotificationsContainer = styled.div`
  display: block;
  max-width: 400px;
  max-height: min(calc(60vh - 38px), 300px);
  overflow-y: scroll;
  overflow-x: hidden;

  a {
    color: var(--neutral-900);

    & > div:hover {
      background-color: var(--color-primary-faded);
    }
  }

  .no-more-notifications {
    opacity: 0.8;
    font-size: 0.9rem;
    display: block;
    text-align: center;
    padding: 0.5rem 0;
  }
`

export const NotificationsHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  padding: 0.3rem 0.7rem 0.7rem;
  gap: 1rem;

  h2 {
    font-size: 1rem;
    font-weight: bold;
    margin: 0;
  }

  .button-container {
    display: flex;
    gap: 1rem;
  }

  button {
    background: transparent;
    padding: 0;
    margin: 0;
    border: none;
    color: var(--color-navigation);
    font-size: 0.9rem;

    &.disabled {
      pointer-events: none;
      color: var(--color-light);
    }

    &:hover {
      text-decoration: underline;
    }
  }
`

export const StyledNotification = styled.div<{ read: boolean }>`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0.3rem 0.5rem;
  margin: 0;
  font-size: 0.8rem;
  border-top: 1px solid var(--border-secondary);
  border-left: 0 solid var(--color-primary);
  border-left-width: ${(props) => (!props.read ? '3px' : '0')};
`

export const NotificationIconStyled = styled.div<{ url?: string | undefined }>`
  width: 40px;
  height: 40px;
  flex: 0 0 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-size: cover;
  background-position: center;
  ${(props) =>
    props.url &&
    css`
      background-image: url(${props.url});
      pointer-events: none;
      cursor: not-allowed;
    `}
`
