import { useTranslation } from 'react-i18next'

import { THINSP } from '../../lib/textSymbols'
import { Tooltip } from '../primitives/Tooltip'
import { Distance } from './units/Distance'

type Tool = {
  nozzle_diameter?: number
  high_flow?: boolean
  hardened?: boolean
  active?: boolean
  toolId: number
  activeSlot?: number
}

function NozzleInfo({ tool, order, hasMultipleTools }: { tool: Tool; order: number; hasMultipleTools?: boolean }) {
  const { t } = useTranslation()
  const slotTitle = hasMultipleTools ? t('printer.params.tool', 'Tool') : t('printer.params.slot', 'Slot')
  const separator = `${THINSP}/${THINSP}`
  const nozzleDiameter = tool.nozzle_diameter || 0

  return (
    <div>
      {hasMultipleTools && (
        <span>
          {slotTitle} {order}:{' '}
        </span>
      )}
      <Distance distance={nozzleDiameter} />
      {tool.high_flow && (
        <>
          {separator}
          {t('printer.slot.nozzle.high-flow-abbr', 'HF')}
        </>
      )}
      {tool.hardened && (
        <>
          {separator}
          {t('printer.slot.nozzle.hardened-abbr', 'H')}
        </>
      )}
    </div>
  )
}

export type ToolsField = {
  tools: Tool[]
  hasMultipleTools?: boolean
  isMultiTool?: boolean
  isMmu?: boolean
  diameterFallback?: number
}

export function NozzlesField({ isMultiTool, tools, hasMultipleTools }: ToolsField) {
  if (isMultiTool) {
    const activeOrFirst = tools.find((tool) => tool.active) || tools[0]

    return (
      <Tooltip
        content={tools.map((tool) => (
          <NozzleInfo tool={tool} order={tool.toolId} key={tool.toolId} hasMultipleTools={hasMultipleTools} />
        ))}
      >
        {activeOrFirst && (
          <NozzleInfo tool={activeOrFirst} order={activeOrFirst.toolId} hasMultipleTools={hasMultipleTools} />
        )}
      </Tooltip>
    )
  }

  return <NozzleInfo tool={tools[0]} order={tools[0].activeSlot || 0} />
}
