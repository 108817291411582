import {
  INotificationName,
  isAttentionNotification,
  isFwUpdateNotification,
  isIncidentNotification,
  isInvitationNotification,
  isJobNotification,
  isTestNotification,
  Notification,
  TransferAbortedReasons
} from '@prusaconnect/api'
import { useTranslation } from 'react-i18next'

import { IJobState } from '../../api/types/job'
import { escapeMarkdownCharacters } from '../../helpers/escapeMarkdownCharacters'
import { formatDuration } from '../helpers/time'

function trimFileName(name: string) {
  const NAME_LENGTH = 25
  if (name.length > NAME_LENGTH) {
    return `${name.substring(0, NAME_LENGTH)}${String.fromCharCode(0x2026)}`
  }

  return name
}

function useReasons(): Record<TransferAbortedReasons, string> {
  const { t } = useTranslation()

  return {
    NOT_FOUND_FILE: t('transfer.aborted.reason.file-not-found'),
    NOT_FOUND_STORAGE: t('transfer.aborted.reason.storage-not-found'),
    NOT_FOUND_FILE_IN_PRINTER: t('transfer.aborted.reason.file-not-found-in-printer'),
    INVALID_FILE_PATH: t('transfer.aborted.reason.invalid-file-path'),
    FORBIDDEN_MAX_STORAGE_LIMIT: t('transfer.aborted.reason.forbidden-max-storage-limit'),
    FORBIDDEN_READ_ONLY: t('transfer.aborted.reason.forbidden-readonly'),
    FORBIDDEN_NO_SPACE_ON_PRINTER: t('transfer.aborted.reason.forbidden-no-space-on-printer')
  }
}

export function useNotificationBody(notification: Notification) {
  const { t } = useTranslation()
  const reasons = useReasons()

  if (isTestNotification(notification)) {
    return t(
      'notifications.text.test-notification',
      'Hi, I am your first notification, thank you for using them. You can adjust the sending of notifications according to your wishes.'
    )
  }

  if (isInvitationNotification(notification)) {
    return t('notifications.text.invitation', '**{userName}** has invited you to the **{teamName}** team.', {
      userName: notification.data?.invitor_name,
      teamName: notification.data?.team_name
    })
  }

  const printerName =
    'data' in notification && notification.data?.printer_name !== ''
      ? escapeMarkdownCharacters(notification.data.printer_name || '')
      : ''

  const fileName =
    'data' in notification && 'job_name' in notification.data
      ? escapeMarkdownCharacters(trimFileName(notification.data.job_name || ''))
      : ''

  const firmware = isFwUpdateNotification(notification)
    ? escapeMarkdownCharacters(notification.data.firmware || '')
    : ''
  const prusaLink = isFwUpdateNotification(notification)
    ? escapeMarkdownCharacters(notification.data.prusa_link || '')
    : ''
  // Older notifications with only string filament_change_in value
  const timeString =
    isJobNotification(notification) && notification.name === INotificationName.FILAMENT_CHANGE_IN
      ? escapeMarkdownCharacters(notification.data.filament_change_in || '')
      : ''
  const time =
    isJobNotification(notification) && notification.name === INotificationName.FILAMENT_CHANGE_IN
      ? formatDuration(t, notification.data.filament_change_in_sec || 0, true)
      : 0

  if (
    isJobNotification(notification) &&
    notification.name === INotificationName.JOB_FINISHED &&
    fileName &&
    printerName
  ) {
    if (notification.data?.job_state === IJobState.FIN_STOPPED) {
      return t('notifications.text.job-stopped', '**{printerName}**: file **{fileName}** has stopped printing', {
        printerName,
        fileName
      })
    }

    if (notification.data?.job_state === IJobState.FIN_ERROR) {
      return t('notifications.text.job-error', '**{printerName}**: file **{fileName}** has failed', {
        printerName,
        fileName
      })
    }

    // State is most likely FIN_OK
    return t('notifications.text.job-finished', '**{printerName}**: file **{fileName}** has finished printing', {
      printerName,
      fileName
    })
  }

  if (isJobNotification(notification) && notification.name === INotificationName.PRINTER_PRINTING) {
    return t('notifications.text.job-printing', '**{printerName}**: file **{fileName}** is printing', {
      printerName,
      fileName
    })
  }

  if (notification.name === INotificationName.PRINTER_RESUMED) {
    return t('notifications.text.job-resumed', '**{printerName}**: file **{fileName}** has resumed printing', {
      printerName,
      fileName
    })
  }

  if (notification.name === INotificationName.PRINTER_PAUSED) {
    return t('notifications.text.job-paused', '**{printerName}**: file **{fileName}** is paused', {
      printerName,
      fileName
    })
  }

  if (isAttentionNotification(notification)) {
    return t('notifications.text.printer-attention', '**{printerName}**: the printer needs your attention.', {
      printerName
    })
  }

  if (isIncidentNotification(notification) && notification.name === INotificationName.INCIDENT_OFFLINE) {
    return t(
      'notifications.text.printer-incident-offline',
      `**{printerName}**: The printer has a problem identifying with Connect. If you have replaced the printer's motherboard or Rapsberry PI expansion board, or performed a factory reset on the printer it must be approved. Approve this change here.`,
      { printerName }
    )
  }

  if (isIncidentNotification(notification)) {
    return t(
      'notifications.text.printer-incident-other',
      '**{printerName}**: The printer has a problem identifying with Connect. Your settings need to be updated.',
      { printerName }
    )
  }

  if (notification.name === INotificationName.FILAMENT_CHANGE_IN && fileName && printerName) {
    if (time || timeString) {
      return t(
        'notifications.text.printer-filament-change-in-time',
        '**{printerName}**: Filament needs to be replaced in **{time}** while printing the job **{fileName}**.',
        { printerName, fileName, time: time || timeString }
      )
    }
    return t(
      'notifications.text.printer-filament-change-in-a-while',
      '**{printerName}**: Filament needs to be replaced in a while during printing the job **{fileName}**.',
      { printerName, fileName }
    )
  }

  if (notification.name === INotificationName.FW_UPDATE && printerName && firmware) {
    return t(
      'notifications.text.printer-fw-update',
      `**{printerName}**: Printer's firmware **{firmware}** was updated successfully.`,
      { printerName, firmware }
    )
  }

  if (notification.name === INotificationName.LINK_UPDATE && printerName && prusaLink) {
    return t(
      'notifications.text.printer-link-update',
      `**{printerName}**: Printer's PrusaLink **{prusaLink}** was updated successfully.`,
      { printerName, prusaLink }
    )
  }

  if (notification.name === INotificationName.PRINTER_ERROR && printerName) {
    return t('notifications.text.printer-error', '**{printerName}**: An error occurred in the printer.', {
      printerName
    })
  }

  if (notification.name === INotificationName.TRANSFER_ABORTED && printerName) {
    const reasonKey = notification.data.reason as TransferAbortedReasons
    const reason = reasons[reasonKey] || notification.data.reason

    return t(
      'notifications.text.printer-transfer-aborted.not-found-file',
      '**{printerName}**: Transfer was aborted because of an error: **{reason}**.',
      { printerName, reason }
    )
  }

  return notification.text // Fallback
}
