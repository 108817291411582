export enum TimeFormat {
  HALF = '12h',
  FULL = '24h'
}

export enum StatusBarParam {
  SLOTS = 'slots',
  NOZZLE = 'nozzle_temperature',
  BED = 'bed_temperature',
  MATERIAL = 'material',
  SPEED = 'speed',
  Z_HEIGHT = 'current_z_height',
  // SLA
  COVER_CLOSED = 'cover_closed',
  CURRENT_LAYER = 'current_layer',
  REMAINING_RESIN = 'remaining_resin',
  UV_LED_TEMP = 'uv_led_temperature',
  CPU_TEMP = 'cpu_temperature',
  // HT90
  CHAMBER_TEMP = 'chamber_temperature'
}

export enum PrinterParamEnum {
  NOZZLE = 'nozzle',
  LOCATION = 'location',
  TEAM = 'team',
  MATERIAL = 'material',
  PRINTER_TYPE = 'printer_type',
  ESTIMATED_END = 'estimated_end',
  TIME_REMAINING = 'time_remaining',
  PRINTING_JOB_NAME = 'printing_job_name',
  PRINTING_PROGRESS = 'printing_progress',
  SPEED = 'speed',
  HEATBED = 'heatbed',
  Z_AXIS = 'z_axis',
  PROGRESS = 'progress',
  HEIGHT = 'height',
  PRINTING_TIME = 'printing_time',
  FW = 'firmware'
}

export enum PrintFileParamEnum {
  PRINTER_MODEL = 'printer_model',
  PRINTING_TIME = 'printing_time',
  ESTIMATED_PRINTING_TIME = 'estimated_printing_time',
  MATERIAL = 'material',
  LAYER_HEIGHT = 'layer_height',
  DATE = 'date',
  SIZE = 'size',
  NOZZLE_DIAMETER = 'nozzle_diameter',
  SUPPORT_MATERIAL = 'support_material',
  BRIM_WIDTH = 'brim_width',
  FILL_DENSITY = 'fill_density',
  PRINT_STARTED = 'print_started',
  REMAINING_TIME = 'remaining_time',
  ESTIMATED_END = 'estimated_end',
  FILAMENT_CHANGE_IN = 'filament_change_in',
  EXPOSURE_TIME = 'exposure_time',
  EXPOSURE_TIME_FIRST = 'exposure_time_first',
  CURRENT_LAYER = 'current_layer',
  TOTAL_LAYERS = 'total_layers',
  TOTAL_HEIGHT = 'total_height',
  RESIN_CONSUMED = 'resin_consumed',
  RESIN_REMAINING = 'resin_remaining',
  RESIN_USED = 'resin_used'
}

export enum JobParamEnum {
  PRINTER_MODEL = 'printer_model',
  PRINTING_TIME = 'printing_time',
  MATERIAL = 'material',
  LAYER_HEIGHT = 'layer_height',
  PRINT_END = 'print_end',
  SIZE = 'size',
  NOZZLE_DIAMETER = 'nozzle_diameter',
  SUPPORT_MATERIAL = 'support_material',
  BRIM_WIDTH = 'brim_width',
  FILL_DENSITY = 'fill_density',
  TOTAL_LAYERS = 'total_layers',
  RESIN_USED = 'resin_used',
  TOTAL_HEIGHT = 'total_height'
}

export type ISortableParam = {
  name: PrinterParamEnum | StatusBarParam | PrintFileParamEnum | JobParamEnum
  visible?: boolean
}

export enum ConnectStateEnum {
  PRINTING = 'printing',
  IDLE = 'idle'
}

export enum PrinterFamily {
  FDM = 'fdm',
  SLA = 'sla'
}
