import { useContext } from 'react'

import { InitOptions } from '../publicApi'
import { SlicerConfigContext } from '../SlicerConfigContext'
import { EventTypes } from '../types'

// Extend the global event handlers to include our custom events
declare global {
  // eslint-disable-next-line @typescript-eslint/consistent-type-definitions
  interface GlobalEventHandlersEventMap {
    [EventTypes.CREATE_ACCESS_TOKEN]: CustomEvent<string>
    [EventTypes.CREATE_SESSION_ID]: CustomEvent<string>
    [EventTypes.INIT]: CustomEvent<InitOptions>
  }
}

export function useSlicerConfig() {
  const context = useContext(SlicerConfigContext)
  if (!context) {
    throw new Error('<SlicerConfigProvider> is missing in component tree!')
  }

  return context
}
