import { useAuthState } from '../context/authStore'
import { useBreakpoint } from './useBreakpoint'

export function useInitAppziFeedback() {
  const isSmOrLarge = useBreakpoint('sm')
  const isMobile = !isSmOrLarge
  const { user } = useAuthState()

  if (!isMobile) {
    const script = document.createElement('script')
    document.head.appendChild(script)
    script.src = 'https://w.appzi.io/w.js?token=xlpiB'

    // @ts-ignore
    window.appziSettings = {
      userId: user?.id,
      data: {
        email: user?.email,
        username: user?.public_name,
        language: user?.language,
        first_name: user?.first_name,
        last_name: user?.last_name
      },
      render: {
        type: 'client'
      }
    }
  }
}
