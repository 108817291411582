export const THINSP = '\u2009'

export enum Arrow {
  UP = '↗',
  DOWN = '↘',
  FLAT = '→'
}

export enum TemperatureUnits {
  CELSIUS = '°C',
  FAHRENHEIT = '°F'
}
