import createClient, { type Middleware } from 'openapi-fetch'
import createReactQueryClient from 'openapi-react-query'

import type { paths } from '../schema/schema'
import { ApiError } from './ApiError'

let storedAccessToken: string | undefined
let storedRequestSource: string | undefined
let storedClientVersion: string | undefined

export function injectHeaders(headers: Headers) {
  if (storedAccessToken) headers.set('Authorization', `Bearer ${storedAccessToken}`)
  if (storedRequestSource) headers.set('X-Request-Source', storedRequestSource)
  if (storedClientVersion) headers.set('X-Client-Version', storedClientVersion)
}

const injectHeadersMiddleware: Middleware = {
  async onRequest({ request }) {
    injectHeaders(request.headers)
    return request
  }
}

/** @deprecated - use only for legacy query client */
const handleErrorsMiddleware: Middleware = {
  async onResponse({ response }) {
    if (response.status >= 400) {
      const body = response.headers.get('content-type')?.includes('json')
        ? await response.clone().json()
        : await response.clone().text()
      throw new ApiError(body?.message, body, response)
    }
    return undefined
  }
}

type ApiClientOptions = {
  accessToken?: string
  requestSource?: 'connect' | 'slicer'
  clientVersion?: string
  useSessionIdCookie?: boolean
}

export default function createApiClient(baseUrl: string, options?: ApiClientOptions) {
  if (options?.accessToken) {
    storedAccessToken = options.accessToken
  }

  if (options?.requestSource) {
    storedRequestSource = options.requestSource
  }

  if (options?.clientVersion) {
    storedClientVersion = options.clientVersion
  }

  /** @deprecated */
  const rawClient = createClient<paths>({ baseUrl })

  const client = createClient<paths>({ baseUrl })

  rawClient.use(injectHeadersMiddleware)
  rawClient.use(handleErrorsMiddleware) // rudimentary error handling without types
  client.use(injectHeadersMiddleware)

  return {
    /**
     * Preferred api client - "native" wrapper for react-query with full type safety
     */
    $api: createReactQueryClient(client),
    /**
     * Raw API method - use only when you need to call the API directly for specific use cases
     */
    rawApi: rawClient
  }
}

export function setAccessToken(token: string) {
  storedAccessToken = token
}

export type ApiClient = ReturnType<typeof createApiClient>
