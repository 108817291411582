import { darkTheme, DarkThemeType } from './dark-theme'
import { defaultTheme, DefaultThemeType } from './default-theme'
import { lightTheme, LightThemeType } from './light-theme'

export type IDefaultTheme = {} & DefaultThemeType
type IDarkTheme = DefaultThemeType & DarkThemeType
type ILightTheme = DefaultThemeType & LightThemeType

export const darkConnectTheme: IDarkTheme = {
  ...defaultTheme,
  ...darkTheme
}

export const lightConnectTheme: ILightTheme = {
  ...defaultTheme,
  ...lightTheme
}
