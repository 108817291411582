import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import { ButtonLink } from '../common/ButtonLink'

const AnimatedButton = styled(ButtonLink)`
  // animate background white from left to right
  background: linear-gradient(90deg, var(--color-primary-faded) 50%, transparent 50%);
  background-size: 200% 100%;
  background-position: left bottom;

  // start animation by default
  // keep animation on end
  animation-fill-mode: forwards;
  animation-iteration-count: 1;
  animation: gradient 10s linear;
  @keyframes gradient {
    0% {
      background-position: right bottom;
    }
    100% {
      background-position: left bottom;
    }
  }
`

const isDevelopment = import.meta.env.DEV
export function ErrorButtonHome() {
  const { t } = useTranslation()

  useEffect(() => {
    if (!isDevelopment) {
      const timeout = setTimeout(() => {
        window.location.href = '/'
      }, 10000)

      return () => {
        clearTimeout(timeout)
      }
    }
  }, [])

  return (
    <AnimatedButton to="/" block>
      {t('error.page-404.button')}
    </AnimatedButton>
  )
}
