import { REFETCH_PRINTER_LIST } from '@prusaconnect/api'
import { useState } from 'react'

import { usePrintersService } from '../../../services/usePrintersService'
import { usePrintersOverviewStore } from '../../../store/printersOverview'
import { Container } from '../../bootstrap/Container'
import { PaceProgress } from '../../common/PaceProgress'
import { Pager } from '../../common/Pager'
import { CardItem } from './CardItem'
import { Cards } from './CardItem.styled'

export function CardsView() {
  const [offset, setOffset] = useState(0)
  const [limit, setLimit] = useState(50)
  const { teamId, groupId, states, getSortParamQuery } = usePrintersOverviewStore()

  const queryResult = usePrintersService(
    {
      team_id: teamId,
      group_id: groupId,
      state_include: states,
      sort_by: getSortParamQuery(),
      offset,
      limit
    },
    {
      refetchInterval: REFETCH_PRINTER_LIST,
      placeholderData: (previousData) => previousData
    }
  )
  const { isLoading, data } = queryResult

  return (
    <>
      {isLoading && <PaceProgress />}
      {data && (
        <Cards>
          {data.printers.map((printer, i) => (
            <CardItem key={i} printer={printer} />
          ))}
        </Cards>
      )}

      <Container className="mb-2">
        {data && <Pager pager={data.pager} setOffset={setOffset} setLimit={setLimit} />}
      </Container>
    </>
  )
}
