import { useEffect } from 'react'

declare global {
  // eslint-disable-next-line @typescript-eslint/consistent-type-definitions
  interface Window {
    dataLayer: Record<string, string | number | boolean | undefined>[]
    OnetrustActiveGroups: string[]
  }
}

const hasConsentForGTM = () => {
  // Check for analytics cookies consent
  return window.OnetrustActiveGroups?.includes('C0002')
}

const trackButtonClicks = (event: MouseEvent) => {
  if (!hasConsentForGTM()) return

  const target = event.target as HTMLElement

  const button = target.closest('button')
  if (!button) return

  window.dataLayer = window.dataLayer || []
  window.dataLayer.push({
    event: 'button_click',
    button_text: button.textContent?.trim(),
    button_id: button.id,
    button_class: button.className,
    page_url: window.location.href,
    page_path: window.location.pathname,
    page_title: document.title
  })
}

export const ButtonClickTracker = () => {
  useEffect(() => {
    document.body.addEventListener('click', trackButtonClicks)
    return () => document.body.removeEventListener('click', trackButtonClicks)
  }, [])

  return null
}
