import { PlainButton } from '@prusaconnect/ui'
import styled, { useTheme } from 'styled-components'

import { usePrintersOverviewStore } from '../../store/printersOverview'
import { SvgIcon } from '../common/SvgIcon'
import { GroupFilter } from './filters/GroupFilter'
import { StateFilter } from './filters/StateFilter'
import { TeamFilter } from './filters/TeamFilter'

export const Filter = styled.div`
  min-width: 200px;
  font-size: 0.9rem;
`

export function Filters() {
  const { groupId, teamId, states, resetAll } = usePrintersOverviewStore()
  const theme = useTheme()

  const isResetVisible = !!groupId || !!teamId || (Array.isArray(states) && states.length > 0)

  return (
    <>
      <Filter>
        <StateFilter />
      </Filter>

      <GroupFilter isClearable />

      <TeamFilter isClearable />

      {isResetVisible && (
        <PlainButton className="ml-2 flex items-center" onClick={resetAll}>
          <SvgIcon icon="nokIcon" fill={theme.colors.icons.secondary} size={16} />
        </PlainButton>
      )}
    </>
  )
}
