import { stringify } from 'query-string'
import { createContext, ReactNode, useContext, useReducer, useRef } from 'react'

import { useApiClient } from '../api/react'
import { PrinterUuid } from '../api/types/printer'
import { CancelablePromise } from '../api/types/sdk'

type UploadState = {
  file: File
  uploadedSize: number
  total: number
  done: boolean
}

type FileName = string

type FileUploads = {
  [key: FileName]: UploadState | undefined
}

type PrinterState = {
  [key: PrinterUuid]: FileUploads | undefined
}

type StartUploadParams = {
  uploadId: number
  teamId: number
  file: File
  printerUuid: PrinterUuid
}

type ContextType = {
  state: PrinterState
  startUpload: (params: StartUploadParams) => Promise<unknown>
  remove: (printerUuid: PrinterUuid, uploadId: number) => void
  reset: (printerUuid: PrinterUuid, uploadId: number) => void
}

const UploadContext = createContext<ContextType | undefined>(undefined)

enum UploadActionType {
  START_UPLOAD,
  UPDATE_PROGRESS,
  REMOVE
}

type UploadActions =
  | { type: UploadActionType.START_UPLOAD; printerUuid: PrinterUuid; file: File; uploadId: number }
  | {
      type: UploadActionType.UPDATE_PROGRESS
      printerUuid: PrinterUuid
      file: File
      uploadedSize: number
      total: number
      done: boolean
      uploadId: number
    }
  | { type: UploadActionType.REMOVE; printerUuid: PrinterUuid; uploadId: number }

function uploadReducer(state: PrinterState, action: UploadActions): PrinterState {
  switch (action.type) {
    case UploadActionType.START_UPLOAD: {
      return {
        ...state,
        [action.printerUuid]: {
          ...state[action.printerUuid],
          [action.uploadId]: {
            file: action.file,
            uploadedSize: 0,
            total: 0,
            done: false
          }
        }
      }
    }
    case UploadActionType.UPDATE_PROGRESS: {
      const previous = state[action.printerUuid]?.[action.uploadId]
      if (!previous) {
        return state
      }

      return {
        ...state,
        [action.printerUuid]: {
          ...state[action.printerUuid],
          [action.uploadId]: {
            ...previous,
            uploadedSize: action.uploadedSize,
            total: action.total,
            done: action.done
          }
        }
      }
    }
    case UploadActionType.REMOVE: {
      const newState = { ...state }
      delete newState[action.printerUuid]?.[action.uploadId]
      return newState
    }
  }
}

export function UploadContextProvider({ children }: { children: ReactNode }) {
  const [state, dispatch] = useReducer(uploadReducer, {})
  const api = useApiClient()

  const promises = useRef(new Map<number, CancelablePromise<unknown>>())

  const startUpload = async ({ uploadId, teamId, file, printerUuid }: StartUploadParams) => {
    dispatch({
      type: UploadActionType.START_UPLOAD,
      file,
      uploadId,
      printerUuid
    })

    const query = {
      upload_id: uploadId
    }

    const url = `/app/teams/${teamId}/files/raw?${stringify(query)}`

    const promise = api.app.files.uploadFile(url, file, {
      onProgress: (progressEvent) => {
        dispatch({
          type: UploadActionType.UPDATE_PROGRESS,
          done: false,
          printerUuid,
          file,
          uploadedSize: progressEvent.loaded,
          total: (progressEvent.loaded / progressEvent.total) * 100,
          uploadId
        })
      },
      onSuccess: (uploadedFile) => {
        dispatch({
          type: UploadActionType.UPDATE_PROGRESS,
          done: true,
          printerUuid,
          file,
          uploadedSize: uploadedFile.size,
          total: 100,
          uploadId
        })

        // Clear promise cache
        promises.current.delete(uploadId)
      }
    })

    // Save promise for cancellation
    promises.current.set(uploadId, promise)
    return promise
  }

  const remove = (printerUuid: PrinterUuid, uploadId: number) => {
    dispatch({ type: UploadActionType.REMOVE, printerUuid, uploadId })
    const pendingPromise = promises.current.get(uploadId)

    if (pendingPromise) {
      pendingPromise.cancel()
      promises.current.delete(uploadId)
    }
  }

  const reset = (printerUuid: PrinterUuid, uploadId: number) => {
    dispatch({ type: UploadActionType.REMOVE, printerUuid, uploadId })
  }

  const value = { state, startUpload, remove, reset }

  return <UploadContext.Provider value={value}>{children}</UploadContext.Provider>
}

export function useUploadContext(printerUuid: PrinterUuid) {
  const context = useContext(UploadContext)
  if (!context) {
    throw new Error('useUploadContext must be used within a UploadContextProvider')
  }

  const startUpload = (params: Omit<StartUploadParams, 'printerUuid'>) =>
    context.startUpload({
      ...params,
      printerUuid
    })

  const remove = (uploadId: number) => context.remove(printerUuid, uploadId)
  const reset = (uploadId: number) => context.reset(printerUuid, uploadId)

  return { state: context.state[printerUuid], startUpload, remove, reset }
}
