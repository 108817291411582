import * as RadixDialog from '@radix-ui/react-dialog'
import classNames from 'classnames'
import { Children, ForwardedRef, forwardRef, ReactElement, ReactNode } from 'react'
import { useTranslation } from 'react-i18next'

import { ReactComponent as NokIcon } from '../../../icons/general/nok.svg'
import { Button } from '../../primitives/Button'

type Props = {
  title: ReactNode
  children: ReactNode
  fullScreen?: boolean
  disableClose?: boolean
} & RadixDialog.DialogContentProps

export const DialogContent = forwardRef(function DialogContent(
  { children, title, fullScreen, disableClose, ...props }: Props,
  forwardedRef: ForwardedRef<HTMLDivElement>
) {
  const childrenArray = Children.toArray(children)
  const userProvidedChildren = childrenArray.filter((child) => (child as any)?.type !== DialogButtons)
  const buttons = childrenArray.find((child) => (child as any)?.type === DialogButtons)
  const stickyFooter = ((buttons as ReactElement)?.props as DialogButtonsProps)?.isSticky
  const { t } = useTranslation()

  return (
    <RadixDialog.Portal>
      <RadixDialog.Overlay className="dialog-overlay bg-neutral-400 bg-opacity-80 fixed inset-0 z-[105]" />
      <RadixDialog.Content
        {...props}
        ref={forwardedRef}
        className="fixed w-full h-full top-0 left-0 flex justify-center items-center z-[106]"
        onClick={(e) => e.stopPropagation()}
      >
        <div
          className={classNames(
            'dialog-content relative bg-neutral-100 dark:bg-neutral-200 flex flex-col max-h-full p-6 pb-0 rounded shadow-lg overflow-x-auto',
            fullScreen ? 'w-screen h-screen' : 'max-w-3xl'
          )}
        >
          <RadixDialog.Title>
            <div className="text-xl font-bold mb-3 pr-5">{title}</div>
          </RadixDialog.Title>
          <div className="dialog-body mb-3">{userProvidedChildren}</div>
          <div
            className={classNames(
              'flex justify-end pt-3 pb-6 gap-2',
              stickyFooter &&
                'bg-neutral-100 dark:bg-neutral-200 bg-opacity-95 [@media(min-height:700px)]:sticky bottom-0 border-t border-neutral-200 dark:border-neutral-500',
              fullScreen && 'mt-auto'
            )}
          >
            {buttons || (
              <RadixDialog.Close asChild>
                <Button>{t('close', 'Close')}</Button>
              </RadixDialog.Close>
            )}
          </div>

          {!disableClose && (
            <RadixDialog.Close asChild className="absolute top-5 right-5 hover:cursor-pointer">
              <NokIcon className="w-5 h-5 dark:fill-neutral-900 hover:!fill-primary" />
            </RadixDialog.Close>
          )}
        </div>
      </RadixDialog.Content>
    </RadixDialog.Portal>
  )
})

export const Dialog = RadixDialog.Root
export const DialogTrigger = RadixDialog.Trigger

type DialogButtonsProps = {
  children: ReactNode
  isSticky?: boolean
}
export const DialogButtons = ({ children }: DialogButtonsProps) => children

export const CloseButton = ({ children }: { children: ReactNode }) => (
  <RadixDialog.Close>
    <Button>{children}</Button>
  </RadixDialog.Close>
)
