import { defaultsDeep } from 'lodash-es'
import { createContext, useContext } from 'react'

import { UserPreferences, UserSubmittedPreferences } from '../types/UserPreferences'
import { defaultUserPreferences } from './defaultUserPreferences'

export const UserPreferencesContext = createContext<UserSubmittedPreferences | null>(null)

export function useUserPreferences() {
  const customPreferences = useContext(UserPreferencesContext)

  if (!customPreferences) {
    return defaultUserPreferences
  }

  const mergedPreferences = structuredClone(customPreferences)
  defaultsDeep(mergedPreferences, defaultUserPreferences)
  return mergedPreferences as UserPreferences
}

export default UserPreferencesContext
