import { useQuery } from '@tanstack/react-query'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { useApiClient } from '../../api/react'
import { config } from '../../config'
import { useToast } from '../../store/toastStore'

export function FrontendVersionWatchdog() {
  const { t } = useTranslation()
  const api = useApiClient()
  const version = config.appVersion
  const { data } = useQuery({
    queryKey: ['/version.json'],
    queryFn: () => api.general.version(),
    refetchInterval: 60 * 1000
  })

  // Prevent show more than once
  const [isVisible, setVisible] = useState(false)

  // Prevent show more than once for the same version
  const [lastNotifiedVersion, setLastNotifiedVersion] = useState<string | null>(null)
  const toast = useToast()

  useEffect(() => {
    if (data && data?.version !== version && data?.version !== lastNotifiedVersion) {
      if (!isVisible) {
        toast.add(
          t('version-watchdog.title', 'New Connect version available'),
          t(
            'version-watchdog.message',
            'There has been an update to the Connect app. Please reload the page to receive the best possible experience.'
          ),
          {
            timeout: 0,
            onClose: () => {
              setVisible(false)
            },
            actions: [
              {
                label: t('reload', 'Reload'),
                onClick: () => {
                  window.location.reload()
                }
              }
            ]
          }
        )
        setVisible(true)
      }
      setLastNotifiedVersion(data.version)
    }
  }, [data, data?.version, isVisible, lastNotifiedVersion, t, toast, version])

  return null
}
