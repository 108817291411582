import classNames from 'classnames'

import { useTheme } from '../../context/ThemeContext'
import { getColorScheme, StateColor } from '../../lib/colorScheme'
import { Tooltip, TooltipPosition } from '../primitives/Tooltip'

type Props = {
  label: string
  color: StateColor
  tooltip?: string
  collapsible?: boolean
  side?: TooltipPosition
}

export function StateLabel(props: Props) {
  const { isDarkMode } = useTheme()
  const colorScheme = getColorScheme(props.color, isDarkMode)
  const className = classNames(
    'py-0.5',
    'px-3',
    'truncate',
    'uppercase',
    'text-center',
    'border-l-4',
    'rounded-tr-sm',
    'rounded-br-sm',
    'min-[400px]:min-w-[8rem]',
    'min-[400px]:max-w-[fit-content]',
    { 'max-w-[6rem]': props.collapsible }
  )

  return (
    <Tooltip content={props.tooltip ?? ''} disabled={!props.tooltip} side={props.side}>
      <div
        className={className}
        style={{
          backgroundColor: colorScheme[500].background,
          borderColor: colorScheme[900].background,
          color: colorScheme[500].text
        }}
      >
        {props.label}
      </div>
    </Tooltip>
  )
}
