import { useQuery } from '@tanstack/react-query'
import { useEffect } from 'react'

import { useApiClient } from '../../api/react'
import { PrinterUuid } from '../../api/types/printer'
import { IConnectState } from '../../api/types/state'

export function useSupportedCommandsByUuid(printerUuid: PrinterUuid, refresh = false) {
  const api = useApiClient()
  const { data: supportedCommands, refetch } = useQuery({
    queryKey: [`/supported-commands/${printerUuid}`],
    queryFn: () => api.app.supportedCommands.getSupportedCommandsByUuid(printerUuid),

    // 1 day
    staleTime: 24 * 60 * 60 * 1000
  })

  useEffect(() => {
    if (refresh) {
      refetch()
    }
  }, [refresh, refetch])

  return supportedCommands
}

export function useSupportedCommandByUuid(
  commandName: string,
  printer: Readonly<{ uuid: PrinterUuid; connect_state: IConnectState }>
): { executable: boolean; supported: boolean } {
  const { uuid, connect_state } = printer
  const supportedCommands = useSupportedCommandsByUuid(uuid)

  const command = supportedCommands?.commands.find((command) => command.command === commandName)

  return { executable: !!command?.executable_from_state?.includes(connect_state), supported: !!command }
}
