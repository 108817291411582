import { createBrowserRouter, redirect } from 'react-router-dom'

import { ErrorBoundary } from './components/errors/ErrorBoundary'
import { Contexts } from './Contexts'
import { ErrorPage } from './pages/ErrorPage'
import { GuestOnlyRoute } from './pages/GuestOnlyRoute'
import { Layout } from './pages/Layout'
import { PrivateRoute } from './pages/PrivateRoute'
import { OAuthJWTCallback, RefreshJwtLoader } from './store/jwt'

export const router = createBrowserRouter([
  // Redirects
  {
    path: 'cs',
    loader: () => redirect('/')
  },
  {
    path: 'de',
    loader: () => redirect('/')
  },
  {
    path: 'fr',
    loader: () => redirect('/')
  },
  {
    path: 'it',
    loader: () => redirect('/')
  },
  {
    path: 'pl',
    loader: () => redirect('/')
  },
  {
    path: 'es',
    loader: () => redirect('/')
  },

  {
    // JWT auth login callback
    path: 'login/auth-callback',
    loader: OAuthJWTCallback
  },

  {
    Component: Contexts,
    ErrorBoundary,
    loader: RefreshJwtLoader,
    children: [
      {
        Component: Layout,
        ErrorBoundary,
        children: [
          // Start of the app routes
          {
            Component: PrivateRoute,
            ErrorBoundary,
            children: [
              {
                path: 'teams',
                children: [
                  {
                    index: true,
                    lazy: () => import('./pages/TeamsPage')
                  },
                  {
                    path: ':teamId',
                    lazy: () => import('./pages/TeamDetailPage'),
                    children: [
                      {
                        index: true,
                        loader: () => redirect('members')
                      },
                      {
                        path: 'members',
                        ErrorBoundary,
                        lazy: () => import('./pages/team/Members')
                      },
                      {
                        path: 'printers',
                        ErrorBoundary,
                        lazy: () => import('./pages/team/Printers')
                      },
                      {
                        path: 'settings',
                        ErrorBoundary,
                        lazy: () => import('./pages/team/Settings')
                      }
                    ]
                  }
                ]
              },
              {
                path: 'groups',
                children: [
                  {
                    index: true,
                    lazy: () => import('./pages/GroupsPage')
                  },
                  {
                    path: ':groupId',
                    lazy: () => import('./pages/GroupDetailPage')
                  }
                ]
              },
              {
                path: 'preferences',
                lazy: () => import('./pages/PreferencesPage'),
                children: [
                  {
                    index: true,
                    loader: () => redirect('date-time') // date-time
                  },
                  {
                    path: 'date-time',
                    lazy: () => import('./pages/preferences/DateTimeSettings')
                  },
                  {
                    path: 'units',
                    lazy: () => import('./pages/preferences/Units')
                  },
                  {
                    path: 'status-bar',
                    lazy: () => import('./pages/preferences/StatusBar')
                  },
                  {
                    path: 'dark-mode',
                    lazy: () => import('./pages/preferences/DarkMode')
                  },
                  {
                    path: 'printers',
                    lazy: () => import('./pages/preferences/printers/Layout'),
                    children: [
                      {
                        index: true,
                        loader: () => redirect('table')
                      },
                      {
                        path: 'table',
                        lazy: () => import('./pages/preferences/printers/Table')
                      },
                      {
                        path: 'queue',
                        lazy: () => import('./pages/preferences/printers/Queue')
                      },
                      {
                        path: 'cards',
                        lazy: () => import('./pages/preferences/printers/Cards')
                      }
                    ]
                  },
                  {
                    path: 'files',
                    lazy: () => import('./pages/preferences/PrinterFiles')
                  },
                  {
                    path: 'jobs',
                    lazy: () => import('./pages/preferences/PrinterJobs')
                  }
                ]
              },
              {
                path: 'invitations',
                lazy: () => import('./pages/InvitationsPage')
              },
              ...(AFS_ENABLED
                ? [
                    {
                      path: 'jobs',
                      lazy: () => import('./pages/AfsJobsPage')
                    },
                    {
                      path: 'jobs/:jobId',
                      lazy: () => import('./pages/AfsJobDetailPage')
                    },
                    {
                      path: 'history',
                      lazy: () => import('./pages/AfsHistoryPage')
                    },
                    {
                      path: 'history/:jobId',
                      lazy: () => import('./pages/AfsJobDetailPage')
                    }
                  ]
                : []),
              {
                path: 'agents',
                lazy: () => import('./pages/AgentsListPage')
              },
              {
                path: 'agents/:agentId',
                lazy: () => import('./pages/AgentDetailPage')
              },
              {
                path: 'printers/*',
                lazy: () => import('./pages/PrintersPage')
              },
              {
                path: 'printer/:printerUuid',
                lazy: () => import('./pages/PrinterDetailPage'),
                children: [
                  {
                    index: true,
                    loader: () => redirect('dashboard')
                  },
                  {
                    path: 'dashboard',
                    lazy: () => import('./pages/printer/DashboardPage')
                  },
                  {
                    path: 'queue/:jobId',
                    lazy: () => import('./pages/printer/PlannedJobDetailPage')
                  },
                  {
                    path: 'queue',
                    lazy: () => import('./pages/printer/QueuePage')
                  },
                  {
                    path: 'jobs/:jobId',
                    lazy: () => import('./pages/printer/JobDetailPage')
                  },
                  {
                    path: 'jobs',
                    lazy: () => import('./pages/printer/JobsPage')
                  },
                  {
                    path: 'storage/teams/:teamId/:storage/files/:hash',
                    lazy: () => import('./pages/FileDetailPage')
                  },
                  {
                    path: 'storage/*',
                    lazy: () => import('./pages/printer/FilesPage')
                  },
                  {
                    path: 'transfers/:transferId',
                    lazy: () => import('./pages/printer/TransfersDetailPage')
                  },
                  {
                    path: 'transfers',
                    lazy: () => import('./pages/printer/TransfersPage')
                  },
                  {
                    path: 'control',
                    lazy: () => import('./pages/printer/control/ControlPage')
                  },
                  {
                    path: 'cameras/*',
                    lazy: () => import('./pages/printer/CamerasPage')
                  },
                  {
                    path: 'command',
                    lazy: () => import('./pages/printer/CommandPage')
                  },
                  {
                    path: 'logs',
                    lazy: () => import('./pages/printer/LogsPage')
                  },
                  {
                    path: 'filament/*',
                    lazy: () => import('./pages/printer/FilamentPage')
                  },
                  {
                    path: 'settings',
                    lazy: () => import('./pages/printer/SettingsPage')
                  },
                  {
                    path: 'statistics',
                    lazy: () => import('./pages/printer/StatisticsPage')
                  },
                  {
                    path: 'telemetry',
                    lazy: () => import('./pages/printer/TelemetryPage')
                  }
                ] // End of PrinterDetailPage
              },
              {
                path: 'add-printer/*',
                lazy: () => import('./pages/AddPrinterPage')
              },
              {
                path: 'add',
                lazy: () => import('./pages/AddPrinterByQrManualPage')
              },
              {
                path: 'add/:code',
                lazy: () => import('./pages/AddPrinterByQrPage')
              },
              {
                path: 'commands/*',
                lazy: () => import('./pages/CommandsPage')
              },
              {
                path: 'profile/*',
                lazy: () => import('./pages/ProfilePage')
              },
              {
                path: 'filaments/*',
                lazy: () => import('./pages/FilamentsPage')
              },
              {
                path: 'users/*',
                lazy: () => import('./pages/UsersPage')
              },
              {
                path: 'notifications/*',
                lazy: () => import('./pages/NotificationsPage')
              },
              {
                path: 'storage/connect',
                children: [
                  {
                    index: true,
                    lazy: () => import('./pages/ConnectStoragePage')
                  },
                  {
                    path: 'teams/:teamId/:hash',
                    lazy: () => import('./pages/FileDetailPage')
                  }
                ]
              },
              {
                path: 'print', // print from Printables
                lazy: async () => {
                  const { PrintPage } = await import('./pages/PrintPage')
                  return {
                    Component: () => <PrintPage isDialog={false} />
                  }
                }
              },
              {
                path: 'slicer-print', // print from Printables in Slicer
                lazy: async () => {
                  const { PrintPage } = await import('./pages/PrintPage')
                  return {
                    Component: () => <PrintPage />
                  }
                }
              }
            ] // End of PrivateRoute
          },

          {
            Component: GuestOnlyRoute,
            ErrorBoundary,
            children: [
              {
                path: 'login/prusa-auth',
                lazy: () => import('./pages/PrusaAuthRedirectPage')
              },
              {
                path: 'login/connect',
                lazy: () => import('./pages/LoginFormPage')
              },
              {
                path: 'login',
                lazy: () => import('./pages/LoginPage')
              },
              {
                index: true,
                lazy: () => import('./pages/LoginPage')
              }
            ] // End of GuestOnlyRoute
          },

          {
            path: 'invitation/:code',
            lazy: () => import('./pages/InvitationDetailPage')
          },
          {
            path: 'game',
            lazy: () => import('./pages/GamePage')
          },
          {
            path: 'minimal-requirements',
            lazy: () => import('./pages/RequirementsPage')
          },

          // Error pages
          {
            path: 'error/*',
            Component: ErrorPage
          },
          {
            path: '*',
            loader: () => redirect('/error/404')
          }
        ] // End of Layout
      },
      {
        path: 'slicer-select-printer',
        lazy: () => import('./slicer/SelectPrinterPage')
      }
    ] // End of Contexts
  }
])
