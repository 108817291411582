import { isSlicerAvailable, openInBrowser } from './hooks/useSlicer'

/**
 * Intercept clicks on external links and open them in the browser if the app is running in PrusaSlicer.
 * @param event
 */
export default function interceptExternalLinkClicks(event: MouseEvent) {
  const target = event.target as HTMLElement
  const anchor = target.closest('a')
  const isInSlicer = isSlicerAvailable()

  if (!anchor) {
    return
  }

  const href = anchor.getAttribute('href')
  const isBlank = anchor.getAttribute('target') === '_blank'

  if (isInSlicer && href && isBlank) {
    event.preventDefault()
    openInBrowser(href)
  }
}
