import { QueryClient, QueryClientProvider as ReactQueryClientProvider } from '@tanstack/react-query'
import { ReactNode, useMemo } from 'react'

import { useErrorHandler } from '../hooks/errors/useErrorHandler'

export function QueryClientProvider({ children }: { children: ReactNode }) {
  const errorHandler = useErrorHandler()

  const queryClient = useMemo(
    () =>
      new QueryClient({
        defaultOptions: {
          queries: {
            retry: false,
            refetchOnWindowFocus: true
          },
          mutations: {
            onError: (error) => {
              errorHandler(error)

              console.error(error)
            }
          }
        }
      }),
    [errorHandler]
  )

  return <ReactQueryClientProvider client={queryClient}>{children}</ReactQueryClientProvider>
}
