export enum Commands {
  START_PRINT = 'START_PRINT',
  SET_NOZZLE_TEMPERATURE = 'SET_NOZZLE_TEMPERATURE',
  SET_HEATBED_TEMPERATURE = 'SET_HEATBED_TEMPERATURE',
  SET_SPEED = 'SET_SPEED',
  MOVE_Z = 'MOVE_Z',
  HOME = 'HOME',
  UNLOAD_FILAMENT = 'UNLOAD_FILAMENT',
  SET_PRINTER_READY = 'SET_PRINTER_READY',
  CANCEL_PRINTER_READY = 'CANCEL_PRINTER_READY',
  SET_TOOL_NOZZLE_DIAMETER = 'SET_TOOL_NOZZLE_DIAMETER',
  SET_TOOL_HIGH_FLOW = 'SET_TOOL_HIGH_FLOW',
  SET_TOOL_HARDENED = 'SET_TOOL_HARDENED'
}
