import { queryKey, REFETCH_LOGIN_CHECK } from '@prusaconnect/api'
import { useQuery } from '@tanstack/react-query'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'

import { useApiClient } from '../api/react'
import { useAuthActions } from '../context/authStore'
import { saveLanguage } from '../interfaces/locale'
import { isSlicerAvailable } from '../slicer/hooks/useSlicer'
import { notifySlicerAboutLogout } from '../slicer/SlicerConfigContext'
import useServiceResponseHandler from './useServiceResponseHandler'

/**
 * @deprecated Better solution is look for all responses from API and if token is expired, logout user
 * This could be handled in the top level initialization of react-query, similar to how we already
 * handle errors for toasts. This can be enabled after we have the response error types available.
 * Also avoid prop drilling the refetch method in favor of invalidating the query key wherever needed.
 */
export function useLoginPing() {
  const { displayHttpError } = useServiceResponseHandler()

  const { i18n } = useTranslation()
  const { language } = i18n
  const auth = useAuthActions()

  const api = useApiClient()
  const query = useQuery({
    queryKey: queryKey(['login']),
    queryFn: () => api.general.getLogin(),
    refetchOnWindowFocus: false,
    refetchInterval: REFETCH_LOGIN_CHECK,
    refetchIntervalInBackground: true
  })

  /**
   * We need to get rid of this type guard after we'll get types of error responses from the queries.
   * @param error
   */
  const isErrorResponse = (error: unknown): error is { response: Response } => {
    return typeof error === 'object' && error !== null && 'response' in error
  }

  const userLanguage = query.data?.user?.language
  const user = query.data?.user
  const { error, dataUpdatedAt } = query
  const errorStatus = isErrorResponse(error) ? error?.response?.status : null

  useEffect(() => {
    if (userLanguage && userLanguage !== language && !isSlicerAvailable()) {
      i18n.changeLanguage(userLanguage).then(() => {
        saveLanguage(userLanguage)
      })
    }
  }, [i18n, userLanguage])

  useEffect(() => {
    if (user) {
      auth.login(user)
    }
  }, [dataUpdatedAt])

  useEffect(() => {
    if (!error) {
      return
    }

    if (isErrorResponse(error) && errorStatus !== 401 && errorStatus !== 403) {
      displayHttpError(error.response)
    }

    if (isSlicerAvailable()) {
      notifySlicerAboutLogout()
      console.log('App is not logged out, because it is running inside slicer')
    } else {
      auth.logout()
    }
  }, [auth, displayHttpError, error, errorStatus])

  return {
    refetch: query.refetch
  }
}
