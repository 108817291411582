const PRINT_FILE_EXTENSIONS = ['.gcode', '.gco', '.g']
export const BINARY_EXTENSIONS = ['.bgc', '.bgcode']
export const PRINT_FILE_SLA_EXTENSIONS = ['.sl1', '.sl1s', '.m1']

const FIRMWARE_EXTENSIONS = ['.bbf', '.hex'] // .hex is for MK3 family, but upload is not supported; can be on SD card
export const FLASHABLE_EXTENSIONS = ['.bbf']

export const ALL_PRINT_FILE_EXTENSIONS = [...PRINT_FILE_EXTENSIONS, ...BINARY_EXTENSIONS, ...PRINT_FILE_SLA_EXTENSIONS]
export const ALL_SUPPORTED_FILE_EXTENSIONS = [...ALL_PRINT_FILE_EXTENSIONS, ...FLASHABLE_EXTENSIONS]

export function getExtensionFromFileName(filename?: string) {
  const parts = (filename || '').split('.')
  return (parts[parts.length - 1] || '').toLowerCase()
}

export function isFirmwareFileByName(file?: File) {
  const ext = getExtensionFromFileName(file?.name || '')
  return ext && FIRMWARE_EXTENSIONS.includes(`.${ext}`)
}

export function isSlaFile(filename?: string) {
  const ext = getExtensionFromFileName(filename)
  return ext && PRINT_FILE_SLA_EXTENSIONS.includes(`.${ext}`)
}
