import classNames from 'classnames'

import { Button, ButtonProps } from '../primitives/Button'

type Props = ButtonProps & {
  isLoading?: boolean
  disabled?: boolean
}

export function LoadingButton(props: Props) {
  const { isLoading, disabled, children, ...buttonProps } = props

  return (
    <Button
      className={classNames('relative transition-colors duration-400 overflow-hidden text-sm', {
        'user-select-none cursor-default pointer-events-none': isLoading
      })}
      disabled={isLoading || disabled}
      {...buttonProps}
    >
      {children}
      {isLoading && <span className="ripple-animation w-full h-full top-0 right-[100%]" />}
    </Button>
  )
}
