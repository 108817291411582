import classNames from 'classnames'
import { MouseEvent, ReactNode } from 'react'

import { SvgComponent } from '../../types/SvgComponent'

export type ButtonProps = {
  children: ReactNode
  icon?: SvgComponent
  onClick?: (event: MouseEvent) => void
  disabled?: boolean
  loading?: boolean
  solidBackground?: boolean
  className?: string
  nowrap?: boolean
  type?: 'button' | 'submit' | 'reset'
  fullWidth?: boolean
}

export type ChildlessButtonProps = Omit<ButtonProps, 'children'>

export function Button({
  disabled,
  onClick,
  loading,
  solidBackground,
  className,
  nowrap = true,
  type = 'button',
  fullWidth = false,
  ...props
}: ButtonProps) {
  return (
    <button
      type={type}
      disabled={disabled}
      onClick={onClick}
      className={classNames(
        className,
        `rounded font-bold text-sm text-center
          flex items-center justify-center gap-2 border-[1px] border-neutral-500
          icon-hover-inverse disabled:opacity-50 disabled:bg-neutral-300`,
        { 'animate-button-pulse': loading },
        { 'pointer-events-none cursor-not-allowed': loading || disabled },
        { 'bg-neutral-100 text-neutral-900 hover:text-neutral-100': solidBackground },
        { 'whitespace-nowrap': nowrap },
        { 'hover:bg-neutral-900 hover:text-neutral-100': !disabled },
        { 'flex-1': fullWidth }
      )}
    >
      <div className="relative overflow-hidden px-2 py-1 btn-label flex items-center gap-2">
        {props.icon && (
          <div>
            <props.icon className="w-6 h-6" />
          </div>
        )}
        {props.children}
      </div>
    </button>
  )
}
