/**
 * Unification of query keys
 * object is filter parameters
 */
export type QueryKeyRules =
  | ['login']
  | ['user']
  | ['user', 'invitations']
  | ['groups']
  | ['group', number, 'afs-jobs', number]
  | ['teams', object]
  // Special key for invalidate all team files query
  | ['team', number, 'files']
  // Files in team cloud storage, in object can be any parameters
  | ['team', number, 'files', object]
  // Upload state of files in team cloud storage
  | ['team', number, 'files', 'uploads']
  // Storage quota of team cloud storage
  | ['team', number, 'files', 'quota']
  | ['printers', object]
  | ['printer', string]
  // Qeueu = jobs will be printed in future
  | ['printer', string, 'queue']
  | ['printer', string, 'queue', object]
  // Jobs = historical jobs already printed
  | ['printer', string, 'jobs']
  | ['printer', string, 'jobs', object]
  | ['printer', string, 'job', number]
  | ['printer', string, 'cameras']
  | ['printer', string, 'files']
  | ['printer', string, 'files', object]
  /** Hacky way to one camera image, returns image itself, not url to image */
  | ['printer', string, 'cameras', object]
  | ['printer', string, 'transfers']
  | ['printer', string, 'transfer', number]
  | ['printer', string, 'commands']
  | ['printer', string, 'download-queue']
  | ['printer-types']
  /** File detail (fileHash) */
  | ['file', string]
  /** List of all cameras, needs api change to inject camera data to printer itself */
  | ['cameras', object]
  | ['notification-medium', 'list']
  | ['notifications']
  | ['notifications', 'unseen']
  | ['invitations']

  // Frontend downlod file from url and parse metadata
  | ['url-gcode-metadata', string]

  // TODO: Legacy code, will need refactoring
  | ['/printers-to-megamenu']

/**
 * Guard for right usage of query keys
 */
export function queryKey<T extends QueryKeyRules>(key: T): T {
  return key
}
