import { ConfirmDialog } from '@prusaconnect/ui'
import { createContext, ReactNode, useCallback, useContext, useRef, useState } from 'react'

import { isPromise } from '../helpers/isPromise'

type ConfirmConfig = {
  title: string
  body?: ReactNode
  confirmText?: string
  cancelText?: string
  onConfirm: () => Promise<void> | void
  onCancel?: () => void
}

type DialogContextType = {
  confirm: (config: ConfirmConfig) => Promise<void> | void
  openDialog: (dialog: ReactNode) => void
  closeDialog: () => void
}

const DialogContext = createContext<DialogContextType | undefined>(undefined)

export const DialogProvider = ({ children }: { children: ReactNode }) => {
  const [confirmConfig, setConfirmConfig] = useState<ConfirmConfig | null>(null)
  const [currentDialog, setCurrentDialog] = useState<ReactNode | null>(null)
  const resolveRef = useRef<() => void>(() => {})

  const confirm = (config: ConfirmConfig) => {
    setConfirmConfig(config)

    return new Promise<void>((resolve) => {
      resolveRef.current = resolve
    })
  }

  const handleConfirm = useCallback(() => {
    if (!confirmConfig) {
      return
    }

    const doConfirm = confirmConfig.onConfirm?.()
    const resolve = () => {
      resolveRef.current()
      setConfirmConfig(null)
    }

    if (isPromise(doConfirm)) {
      doConfirm.then(resolve)
    } else {
      resolve()
    }
  }, [confirmConfig])

  const openDialog = (dialog: ReactNode) => {
    setCurrentDialog(dialog)
  }

  const closeDialog = () => {
    setCurrentDialog(null)
  }

  return (
    <DialogContext.Provider value={{ confirm, openDialog, closeDialog }}>
      {confirmConfig && (
        <ConfirmDialog
          title={confirmConfig.title}
          body={confirmConfig.body}
          open
          onConfirm={handleConfirm}
          onCancel={() => {
            setConfirmConfig(null)
          }}
        />
      )}
      {currentDialog}
      {children}
    </DialogContext.Provider>
  )
}

export function useDialog() {
  const context = useContext(DialogContext)

  if (!context) {
    throw new Error('useConfirm must be used within a ConfirmDialogProvider')
  }

  return context
}
