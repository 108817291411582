import { ConfirmDialog, translateLabel } from '@prusaconnect/ui'
import { type MouseEvent, ReactNode, useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { IPrinterSimpleView } from '../../../api/types/printer'
import { useCanControl } from '../../../context/permissionsStore'
import { useCommandMutation } from '../../../hooks/commands/useCommandMutation'
import { dispatchPrintStoppedEvent } from '../../../hooks/useStoppedPrint'
import { Icon } from '../../../icons'
import { useToast } from '../../../store/toastStore'
import { AdaptiveButton } from '../../common/AdaptiveButton/AdaptiveButton'

type Config = {
  label: string
  icon: Icon
  command: string
  successMessage: {
    title: string
    body: string
  }
  confirm: {
    title: string
    body: string
    icon: Icon
    button?: string
  }
}

type PublicProps = {
  onSuccess?: () => void
  withConfirm?: boolean
  iconNode?: ReactNode
  printer: IPrinterSimpleView
}

type Props = PublicProps & {
  config: Config
}

type CommandError = {
  code: string
  message: string
}

function PrintControlAction({ onSuccess, withConfirm, iconNode, printer, config }: Props) {
  const [showConfirmDialog, setShowConfirmDialog] = useState(false)
  const toast = useToast()
  const canControl = useCanControl(printer.team_id)
  const { t } = useTranslation()

  const connectState = translateLabel(t, printer.connect_state)
  const isStopCommand = config.command === 'STOP_PRINT'

  const { execute, isPending, isAvailable } = useCommandMutation(printer, {
    icon: config.icon,
    label: config.label,
    command: config.command,
    successMessage: {
      title: config.successMessage.title,
      body: config.successMessage.body
    }
  })

  const startAction = useCallback(() => {
    execute(
      {},
      {
        onSuccess: () => {
          if (isStopCommand) {
            dispatchPrintStoppedEvent(printer.uuid, printer.printer_type, printer?.job_info?.id)
          }
          setShowConfirmDialog(false)
          onSuccess?.()
        },
        onError: (error) => {
          const errorObj = error as CommandError
          setShowConfirmDialog(false)
          toast.add(errorObj.code, errorObj.message, { severity: 'error' })
        }
      }
    )
  }, [execute, isStopCommand, onSuccess, printer.uuid, printer.printer_type, printer?.job_info?.id, toast])

  const trigger = useCallback(
    (e: MouseEvent<Element>) => {
      e.stopPropagation()
      e.preventDefault()

      if (withConfirm) {
        setShowConfirmDialog(true)
      } else {
        startAction()
      }
    },
    [startAction, withConfirm]
  )

  if (!isAvailable) return null

  let disabledTooltip = ''
  if (!canControl) {
    disabledTooltip = t('printer.tooltip.rights')
  } else if (isPending) {
    disabledTooltip = t('printer.command.processing')
  } else {
    disabledTooltip = `${t('printer.overview.currentFile.in-state')} ${connectState.toUpperCase()}`
  }

  return (
    <>
      <AdaptiveButton
        icon={config.icon}
        iconNode={iconNode}
        trigger={trigger}
        label={config.label}
        isLoading={isPending}
        isDisabled={isPending || !canControl}
        disabledTooltip={disabledTooltip}
      />

      {showConfirmDialog && (
        <ConfirmDialog
          title={config.confirm.title}
          open={showConfirmDialog}
          onCancel={() => setShowConfirmDialog(false)}
          onConfirm={startAction}
          disabled={isPending}
          body={config.confirm.body}
          confirmText={config.confirm.button || config.label}
        />
      )}
    </>
  )
}

export const ResumePrintAction = (props: PublicProps) => {
  const { t } = useTranslation()

  return (
    <PrintControlAction
      {...props}
      config={{
        command: 'RESUME_PRINT',
        label: t('printer.actions.resume-print.label'),
        icon: 'startIcon',
        confirm: {
          title: t('printer.actions.resume-print.confirm.title'),
          body: t('printer.actions.resume-print.confirm.body'),
          icon: 'startIcon'
        },
        successMessage: {
          title: t('printer.actions.resume-print.success.title'),
          body: t('printer.actions.resume-print.success.body')
        }
      }}
    />
  )
}

export const PausePrintAction = (props: PublicProps) => {
  const { t } = useTranslation()

  return (
    <PrintControlAction
      {...props}
      config={{
        command: 'PAUSE_PRINT',
        label: t('printer.actions.pause-print.label'),
        icon: 'pauseCurrentIcon',
        confirm: {
          title: t('printer.actions.pause-print.confirm.title'),
          body: t('printer.actions.pause-print.confirm.body'),
          icon: 'pauseCurrentIcon'
        },
        successMessage: {
          title: t('printer.actions.pause-print.success.title'),
          body: t('printer.actions.pause-print.success.body')
        }
      }}
    />
  )
}

export const StopPrintAction = (props: PublicProps) => {
  const { t } = useTranslation()

  return (
    <PrintControlAction
      {...props}
      config={{
        command: 'STOP_PRINT',
        label: t('printer.actions.stop-print.label'),
        icon: 'stopCurrentIcon',
        confirm: {
          title: t('printer.actions.stop-print.confirm.title'),
          body: t('printer.actions.stop-print.confirm.body'),
          icon: 'stopCurrentIcon'
        },
        successMessage: {
          title: t('printer.actions.stop-print.success.title'),
          body: t('printer.actions.stop-print.success.body')
        }
      }}
    />
  )
}

export const RefillResin = (props: PublicProps) => {
  const { t } = useTranslation()

  return (
    <PrintControlAction
      {...props}
      config={{
        command: 'PAUSE_PRINT',
        label: t('printer.actions.refill-resin.label', 'Refill resin'),
        icon: 'refillColorIcon',
        confirm: {
          title: t('printer.actions.refill-resin.confirm.title', 'Refill resin'),
          body: t(
            'printer.actions.refill-resin.confirm.body',
            'Do you really want to pause the print to refill the resin tank?'
          ),
          icon: 'pauseCurrentIcon',
          button: t('printer.actions.refill-resin.confirm.button', 'Confirm')
        },
        successMessage: {
          title: t('printer.actions.refill-resin.success.title', 'Refill resin tank'),
          body: t('printer.actions.refill-resin.success.body', 'Command to pause the print has been successfully sent.')
        }
      }}
    />
  )
}
