import { Tooltip } from '@prusaconnect/ui'
import { useTranslation } from 'react-i18next'

export function UncertaintySign({ printerSpeed }: { printerSpeed?: number }) {
  const { t } = useTranslation()

  if (!printerSpeed || printerSpeed === 100) {
    return null
  }

  return (
    <Tooltip content={t('remaining-time.estimated-due-to-speed', { speed: printerSpeed })} side="top">
      <span>&nbsp;?</span>
    </Tooltip>
  )
}
