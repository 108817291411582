import { ConnectState } from '@prusaconnect/api'
import { translateLabel } from '@prusaconnect/ui'
import { useTranslation } from 'react-i18next'
import Select from 'react-select'

import { IConnectState } from '../../../api/types/state'
import { usePrintersOverviewStore } from '../../../store/printersOverview'
import { useStyles } from './styles'

type Option = {
  value: IConnectState
  label: string
}

export function StateFilter() {
  const { t } = useTranslation()
  const { setParams, states } = usePrintersOverviewStore()

  const options = Object.values(IConnectState)
    .filter((state) => AFS_ENABLED || state !== IConnectState.MANIPULATING)
    .map((state) => ({
      label: translateLabel(t, state).toUpperCase(),
      value: state
    }))

  return (
    <Select
      value={options.filter((opt) => states?.includes(opt.value as ConnectState))}
      options={options}
      onChange={(options) => {
        const value = options.map((opt) => opt.value as ConnectState)
        setParams({ states: value })
      }}
      placeholder={t('printer.state')}
      isMulti
      isClearable
      styles={useStyles<Option, true>()}
    />
  )
}
