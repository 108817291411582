/**
 * CookiePro
 */
if (window.location.host.indexOf('prusa3d.com') !== -1 && !window._prusaSlicer) {
  const cookieScript = document.createElement('script')
  document.head.appendChild(cookieScript)
  cookieScript.src = 'https://cookie-cdn.cookiepro.com/scripttemplates/otSDKStub.js'
  cookieScript.type = 'text/javascript'
  cookieScript.charset = 'UTF-8'
  cookieScript.setAttribute('data-domain-script', '24d209b9-25f4-4196-8d7a-b306313b85d5')

  window.OptanonWrapper = function () {}
}

/**
 * Google Tag Manager
 */
;(function (w, d, s, l, i) {
  w[l] = w[l] || []
  w[l].push({
    'gtm.start': new Date().getTime(),
    event: 'gtm.js'
  })
  const f = d.getElementsByTagName(s)[0]
  const j = d.createElement(s)
  const dl = l != 'dataLayer' ? `&l=${l}` : ''
  j.async = true
  j.src = `https://www.googletagmanager.com/gtm.js?id=${i}${dl}`
  f.parentNode.insertBefore(j, f)
})(window, document, 'script', 'dataLayer', 'GTM-5XF6BGP')
