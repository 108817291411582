import { useApiClient } from '../client/apiClientContext'
import { queryKey } from '../lib/queryKeys'
import type { components } from '../schema/schema'

export type UserResponse = components['schemas']['user_response']

export function useLoginInfo(isEnabled = true) {
  const { $api } = useApiClient()
  const { data, isLoading, isError, isSuccess, refetch } = $api.useQuery('get', '/app/login', {
    queryKey: queryKey(['login']),
    enabled: isEnabled
  })
  return {
    user: data?.user,
    isLoading,
    isError,
    isSuccess,
    refetch
  }
}
