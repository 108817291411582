/* Modules */
import { createModule as createAfsModule } from './modules/app/afs'
import { createModule as createAgentsModule } from './modules/app/agents'
import { createModule as createCommandQueueModule } from './modules/app/commandQueue'
import { createModule as createEventsModule } from './modules/app/events'
import { createModule as createFilamentsModule } from './modules/app/filaments'
import { createModule as createFilesModule } from './modules/app/files'
import { createModule as createGCodesModule } from './modules/app/gCodes'
import { createModule as createJobsModule } from './modules/app/jobs'
import { createModule as createPrinterGroupsModule } from './modules/app/printerGroups'
import { createModule as createPrintersModule } from './modules/app/printers'
import { createModule as createSupportedCommandsModule } from './modules/app/supportedCommands'
import { createModule as createTeamsModule } from './modules/app/teams'
import { createModule as createTelemetryModule } from './modules/app/telemetry'
import { createModule as createTransfersModule } from './modules/app/transfers'
import { createModule as createGeneralModule } from './modules/general'
import { createRequestFactory } from './requestFactory'

export type ApiClient = ReturnType<typeof createApiClient>

/**
 * @deprecated
 */
export function createApiClient(base = '') {
  const baseUrl = base.replace(/\/$/, '') // remove trailing /
  const rf = createRequestFactory(baseUrl)

  const general = createGeneralModule(rf)
  const printerGroups = createPrinterGroupsModule(rf)
  const printers = createPrintersModule(rf)
  const files = createFilesModule(rf)
  const gCodes = createGCodesModule(rf)
  const jobs = createJobsModule(rf)
  const transfers = createTransfersModule(rf)
  const teams = createTeamsModule(rf)
  const telemetry = createTelemetryModule(rf)
  const commandQueue = createCommandQueueModule(rf)
  const filaments = createFilamentsModule(rf)
  const agents = createAgentsModule(rf)
  const events = createEventsModule(rf)
  const supportedCommands = createSupportedCommandsModule(rf)
  const afs = createAfsModule(rf)

  return {
    // Base
    rf,
    baseUrl,

    general,
    app: {
      afs,
      agents,
      commandQueue,
      supportedCommands,
      events,
      filaments,
      files,
      gCodes,
      jobs,
      transfers,
      printerGroups,
      printers,
      teams,
      telemetry
    }
  }
}
