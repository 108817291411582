import * as Sentry from '@sentry/react' // TODO: consider using store for all needed contexts
import { useQuery } from '@tanstack/react-query'
import { useEffect } from 'react'

import { useApiClient } from '../api/react'
import { config } from '../config'

export function useInfoService() {
  const api = useApiClient()
  const { data } = useQuery({
    queryKey: ['/info'],
    queryFn: () => api.general.getInfo()
  })

  useEffect(() => {
    Sentry.setContext('version', {
      FE: `${config.appVersion}`,
      BE: `${data?.version.string}`,
      DB: `${data?.database_version?.script_count}`
    })
  }, [data?.database_version?.script_count, data?.version.string])

  return data
}
