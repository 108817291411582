import { useTranslation } from 'react-i18next'
import Select from 'react-select'

import { useGroupFilterData } from '../../../hooks/useGroupFilterData'
import { useGroupName } from '../../../hooks/useGroupName'
import { usePrintersOverviewStore } from '../../../store/printersOverview'
import { Filter } from '../Filters'
import { useStyles } from './styles'

type OptionType = {
  label: string
  value: number
}

type Props = {
  isClearable?: boolean
}

export function GroupFilter({ isClearable }: Props) {
  const { groupId, setParams } = usePrintersOverviewStore()
  const { getGroupName } = useGroupName()
  const { t } = useTranslation()
  const styles = useStyles<OptionType>()

  const { groupList, isLoading } = useGroupFilterData()

  if (groupList.length === 0) {
    return null
  }

  const options = groupList.map((group) => ({ label: getGroupName(group), value: group.id })) || []

  return (
    <Filter>
      <Select
        hideSelectedOptions
        value={options.find((opt) => opt.value === groupId) || null}
        placeholder={t('printer.group')}
        isLoading={isLoading}
        isClearable={isClearable}
        options={options}
        onChange={(newOption) => setParams({ groupId: newOption?.value })}
        styles={styles}
      />
    </Filter>
  )
}
