import { Toast as UiToast } from '@prusaconnect/ui'
import { useCallback, useEffect } from 'react'
import styled from 'styled-components'

import { delay } from '../../helpers/std'
import { type Toast, useToast, useToastList } from '../../store/toastStore'

const ToastsContainer = styled.div`
  position: fixed;
  bottom: 0;
  right: 0;
  padding: 20px;
  z-index: 1100;

  a {
    color: var(--color-primary);
  }

  a:hover {
    text-decoration: underline;
  }
`

export function Toasts() {
  const { remove } = useToast()
  const toasts = useToastList()

  if (toasts.length === 0) {
    return null
  }

  return (
    <ToastsContainer>
      <div className="flex flex-col gap-2">
        {toasts.map((toast) => (
          <ToastItem key={toast.toastId} {...toast} remove={remove} />
        ))}
      </div>
    </ToastsContainer>
  )
}

type IProps = {
  remove: (toastId: string) => void
} & Toast

const TOAST_TTL = 5000

function ToastItem({ remove, toastId, title, severity, body, timeout, onClose, actions }: IProps) {
  const handleClose = useCallback(() => {
    if (toastId) {
      remove(toastId)
    }
    onClose?.()
  }, [onClose, remove, toastId])

  useEffect(() => {
    if (timeout !== 0) {
      const { promise, cancel } = delay(timeout || TOAST_TTL)
      promise.then(() => handleClose())

      return () => cancel()
    }
  }, [handleClose, timeout])

  return <UiToast title={title} body={body} severity={severity} onClose={handleClose} actions={actions} />
}
