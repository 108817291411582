import { faChevronLeft, faChevronRight } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { PropsWithChildren, useEffect, useRef, useState } from 'react'
import styled, { useTheme } from 'styled-components'

function useHorizontalDragScroll() {
  const [isDragging, setIsDragging] = useState<boolean>(false)
  const [startX, setStartX] = useState<number>(0)
  const [scrollLeft, setScrollLeft] = useState<number>(0)
  return {
    isDragging,
    startX,
    scrollLeft,
    setIsDragging,
    setStartX,
    setScrollLeft
  }
}

const Scroller = styled.div`
  width: 100%;
  overflow-x: auto;
  overflow-y: hidden;
  box-sizing: content-box;
  scroll-behavior: smooth;
  padding-bottom: 1px;
  ::-webkit-scrollbar {
    display: none;
  }
  scrollbar-width: none; // Firefox
`

const Wrapper = styled.div<{ noPadding?: boolean }>`
  width: 100%;
  overflow: hidden;
  position: relative;
  padding: ${({ noPadding }) => (noPadding ? '0' : '0 1rem')};
  z-index: 40;
`

const Shadow = styled.div<{ position: 'left' | 'right'; visible: boolean }>`
  position: absolute;
  ${({ position }) => position}: -1px;
  width: 1rem;
  top: 0;
  bottom: 0;
  box-shadow: 0 0 1.43rem 1rem rgba(255, 255, 255, 0.9);

  transition: opacity 0.3s;
  opacity: ${({ visible }) => (visible ? 1 : 0)};
  z-index: 50;
`

const ArrowIcon = styled.button<{ position: string; background: string }>`
  position: absolute;
  top: 0;
  bottom: 0;
  border: 0;
  margin: 0;
  padding: 0 0.4rem;
  background: transparent;
  width: 2.57rem;
  cursor: pointer;
  font-size: 1.25rem;
  text-align: ${({ position }) => position};
  z-index: 1;

  ${({ position }) => `${position}: 0;`}

  svg {
    z-index: 1;
    position: relative;
  }

  &:after {
    content: '';
    position: absolute;
    z-index: 0;

    ${({ position, background }) =>
      position === 'left'
        ? `
            right : 0;
            background: linear-gradient(90deg, ${background}, rgba(255,255,255,0));
          `
        : `
            left: 0;
            background: linear-gradient(270deg, ${background}, rgba(255,255,255,0));
    `}
    width: 1.6875rem;
    top: 0;
    bottom: 0;
  }

  &:before {
    content: '';
    position: absolute;
    z-index: 0;
    ${({ position }) => `${position}: 0;`}
    width: 0.93rem;
    background: var(--background-body);
    top: 0;
    bottom: 0;

    ${({ background }) =>
      `background: ${background};
    `}
  }
`

type ScrollContainerProps = {
  className?: string
  hideArrows?: boolean
  background?: string
  color?: string
  noPadding?: boolean
}

const ScrollContainer: React.FC<PropsWithChildren<ScrollContainerProps>> = ({
  children,
  className = '',
  hideArrows = false,
  background = 'transparent',
  color,
  noPadding
}) => {
  const parentRef = useRef<HTMLDivElement | null>(null)
  const [shadowLeft, setShadowLeft] = useState<boolean>(false)
  const [shadowRight, setShadowRight] = useState<boolean>(false)
  const theme = useTheme()
  const filling = color || theme.colors.secondary
  const [fill, setFill] = useState({ leftIcon: filling, rightIcon: filling })

  const { isDragging, setIsDragging, startX, setStartX, scrollLeft, setScrollLeft } = useHorizontalDragScroll()

  const handleScroll = () => {
    if (parentRef.current) {
      const shouldBeShadowLeft = parentRef.current.scrollLeft > 0
      if (shadowLeft !== shouldBeShadowLeft) setShadowLeft(shouldBeShadowLeft)

      const shouldBeShadowRight =
        parentRef.current.scrollLeft + parentRef.current.clientWidth < parentRef.current.scrollWidth
      if (shadowRight !== shouldBeShadowRight) setShadowRight(shouldBeShadowRight)
    }
  }

  function doScroll(dir = 1) {
    if (parentRef.current) {
      parentRef.current.scrollLeft += (parentRef.current.clientWidth / 2) * dir
    }
  }

  useEffect(() => {
    handleScroll()
    window.addEventListener('resize', handleScroll)
    return () => {
      window.removeEventListener('resize', handleScroll)
    }
  }, [])

  return (
    <Wrapper className={`scroll-arrows ${className}`} noPadding={noPadding}>
      {shadowLeft && !hideArrows && (
        <ArrowIcon
          onClick={(e) => {
            e.preventDefault()
            doScroll(-1)
          }}
          position="left"
          background={background}
          onMouseEnter={() => setFill({ ...fill, leftIcon: theme.colors.primary })}
          onMouseLeave={() => setFill({ ...fill, leftIcon: filling })}
        >
          <FontAwesomeIcon icon={faChevronLeft} color={fill.leftIcon} />
        </ArrowIcon>
      )}
      {hideArrows && (
        <>
          <Shadow position="left" visible={shadowLeft} />
          <Shadow position="right" visible={shadowRight} />
        </>
      )}
      <Scroller
        ref={parentRef}
        onScroll={handleScroll}
        onLoad={handleScroll}
        onMouseDown={(e) => {
          if (!parentRef.current) return
          setIsDragging(true)
          setStartX(e.pageX - parentRef.current.offsetLeft)
          setScrollLeft(parentRef.current.scrollLeft)
        }}
        onMouseMove={(e) => {
          if (!parentRef.current) return
          if (!isDragging) return
          if (isDragging) {
            e.persist()
            const x = e.pageX - parentRef.current.offsetLeft
            const walk = x - startX
            parentRef.current.scrollLeft = scrollLeft - walk
          }
        }}
        onMouseUp={() => setIsDragging(false)}
        onMouseLeave={() => setIsDragging(false)}
      >
        {children}
      </Scroller>
      {shadowRight && !hideArrows && (
        <ArrowIcon
          onClick={(e) => {
            e.preventDefault()
            doScroll(1)
          }}
          position="right"
          background={background}
          onMouseEnter={() => setFill({ ...fill, rightIcon: theme.colors.primary })}
          onMouseLeave={() => setFill({ ...fill, rightIcon: filling })}
        >
          <FontAwesomeIcon icon={faChevronRight} color={fill.rightIcon} />
        </ArrowIcon>
      )}
    </Wrapper>
  )
}

export default ScrollContainer
