import { Pagination } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { useSearchParams } from 'react-router-dom'
import styled from 'styled-components'

const Label = styled.div`
  align-self: center;
  margin-right: 0.5rem;
  font-weight: 400;
  font-size: 0.6875rem;
  color: #808080;
`

type Item = {
  limit: number
  active: boolean
}

type Props = {
  limit?: number
  setOffset: (offset: number) => void
  setLimit: (limit: number) => void
  itemsPerPage?: [number, number, number]
}

export function Limitation({ limit, setOffset, setLimit, itemsPerPage = [25, 50, 100] }: Props) {
  const { t } = useTranslation()
  const [searchParams, setSearchParams] = useSearchParams()
  const currentPage = Number(searchParams.get('page'))
  const limitFromUrl = Number(searchParams.get('itemsPerPage'))
  const actualLimit = itemsPerPage?.includes(limitFromUrl) ? limitFromUrl : limit
  const items: Item[] = itemsPerPage.map((amount) => ({
    limit: amount,
    active: actualLimit !== undefined ? actualLimit === amount : false
  }))

  const onClick = (limit: number) => {
    setOffset(0)
    setLimit(limit)
    searchParams.set('itemsPerPage', limit.toString())

    if (currentPage > 1) {
      searchParams.delete('page')
    }

    setSearchParams(searchParams)
  }

  return (
    <>
      <Label>{t('pager.items-per-page')}</Label>
      <div className="sr-only" /> {/* temporary hack so that the sr-only class doesn't get stripped out by purgecss */}
      <Pagination>
        {items.map((item, i) => (
          <Pagination.Item key={i} active={item.active} onClick={() => onClick(item.limit)}>
            {item.limit}
          </Pagination.Item>
        ))}
      </Pagination>
    </>
  )
}
