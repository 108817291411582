import { ReactNode } from 'react'

type Props = {
  icon: ReactNode
  onClick?: () => void
  className?: string
}

export function IconButton({ icon, onClick, className }: Props) {
  return (
    <button type="button" onClick={onClick} className={`${className}`}>
      {icon}
    </button>
  )
}
