export const REFETCH_PRINTER_DETAIL = 5_000
export const REFETCH_PRINTER_DETAIL_INACTIVE = 60_000
export const REFETCH_PRINTER_LIST = 30_000
export const REFETCH_PRINT_QUEUE = 10_000
export const REFETCH_PRINTER_CAMERAS = 30_000
export const REFETCH_CAMERA_PRINTING = 10_000
export const REFETCH_CAMERA_IDLE = 60_000
export const REFETCH_NOTIFICATIONS_UNSEEN = 10_000
export const REFETCH_PRINTER_GROUPS = 10_000
export const REFETCH_GROUP_QUEUES = 10_000
export const REFETCH_PRINTER_CLOUD_FILES = 10_000
export const REFETCH_PRINTER_FILES = 10_000
export const REFETCH_TEAMS_LIST = 15 * 60_000
export const REFETCH_USER_INVITATIONS = 10_000
export const REFETCH_COMMAND_QUEUE = 5_000
export const REFETCH_EVENTS = 10_000
export const REFETCH_JOBS = 10_000
export const REFETCH_LOGIN_CHECK = 10 * 60_000
export const REFETCH_TRANSFERS = 10_000
