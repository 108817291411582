import { ConnectState } from '@prusaconnect/api'
import { useQuery, UseQueryOptions } from '@tanstack/react-query'

import { useApiClient } from '../api/react'
import { IGroupDetail, IGroupPrintersResponse } from '../api/types/groups'

export type FullGroupDetail = {
  group: IGroupDetail
  groupPrinters: IGroupPrintersResponse
}

export function getFullGroupDetailKey(groupId: number, includedStates: ConnectState[] = []) {
  return `/full-group-detail/${groupId}/${JSON.stringify(includedStates)}`
}

// Ugly Omitted type
export function useFullGroupDetail(
  groupId: number,
  includedStates: ConnectState[],
  options: Omit<UseQueryOptions<FullGroupDetail>, 'queryKey'> = {}
) {
  const api = useApiClient()

  return useQuery({
    queryKey: [getFullGroupDetailKey(groupId, includedStates)],
    queryFn: async () => {
      const group = await api.app.printerGroups.getGroup(groupId)
      const groupPrinters = await api.app.printerGroups.getGroupPrinters(groupId, {
        limit: group.printer_count,
        state_include: includedStates
      })

      return { group, groupPrinters }
    },

    ...options
  })
}
