import { GenericSorting } from '@prusaconnect/ui'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { SortKey, usePrintersOverviewStore } from '../../../store/printersOverview'

export function Sorting() {
  const { t } = useTranslation()
  const options = useMemo(
    () =>
      [
        { value: 'state', label: t('printer.sort.state') },
        { value: 'name', label: t('printer.sort.name') },
        { value: 'type', label: t('printer.sort.type') },
        { value: 'location', label: t('printer.sort.location') }
      ] as const,
    [t]
  )

  const sortBy = usePrintersOverviewStore((store) => store.sortBy)
  const applySort = usePrintersOverviewStore((store) => store.applySort)
  const resetSort = usePrintersOverviewStore((store) => store.resetSort)

  const isResetVisible = sortBy.filter((item) => item.key !== 'remaining_time').length > 0

  return (
    <GenericSorting<SortKey>
      sortBy={sortBy}
      options={options}
      onChange={applySort}
      isResetVisible={isResetVisible}
      onReset={resetSort}
    />
  )
}
