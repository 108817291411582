import { stringify } from 'query-string'

import { Created201, NoContent204, RequestFactory } from '../../types/sdk'
import {
  IEditTeam,
  IInvitationAcceptedResponse,
  IInvitationResponse,
  IInvitedUser,
  IRights,
  ITeamDetail,
  ITeamsQuery,
  ITeamsResponse,
  ITeamToBeDeletedEntities
} from '../../types/team'

export function createModule(rf: RequestFactory) {
  return {
    getTeams: (query: ITeamsQuery = {}) => rf.get<ITeamsResponse>(`/app/users/teams?${stringify(query)}`),

    getTeam: (teamId: number) => rf.get<ITeamDetail>(`/app/users/teams/${teamId}`),

    createTeam: (name: string, description?: string) => rf.post<ITeamDetail>(`/app/users/teams`, { name, description }),

    editTeam: (teamId: number, data: IEditTeam) => rf.put<IEditTeam>(`/app/users/teams/${teamId}`, data),

    getToBeDeletedEntities: (teamId: number) => rf.get<ITeamToBeDeletedEntities>(`/app/users/teams/${teamId}/entities`),

    deleteTeam: (teamId: number) => rf.delete<NoContent204>(`/app/users/teams/${teamId}`),

    inviteUserToTeam: (teamId: number, userData: IInvitedUser) =>
      rf.post<Created201>(`/app/teams/${teamId}/add-user`, userData),

    getUserInvitation: (code: string) => rf.get<IInvitationResponse>(`/app/users/invitation/${code}`),

    acceptInvitationToTeam: (code: string) => rf.post<IInvitationAcceptedResponse>(`/app/users/invitation/${code}`),

    declineInvitationToTeam: (code: string) => rf.delete<NoContent204>(`/app/users/invitation/${code}`),

    editRights: (userId: number, teamId: number, rights: IRights) =>
      rf.put<NoContent204>(`/app/users/${userId}/teams/${teamId}`, rights),

    removeUserFromTeam: (userId: number, teamId: number) =>
      rf.delete<NoContent204>(`/app/users/${userId}/teams/${teamId}`),

    editInvitedRights: (invitationId: number, teamId: number, rights: IRights) =>
      rf.put<NoContent204>(`/app/teams/${teamId}/invitations/${invitationId}`, rights),

    restoreInvitation: (invitationId: number, teamId: number) =>
      rf.post<NoContent204>(`/app/teams/${teamId}/invitations/${invitationId}`),

    refuseInvitation: (invitationId: number, teamId: number) =>
      rf.delete<NoContent204>(`/app/teams/${teamId}/invitations/${invitationId}`),

    generateToken: (teamId: number) => rf.post<NoContent204>(`/app/teams/${teamId}/token`),

    deleteToken: (teamId: number) => rf.delete<NoContent204>(`/app/teams/${teamId}/token`)
  }
}
