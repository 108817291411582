import { useLoginInfo } from '@prusaconnect/api'
import { UserPreferencesContext, UserSubmittedPreferencesSchema } from '@prusaconnect/ui'
import { ReactNode } from 'react'

import { useAuthState } from './authStore'

export const UserPreferencesProvider = ({ children }: { children: ReactNode }) => {
  const authState = useAuthState()
  const user = useLoginInfo(authState.isAuthenticated)

  try {
    const userPreferences = UserSubmittedPreferencesSchema.parse(user?.user?.preferences)
    return <UserPreferencesContext.Provider value={userPreferences}>{children}</UserPreferencesContext.Provider>
  } catch (_error) {
    return children
  }
}
