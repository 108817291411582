import styled from 'styled-components'

const Label = styled.span`
  position: relative;
  display: inline-block;
  margin-left: 0.2rem;
  top: -0.5rem;

  background: var(--color-primary);
  text-transform: uppercase;
  font-weight: bold;
  font-size: 0.65rem !important;
  line-height: 0.65rem;

  color: var(--color-text-inverse) !important;
  padding: 2px !important;
  border-radius: 2px;
`

export function BetaLabel() {
  return <Label>BETA</Label>
}
