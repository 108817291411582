import styled from 'styled-components'

export const CONTAINER_MARGIN_BOTTOM = 30

export const JobList = styled.div<{ $disabledSelection?: boolean }>`
  ${({ $disabledSelection }) =>
    $disabledSelection &&
    `-webkit-user-select: none; /* Safari */
    -ms-user-select: none; /* IE 10 and IE 11 */
    user-select: none; /* Standard syntax */`}

  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  margin-bottom: 0.5rem;
`

export const TitleContainer = styled.div`
  display: flex;
  align-items: center;
`
