import classNames from 'classnames'
import { ReactNode } from 'react'

type Props = {
  Ref?: any
  children: ReactNode
  className?: string
}

export function Container(props: Props) {
  return (
    <div ref={props.Ref} className={classNames('container', props.className)}>
      {props.children}
    </div>
  )
}
