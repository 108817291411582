import { useQuery } from '@tanstack/react-query'
import { useMemo } from 'react'
import { useParams } from 'react-router'

import { useApiClient } from '../api/react'

function useView() {
  const { view } = useParams<{ view: string }>()
  return useMemo(() => view, [view])
}

export function usePrintersCount() {
  const api = useApiClient()
  const view = useView()

  const { data, isLoading } = useQuery({
    queryKey: [`/printers-count-check/${view}`],
    queryFn: () => api.app.printers.getPrinters({ limit: 1 }),

    // dont cache, we need to get fresh data everytime this component mounts, otherwise this could resolve wrong total from cache before the fresh load and execute wrong redirect
    gcTime: 0
  })

  return { data, isLoading }
}
