import { Outlet } from 'react-router-dom'
import styled from 'styled-components'

import { ButtonClickTracker } from '../ButtonClickTracker'
import { ScrollToTop } from '../components/common/ScrollToTop'
import { Footer } from '../components/Footer'
import { Header } from '../components/header/Header'
import { Toasts } from '../components/helpers/Toasts'
import { isSlicerAvailable } from '../slicer/hooks/useSlicer'

const LayoutContainer = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
`

const Content = styled.div`
  flex-grow: 1;
`

export function Layout() {
  const insideSlicer = isSlicerAvailable()

  return (
    <LayoutContainer>
      <ButtonClickTracker />
      <Header />
      <Content>
        <Outlet />
      </Content>
      <ScrollToTop />
      <Toasts />
      {!insideSlicer && <Footer />}
    </LayoutContainer>
  )
}
