import { stringify } from 'query-string'

import { ITransferMoveRequest, IUploadQueueFile, IUploadQueueFirmwareFile } from '../../types/file'
import { PrinterUuid } from '../../types/printer'
import { NoContent204, RequestFactory } from '../../types/sdk'
import {
  IConnectUploads,
  IHistoricTransfer,
  IHistoricTransfers,
  IQueuedTransfers,
  IUploadState
} from '../../types/transfer'

export function createModule(rf: RequestFactory) {
  return {
    deleteTransfer: (printerUuid: PrinterUuid, fileId: number) =>
      rf.delete<NoContent204>(`/app/printers/${printerUuid}/download-queue/${fileId}`),

    abortUpload: (uploadId: number) => rf.post<NoContent204>(`/app/uploads/${uploadId}/abort`),

    getPrinterQueue: (printerUuid: PrinterUuid) =>
      rf.get<IQueuedTransfers>(`/app/printers/${printerUuid}/download-queue`),

    getOngoingUploads: (teamId: number) =>
      rf.get<IConnectUploads>(`/app/users/teams/${teamId}/uploads?${stringify({ state: IUploadState.UPLOADING })}`),

    addToPrinterDownloadQueue: (printerUuid: PrinterUuid, fileInfo: IUploadQueueFile | IUploadQueueFirmwareFile) =>
      rf.post<NoContent204>(`/app/printers/${printerUuid}/download-queue`, fileInfo),

    moveTransfer: (printerUuid: PrinterUuid, fileData: ITransferMoveRequest) =>
      rf.put<NoContent204>(`/app/printers/${printerUuid}/download-queue`, fileData),

    getHistoryTransfers: (printerUuid: PrinterUuid) =>
      rf.get<IHistoricTransfers>(`/app/printers/${printerUuid}/transfers`),

    getTransfer: (printerUuid: PrinterUuid, transferId: number) =>
      rf.get<IHistoricTransfer>(`/app/printers/${printerUuid}/transfers/${transferId}`),

    retryTransfer: (printerUuid: PrinterUuid, fileId: number) =>
      rf.post<NoContent204>(`/app/printers/${printerUuid}/download-queue/${fileId}/retry`)
  }
}
