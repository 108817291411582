import { queryKey, REFETCH_PRINTER_LIST } from '@prusaconnect/api'
import { useInfiniteQuery, useQuery, UseQueryOptions } from '@tanstack/react-query'

import { useApiClient } from '../api/react'
import { IPrintersQuery, IPrintersResponse } from '../api/types/printer'

// Ugly Omitted type
export function usePrintersService(
  query: IPrintersQuery,
  options?: Omit<UseQueryOptions<IPrintersResponse>, 'queryKey'>
) {
  const api = useApiClient()
  return useQuery({
    queryKey: queryKey(['printers', query]),
    queryFn: () => api.app.printers.getPrinters(query),
    ...options
  })
}

export function useInfinitePrintersService(query: IPrintersQuery) {
  const { app } = useApiClient()
  return useInfiniteQuery({
    queryKey: queryKey(['printers', query]),
    queryFn: () => app.printers.getPrinters(query),
    refetchInterval: REFETCH_PRINTER_LIST,
    placeholderData: (previousData) => previousData,
    initialPageParam: { offset: 0, limit: query.limit } as IPrintersQuery,
    getNextPageParam: (lastPage, allPages) => {
      const loaded = allPages.flatMap((page) => page.printers).length
      const total = lastPage.pager.total

      if (loaded < total) {
        query.offset = loaded
        query.limit = total
        return query
      }
      return undefined
    }
  })
}
