export enum EventTypes {
  CREATE_ACCESS_TOKEN = 'tokencreate',
  CREATE_SESSION_ID = 'sessionidcreate',
  INIT = 'slicerinit',
  REQUEST_COMPATIBLE_PRINTER = 'requestcompatibleprinter',
  LOGOUT = 'logout'
}

export type SlicerEvent<T> = CustomEvent<{
  apiVersion: number
  data: T
}>
