import { useTheme } from '../../context/ThemeContext'
import lightModeIcon from '../../icons/general/loading-wheel-4s.svg'
import darkModeIcon from '../../icons/general/loading-wheel-4s-inverse.svg'

type SpinnerProps = {
  size?: number | string
}

export function Spinner({ size = 50 }: SpinnerProps) {
  const { isDarkMode } = useTheme()
  const sizeValue = typeof size === 'number' ? `${size}px` : size

  return (
    <img
      src={isDarkMode ? darkModeIcon : lightModeIcon}
      alt="Prusament Spool"
      style={{ width: sizeValue, height: sizeValue }}
    />
  )
}
