export enum Globals {
  VAPID_PUBLIC_KEY = 'VAPID_PUBLIC_KEY',
  ENVIRONMENT = 'ENVIRONMENT',
  OUTAGE_MESSAGE = 'OUTAGE_MESSAGE',
  OUTAGE_MESSAGE_MAINTENANCE = 'OUTAGE_MESSAGE_MAINTENANCE',
  OUTAGE_MESSAGE_FALLBACK = 'OUTAGE_MESSAGE_FALLBACK',
  AI_DETECTOR_API_URL = 'AI_DETECTOR_API_URL',
  VITE_HOMEPAGE_URL = 'VITE_HOMEPAGE_URL',
  VITE_ACCOUNT_URL = 'VITE_ACCOUNT_URL',
  VITE_ACCOUNT_CLIENT_ID = 'VITE_ACCOUNT_CLIENT_ID',

  $sleek = '$sleek'
}

type customWindow = {
  [Globals.VAPID_PUBLIC_KEY]?: string
  [Globals.ENVIRONMENT]?: string
  [Globals.OUTAGE_MESSAGE]?: string
  [Globals.OUTAGE_MESSAGE_MAINTENANCE]?: string
  [Globals.OUTAGE_MESSAGE_FALLBACK]?: string
  [Globals.AI_DETECTOR_API_URL]?: string
  [Globals.VITE_HOMEPAGE_URL]?: string
  [Globals.VITE_ACCOUNT_URL]?: string
  [Globals.VITE_ACCOUNT_CLIENT_ID]?: string
  [Globals.$sleek]?: any
} & Window

declare const global: customWindow

export function getEnvironment(globalName: Globals) {
  try {
    return global[globalName]
  } catch (_error) {
    return null
  }
}
