import { PrinterSimpleView } from '@prusaconnect/api'
import { Button, IconButton } from '@prusaconnect/ui'
import { ReactComponent as SlicerIcon } from '@prusaconnect/ui/icons/general/prusa-slicer.svg'
import { useTranslation } from 'react-i18next'

import { isSlicerAvailable, sendMessage } from '../../../slicer/hooks/useSlicer'

type Props = {
  printer: PrinterSimpleView
  iconOnly?: boolean
}

export function SlicerSelectPrinter({ printer, iconOnly = false }: Props) {
  const { t } = useTranslation()

  const handleSelectPrinter = (uuid: string) => {
    sendMessage({
      action: 'SELECT_PRINTER',
      uuid
    })
  }

  const isInsideSlicer = isSlicerAvailable()

  if (!isInsideSlicer) return null

  if (iconOnly) return <IconButton icon={<SlicerIcon size={25} />} onClick={() => handleSelectPrinter(printer.uuid)} />

  return (
    <Button onClick={() => handleSelectPrinter(printer.uuid)} icon={SlicerIcon} nowrap={false}>
      {t('printer.set-as-current', 'Set as current')}
    </Button>
  )
}
