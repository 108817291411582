import { ConnectState } from '@prusaconnect/api'
import { create } from 'zustand'

import { PrintersViewEnum } from '../interfaces/view'

export type SortKey = 'name' | 'state' | 'type' | 'location' | 'remaining_time'

export type SortParam<T extends string> = {
  key: T
  order: 'ASC' | 'DESC'
}

export type PrintersOverviewStore = {
  states?: ConnectState[]
  sortBy: SortParam<SortKey>[]
  teamId?: number
  groupId?: number
  view: PrintersViewEnum
  getSortParamQuery: () => string
  setParams: (params: Partial<PrintersOverviewStore>) => void
  applySort: (sortKey: SortKey) => void
  resetFilter: () => void
  resetSort: () => void
  resetAll: () => void
}

export const usePrintersOverviewStore = create<PrintersOverviewStore>((set, get) => ({
  states: [],
  sortBy: [
    { key: 'state', order: 'ASC' },
    { key: 'name', order: 'ASC' }
  ],
  groupId: undefined,
  teamId: undefined,
  view: PrintersViewEnum.TABLE,

  getSortParamQuery: () => sortParamsToApiQuery(get().sortBy),

  setParams: (params) => set(params),
  resetFilter: () => set({ states: [], teamId: undefined, groupId: undefined }),
  resetSort: () => set({ sortBy: [] }),
  resetAll: () => set({ states: [], sortBy: [], groupId: undefined, teamId: undefined }),
  applySort: (sortParam) => {
    const { sortBy } = get()
    set({ sortBy: createSortParams(sortBy, sortParam) })
  }
}))

function sortParamsToApiQuery(sortParams: SortParam<SortKey>[]) {
  const params = sortParams.map((param) => {
    const sign = param.order === 'ASC' ? '+' : '-'
    return `${sign}${param.key}`
  })
  params.push('+remaining_time') // always sort by remaining time
  return params.join(',')
}

export function createSortParams<T extends string>(sortParams: SortParam<T>[], sortKey: T): SortParam<T>[] {
  const prev = sortParams.find((item) => item.key === sortKey)

  // Missing -> first time ASC sort
  if (!prev) {
    return [...sortParams, { key: sortKey, order: 'ASC' }]
  }

  // ASC -> DESC
  if (prev.order === 'ASC') {
    return sortParams.map((item) => {
      if (item.key !== sortKey) {
        return item
      }
      return { key: sortKey, order: 'DESC' }
    })
  }

  // DESC -> remove
  return sortParams.filter((item) => item.key !== sortKey)
}
