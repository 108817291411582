import { useGroups } from './useGroups'

export function useGroupFilterData(isEnabled?: boolean) {
  // TODO temporary workaround for missing pagination
  const { data, isLoading } = useGroups(isEnabled)

  return {
    groupList: data?.groups || [],
    isLoading
  }
}
