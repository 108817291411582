import { TemperatureUnits } from '../lib/textSymbols'
import { UserPreferences } from '../types/UserPreferences'

export const defaultUserPreferences: UserPreferences = {
  units: {
    dimensions: 'mm',
    temp: TemperatureUnits.CELSIUS
  },
  date_time: {
    date_format: 'l',
    locale: 'en',
    time_format: '24h'
  },
  jobs: {
    params: {
      fdm: [
        { name: 'printer_model' },
        { name: 'printing_time' },
        { name: 'material' },
        { name: 'layer_height' },
        { name: 'print_end' },
        { name: 'size' },
        { name: 'nozzle_diameter' },
        { name: 'support_material' },
        { name: 'brim_width', visible: false },
        { name: 'fill_density', visible: false }
      ],
      sla: [
        { name: 'printer_model' },
        { name: 'printing_time' },
        { name: 'material', visible: false },
        { name: 'layer_height' },
        { name: 'print_end' },
        { name: 'size' },
        { name: 'resin_used' },
        { name: 'total_height' },
        { name: 'total_layers' }
      ]
    }
  },

  printers: {
    params: {
      fdm: {
        cards: {
          idle: [
            { name: 'printer_model' },
            { name: 'material' },
            { name: 'nozzle' },
            { name: 'location' },
            { name: 'team' }
          ],
          printing: [
            { name: 'printing_progress' },
            { name: 'printing_job_name' },
            { name: 'time_remaining' },
            { name: 'estimated_end' },
            { name: 'printer_type' },
            { name: 'material' },
            { name: 'nozzle' },
            { name: 'location', visible: false },
            { name: 'team', visible: false }
          ]
        },
        table: {
          idle: [
            { name: 'printer_model' },
            { name: 'material' },
            { name: 'nozzle' },
            { name: 'location' },
            { name: 'team' }
          ],
          printing: [
            { name: 'printing_progress' },
            { name: 'printing_job_name' },
            { name: 'time_remaining' },
            { name: 'estimated_end' },
            { name: 'printer_type' },
            { name: 'material' },
            { name: 'nozzle' },
            { name: 'location', visible: false },
            { name: 'team', visible: false }
          ]
        }
      },
      sla: {
        cards: {
          idle: [{ name: 'printer_model' }, { name: 'location' }, { name: 'team' }],
          printing: [
            { name: 'printing_progress' },
            { name: 'printing_job_name' },
            { name: 'time_remaining' },
            { name: 'estimated_end' },
            { name: 'printer_type' },
            { name: 'location', visible: false },
            { name: 'team', visible: false }
          ]
        },
        table: {
          idle: [{ name: 'printer_model' }, { name: 'location' }, { name: 'team' }],
          printing: [
            { name: 'printing_progress' },
            { name: 'printing_job_name' },
            { name: 'time_remaining' },
            { name: 'estimated_end' },
            { name: 'printer_type' },
            { name: 'location', visible: false },
            { name: 'team', visible: false }
          ]
        }
      }
    }
  },

  files: {
    params: {
      fdm: {
        idle: [
          { name: 'printer_model' },
          { name: 'estimated_printing_time' },
          { name: 'material' },
          { name: 'layer_height' },
          { name: 'date' },
          { name: 'size' },
          { name: 'nozzle_diameter', visible: false },
          { name: 'support_material', visible: false },
          { name: 'brim_width', visible: false },
          { name: 'fill_density', visible: false }
        ],
        printing: [
          { name: 'print_started' },
          { name: 'printing_time' },
          { name: 'time_remaining' },
          { name: 'estimated_end' },
          { name: 'filament_change_in' }
        ]
      },
      sla: {
        idle: [
          { name: 'printer_model' },
          { name: 'estimated_printing_time' },
          { name: 'material', visible: false },
          { name: 'layer_height' },
          { name: 'date' },
          { name: 'size' },
          { name: 'exposure_time_first' },
          { name: 'exposure_time' },
          { name: 'resin_used' },
          { name: 'total_height' },
          { name: 'total_layers' }
        ],
        printing: [
          { name: 'print_started' },
          { name: 'printing_time' },
          { name: 'time_remaining' },
          { name: 'estimated_end' },
          { name: 'exposure_time_first' },
          { name: 'exposure_time' },
          { name: 'current_layer' },
          { name: 'resin_consumed' }
        ]
      }
    }
  },

  status_bar: {
    params: {
      fdm: [
        { name: 'slots' },
        { name: 'nozzle_temperature' },
        { name: 'bed_temperature' },
        { name: 'material' },
        { name: 'speed' },
        { name: 'current_z_height' },
        { name: 'chamber_temperature' }
      ],
      sla: [
        { name: 'current_layer', visible: true },
        { name: 'cover_closed', visible: true },
        { name: 'uv_led_temperature', visible: true },
        { name: 'cpu_temperature', visible: true }
      ]
    }
  },

  orders: {
    params: [
      { name: 'printer_model' },
      { name: 'location' },
      { name: 'order_progress' },
      { name: 'order_items' },
      { name: 'order_estimated_end' },
      { name: 'material_with_color' },
      { name: 'weight' },
      { name: 'printing_time' },
      { name: 'printer_type' },
      { name: 'sheet' },
      { name: 'color' },
      { name: 'count' },
      { name: 'tags' }
    ]
  }
}
