import { PrinterSimpleView, printerTools, usePrinterCameraLastImage } from '@prusaconnect/api'
import { NozzlesField, PrinterImage as PrinterIcon } from '@prusaconnect/ui'
import { PrinterStateLabel } from '@prusaconnect/ui/components/partials/PrinterStateLabel'
import dayjs from 'dayjs'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import { IPrinterSimpleView } from '../../../api/types/printer'
import { getState, IConnectState, isPrintingState } from '../../../api/types/state'
import { useCanControl } from '../../../context/permissionsStore'
import { formatPercents } from '../../../helpers/formatters'
import { isDefined } from '../../../helpers/std'
import { useCommandMutation } from '../../../hooks/commands/useCommandMutation'
import { useLoggedUserSettings } from '../../../hooks/useLoggedUser'
import { isSlaPrinter } from '../../../hooks/usePrinterType'
import { useTeams } from '../../../hooks/useTeams'
import { ITimetype } from '../../../interfaces/time'
import { PrintersPreviewEnum } from '../../../interfaces/view'
import { DataItem } from '../../common/DataItemList'
import { FilamentColorTag } from '../../common/FilamentColorTag'
import { ListItem } from '../../common/ListItem'
import { textEllipsis } from '../../helpers/styled'
import { Time } from '../../helpers/time'
import { ConnectStateEnum, ISortableParam, PrinterFamily, PrinterParamEnum } from '../../preferences/types'
import { SlicerSelectPrinter } from '../actions/SlicerSelectPrinter'
import { CurrentFileControls } from '../control/CurrentFileControls'
import { useCurrentJob } from '../hooks/useCurrentJob'
import { SetPrinterReadyButton, SetReadyButtonDialog, useSetPrinterReadyDialog } from '../SetPrinterReadyButton'
import { getPrinterName } from '../utils'

const Ellipsis = styled.div`
  max-width: 300px;
  ${textEllipsis};
`

function PrinterImage({ printer, showCamera }: { printer: IPrinterSimpleView; showCamera: boolean }) {
  const { data } = usePrinterCameraLastImage({
    printerUuid: printer.uuid,
    printerIsPrinting: isPrintingState(printer.connect_state),
    resolution: 'thumbnail',
    enabled: showCamera
  })

  if (data && !data.isStale) {
    return (
      <img src={data.imageObjectUrl} alt={printer.name} style={{ transform: `rotate(${data.rotation || 0}deg)` }} />
    )
  }

  const toolsHelper = printer.tools ? printerTools(printer.tools) : null

  return (
    <PrinterIcon
      type={printer.printer_type}
      countOfTools={toolsHelper?.tools().length}
      title={printer.name}
      isMmu={toolsHelper?.isMultiMaterial()}
      enclosure={printer.enclosure?.present}
    />
  )
}

function Controls({
  printer,
  overwriteControls,
  onSetPrinterReady
}: {
  printer: IPrinterSimpleView
  onSetPrinterReady: () => void
  overwriteControls?: JSX.Element
}) {
  const canControl = useCanControl(printer.team_id)
  const { isAvailable } = useCommandMutation(printer, {
    command: 'SET_PRINTER_READY'
  })

  if (overwriteControls) return overwriteControls

  if (canControl && [IConnectState.PRINTING, IConnectState.PAUSED].includes(printer.connect_state)) {
    return (
      <>
        <SlicerSelectPrinter printer={printer as PrinterSimpleView} />
        <CurrentFileControls printer={printer} />
      </>
    )
  }

  return isAvailable ? (
    <>
      <SlicerSelectPrinter printer={printer as PrinterSimpleView} />
      <SetPrinterReadyButton
        printer={printer}
        onSetPrinterReady={onSetPrinterReady}
        plannedJobsCount={printer.job_queue_count}
      />
    </>
  ) : (
    <SlicerSelectPrinter printer={printer as PrinterSimpleView} />
  )
}

type Props = {
  printer: IPrinterSimpleView
  preview?: boolean
  overwriteControls?: JSX.Element
  showCamera?: boolean
}

export function PrinterListItem({ printer, overwriteControls, preview = false, showCamera = false }: Props) {
  const teams = useTeams()
  const { t } = useTranslation()
  const { showSetPrinterReadyDialog, onCancel, onSetPrinterReady } = useSetPrinterReadyDialog()
  const { estimatedEnd, timeRemaining } = useCurrentJob(printer.connect_state, printer.job_info)
  const isSla = isSlaPrinter(printer.printer_type)

  const isPrinting = [IConnectState.PAUSED, IConnectState.PRINTING].includes(printer.connect_state)

  const renderParam = (name: PrinterParamEnum) => {
    if (name === PrinterParamEnum.PRINTING_PROGRESS && isDefined(printer.job_info?.progress)) {
      return {
        label: t('printer.printing-progress'),
        value: formatPercents(printer.job_info?.progress || 0)
      }
    }

    if (name === PrinterParamEnum.PRINTING_JOB_NAME && printer?.job_info?.display_name) {
      return {
        label: t('printer.printing-job-name'),
        value: <Ellipsis title={printer.job_info.display_name}>{printer.job_info.display_name}</Ellipsis>
      }
    }

    if (name === PrinterParamEnum.TIME_REMAINING && printer?.job_info?.time_remaining && timeRemaining > -1) {
      return {
        label: t('printer.time-remaining'),
        value: <Time unixTimestamp={timeRemaining} type={ITimetype.COUNTDOWN} noSeconds />
      }
    }

    if (name === PrinterParamEnum.ESTIMATED_END && estimatedEnd > 0 && timeRemaining > -1) {
      return {
        label: t('printer.estimated-end', 'Estimated end'),
        value: <Time unixTimestamp={estimatedEnd} noSeconds />
      }
    }

    if (name === PrinterParamEnum.PRINTER_TYPE) {
      return {
        label: t('printer.printer-type'),
        value: printer.printer_type_name
      }
    }

    if (name === PrinterParamEnum.MATERIAL && printer.filament) {
      return {
        label: t('printer.material'),
        value: <FilamentColorTag filament={printer.filament} material name />
      }
    }

    if (name === PrinterParamEnum.NOZZLE && printer.tools) {
      const toolsHelper = printerTools(printer.tools)
      return {
        label: t('printer.nozzle'),
        value: (
          <NozzlesField
            tools={toolsHelper.tools()}
            hasMultipleTools={toolsHelper.isMultitool()}
            isMmu={toolsHelper.isMultiMaterial()}
            diameterFallback={toolsHelper.tools()[0].nozzle_diameter}
          />
        )
      }
    }

    if (name === PrinterParamEnum.LOCATION && printer.location) {
      return {
        label: t('printer.location'),
        value: printer.location
      }
    }

    if (name === PrinterParamEnum.TEAM && teams.length > 1) {
      return {
        label: t('printer.team'),
        value: printer.team_name || t('team.default-name', { id: printer.team_id })
      }
    }

    if (name === PrinterParamEnum.FW && printer.firmware) {
      return {
        label: t('printer.firmware'),
        value: printer.firmware
      }
    }
  }

  const idleFdmParams = useLoggedUserSettings(
    'printers',
    PrinterFamily.FDM,
    PrintersPreviewEnum.TABLE,
    ConnectStateEnum.IDLE
  )
  const idleSlaParams = useLoggedUserSettings(
    'printers',
    PrinterFamily.SLA,
    PrintersPreviewEnum.TABLE,
    ConnectStateEnum.IDLE
  )
  const progressFdmParams = useLoggedUserSettings(
    'printers',
    PrinterFamily.FDM,
    PrintersPreviewEnum.TABLE,
    ConnectStateEnum.PRINTING
  )
  const progressSlaParams = useLoggedUserSettings(
    'printers',
    PrinterFamily.SLA,
    PrintersPreviewEnum.TABLE,
    ConnectStateEnum.PRINTING
  )

  let dataItems: DataItem[] = []
  if (isPrinting) {
    dataItems = (isSla ? progressSlaParams : progressFdmParams)
      .map((item: ISortableParam) => {
        if (item.visible === false) {
          return null
        }
        return renderParam(item.name as PrinterParamEnum)
      })
      .filter(Boolean) as DataItem[]
  } else {
    dataItems = (isSla ? idleSlaParams : idleFdmParams)
      .map((item: ISortableParam) => {
        if (item.visible === false) {
          return
        }
        return renderParam(item.name as PrinterParamEnum)
      })
      .filter(Boolean) as DataItem[]
  }

  const currentState = getState(printer.connect_state)
  const isTransparent = !overwriteControls && printer.connect_state === IConnectState.OFFLINE

  let errorsAndAttentions =
    printer.connect_state === IConnectState.ATTENTION ? [t('printer.attention.tooltip')] : undefined

  if (printer.connect_state === IConnectState.ERROR && printer.dialog_info) {
    errorsAndAttentions = [printer.dialog_info?.text || t('printer.attention.tooltip')] // TODO translate by code/id from Prusalator
  }

  return (
    <>
      <ListItem
        title={getPrinterName(printer)}
        link={preview ? '#' : `/printer/${printer.uuid}`}
        imageElement={<PrinterImage printer={printer} showCamera={showCamera} />}
        progress={printer.job_info?.progress}
        controls={
          !preview ? (
            <Controls printer={printer} overwriteControls={overwriteControls} onSetPrinterReady={onSetPrinterReady} />
          ) : null
        }
        dataItems={dataItems}
        baseColor={currentState.baseColor}
        statusBadge={
          <PrinterStateLabel
            printerState={printer.connect_state}
            tooltip={
              printer.connect_state === IConnectState.OFFLINE && printer.last_online
                ? dayjs.unix(printer.last_online).fromNow()
                : undefined
            }
          />
        }
        isTransparent={isTransparent}
        attentionList={errorsAndAttentions}
      />
      {showSetPrinterReadyDialog && <SetReadyButtonDialog onCancel={onCancel} printer={printer} />}
    </>
  )
}
