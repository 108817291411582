import { Language } from '@prusaconnect/prusalator-i18next'
import styled from 'styled-components'

import { useDevice } from '../../helpers/useDevice'
import { useLanguage } from '../../hooks/useLanguage'
import { Dropdown, DropdownItemButton } from '../common/Dropdown'

const CapitalizedFirst = styled.div`
  font-size: 0.875rem;

  :first-letter {
    text-transform: uppercase;
  }

  :hover {
    color: ${({ theme }) => theme.colors.primary};
  }
`

function getNativeLanguageName(code: string) {
  return new Intl.DisplayNames([code], { type: 'language' }).of(code)
}

export function LanguageDropdown() {
  const { language, changeLanguage } = useLanguage()
  const { lg } = useDevice()

  return (
    <Dropdown
      trigger={
        <CapitalizedFirst>{lg ? getNativeLanguageName(language) : language.toLocaleUpperCase()}</CapitalizedFirst>
      }
    >
      {Object.values(Language).map((key) => (
        <DropdownItemButton key={key} onClick={() => changeLanguage(key)}>
          <CapitalizedFirst>{getNativeLanguageName(key)}</CapitalizedFirst>
        </DropdownItemButton>
      ))}
    </Dropdown>
  )
}
