import { Material } from '@prusaconnect/api'

import { CompatiblePrinterParams } from '../slicer/publicApi'

export type NormalizedCompatiblePrinterParams = {
  printerUuid?: string
  filename?: string
  printerType?: string
  printerModel?: string
  material?: Material[]
  nozzleDiameter?: number[]
  nozzleHardened?: boolean[]
  nozzleHighFlow?: boolean[]
  multipleBeds?: boolean
}

export function getNormalizedSlicerData(data: CompatiblePrinterParams): NormalizedCompatiblePrinterParams {
  return Object.entries(data).reduce((acc, [key, value]) => {
    if (key === 'printerUuid' || key === 'printerModel' || key === 'printerType' || key === 'filename') {
      acc[key] = String(value)
    }

    if (key === 'material') {
      if (Array.isArray(value) && value.every((v) => typeof v === 'string')) {
        acc.material = value
      } else if (typeof value === 'string') {
        const materials = value.split(';')
        acc.material = materials as Material[]
      }
    }

    if (key === 'nozzleDiameter' && typeof value === 'number') {
      acc.nozzleDiameter = [value]
    }

    if (key === 'nozzle_diameter' && Array.isArray(value) && value.every((v) => typeof v === 'number')) {
      acc.nozzleDiameter = value
    }

    if (key === 'filament_abrasive' && Array.isArray(value) && value.every((v) => typeof v === 'boolean')) {
      acc.nozzleHardened = value
    }

    if (key === 'high_flow' && Array.isArray(value) && value.every((v) => typeof v === 'boolean')) {
      acc.nozzleHighFlow = value
    }

    if (key === 'multiple_beds' && typeof value === 'boolean') {
      acc.multipleBeds = value
    }

    return acc
  }, {} as NormalizedCompatiblePrinterParams)
}
